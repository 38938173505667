import React, { useContext, useState, useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../utils/context/userContext';
import Requests from '../../utils/Requests/Index';
import { useSelector } from 'react-redux'
import { io } from "socket.io-client";
// for notification;
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })


const Item = ({ item, setPageload }) => {
    const { user } = useContext(UserContext)
    const [btnText, setBtnText] = useState("")
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const socket = useRef()
    const userInfo = useSelector((state) => state.user.userData)

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
    }, [])

    useEffect(() => {
        let checkConnect = user?.connections?.filter(data => data._id === item?._id)
        let checkPending = user?.pendingRequests?.filter(id => id === item?._id)
        let checkSent = user?.sentRequests?.filter(id => id === item?._id)

        if (checkConnect?.length > 0) {
            setBtnText("disconnect")
        } else if (checkPending?.length > 0) {
            setBtnText("accept")
        } else if (checkSent?.length > 0) {
            setBtnText("cancel")
        } else {
            setBtnText("propose now")
        }
    }, [setPageload]) // eslint-disable-line react-hooks/exhaustive-deps


    // Send request
    const sendRequest = async id => {
        try {

            const response = await Requests.Connection.Connect(id, header)
            if (response) {
                setPageload(true)
                toast.success(response.message);
                socket.current.emit("sendProposal", {
                    senderId: userInfo.userID,
                    name: userInfo.name,
                    receiverId: id
                })
                if (response.status) {
                    setBtnText("cancel")
                }
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }

    // disconnect 
    const handleDisconnect = async id => {
        try {
            setBtnText("propose now")
            const response = await Requests.Connection.UpdateRequestType(id, "disconnect", header)
            if (response && response.status) {
                setPageload(true)
                toast.success(response.message)
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }

    // accept   
    const handleAccept = async id => {
        try {
            setBtnText("disconnect")
            const response = await Requests.Connection.UpdateRequestType(id, "accept", header)
            if (response && response.status) {
                setPageload(true)
                toast.success(response.message)
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }

    // cancel
    const handleCancel = async id => {
        try {
            setBtnText("propose now")
            const response = await Requests.Connection.UpdateRequestType(id, "cancel", header)
            if (response && response.status) {
                setPageload(true)
                toast.success(response.message)
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }


    return (
        <div className="card">
            <div className="card-body shadow-sm">
                <div className="img-container rounded-circle">
                    <img src={item.images[0].name} className="img-fluid" alt="..." />
                </div>
                <div className="content">

                    <div className="">
                        <Link to={`/single-profile/${item._id}`}>
                            <h6 className="profile_id">@{item.profileId}</h6>
                        </Link>
                        <h6 className="mb-2">{item.name}</h6>
                        <p>{item.isPhoneVerified ? <span className="text-success">Verified number</span> : <span className="text-danger">Unverified number</span>}</p>
                        <p>{item.age} years</p>
                        <p>lives in {item.city}</p>
                        <p >Citizenship: {item.birthCountry}</p>
                    </div>

                    <button type="button" className="btn shadow-sm"
                        onClick={
                            btnText === "disconnect"
                                ? () => handleDisconnect(item?._id)
                                : btnText === "accept"
                                    ? () => handleAccept(item?._id)
                                    : btnText === "cancel"
                                        ? () => handleCancel(item?._id)
                                        : () => sendRequest(item?._id)
                        }
                    > {btnText} </button>
                </div>
            </div>
        </div>
    );
};

export default Item;