import React, { useState, useCallback, useEffect, createContext } from "react";
import { Options } from '../../utils/Requests/Options';
// import { optionSetter } from "../Redux/Slice/OptionSlice";
// import { useDispatch } from "react-redux";
export const optionContext = createContext();

const OptionContextProvider = ({ children }) => {

    const [options, setOptions] = useState(null)
    // const dispatch = useDispatch()

    const fetchOptions = useCallback(async () => {
        const response = await Options()
        // dispatch(optionSetter(response))
        setOptions(response)
    }, [])

    useEffect(() => {
        fetchOptions()
    }, [fetchOptions])

    // OptionContext To search data... Function
    const handleOptionToData = (search) => {
        let findSearch = options && options.settingFiled ? options.settingFiled.find(info => info.label === search) : null;
        if (!findSearch) return [];
        let info = [];

        if (search === "Income") {
            for (let i = 0; i < findSearch.value.length; i++) {
                info.push({ label: `${findSearch.value[i]} BDT`, value: findSearch.value[i] });
            }
        } else {
            for (let i = 0; i < findSearch.value.length; i++) {
                info.push({ label: `${findSearch.value[i]}`, value: findSearch.value[i] });
            }
        }
        return info;
    };

    const { Provider } = optionContext

    return(
        <Provider value={{options, handleOptionToData}}>
            {children}
        </Provider>
    );
}
export default OptionContextProvider;