import React from "react"
import Icon from "react-icons-kit"
import { smartphone, mail} from "react-icons-kit/feather"


const ForgotOption = (props) => {
   
    return (
        <div className="card auth-card">
            <div className="card-header bg-white border-0 px-2 py-3">
                    <div className="text-center pt-2">
                        <h5>Choose your verification option</h5>
                    </div>
            </div>
            <div className="card-body d-flex justify-content-center p-4">
                    <button 
                    className="btn mx-5 shadow-none"
                    onClick={() => props.setShow('email')}
                    >
                    <Icon icon={mail} size={60} />
                    <span>Email</span>
                    </button>

                    <button 
                    className="btn mx-5 shadow-none" 
                    onClick={() => props.setShow('phone')}
                    >
                    <Icon icon={smartphone} size={60} />
                    <span>Phone</span>
                    </button>
            </div>
        </div>
    );
};

export default ForgotOption;