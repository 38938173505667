import React from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import NavBar from '../../components/NavBar/Search'
import Basic from './Basic/Index'
import Advance from './Advance/Index'
// import FourOFour from '../FourOfour/Index'
import Result from './Result/Index'

const Master = () => {
    return (
        <div className="search-master">
            <NavBar />
            <Switch>
                <Route exact path="/search" component={Basic} />
                <Route path="/search/advance" component={Advance} />
                <Route path="/search/results" component={Result} />

                {/* <Route path="*" component={FourOFour} /> */}
            </Switch>

        </div>
    );
};

export default Master;