import React, { useContext, useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";
import { HeightCalculate } from "../../utils/helpers";
import { UserContext } from '../../utils/context/userContext';
import Requests from '../../utils/Requests/Index';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { io } from "socket.io-client";

toast.configure({ autoClose: 2000 })


const ProfileCard = ({ item }) => {
    const { handleRefetch } = useContext(UserContext)
    const [send, setSend] = useState(false);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const socket = useRef()
    const userInfo = useSelector((state) => state.user.userData)

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
    }, [])

    // Send request
    const sendRequest = async id => {
        try {
            const response = await Requests.Connection.Connect(id, header)
            if (response) {
                toast.success(response.message);

                socket.current.emit("sendProposal", {
                    senderId: userInfo.userID,
                    name:userInfo.name,
                    receiverId: id
                })

                if (response.status) {
                    setSend(true);
                }
                handleRefetch()
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }


    return (
        <div id="profile_card" className="card mb-3" >
            <div className="d-flex align-items-center g-0">
                <div className=" card_image">
                    <img src={item.images[0].name} alt="..." />
                </div>
                <div className="card-body ">
                    <Link to={`/single-profile/${item._id}`}>
                        <h6 className="profile_id">@{item.profileId}</h6>
                    </Link>
                    <h6 className="card-title">{item.name}</h6>
                    <hr className="m-0" />
                    <span className="card-text text-muted">{item?.age} Years, {item?.height ? HeightCalculate(item.height) + ", " : null} {item?.city ? item?.city + ", " : null}
                        {item?.birthCountry ? item?.birthCountry + " (Citizen)" : null} </span>
                    {
                        item?.professionArea && <span className="card-text text-muted"> - { item?.professionArea} </span>
                    }
                    <br /> <br />
                    {
                        !send ?
                            <button type="button"
                                className="btn shadow-sm request_btn"
                                onClick={() => sendRequest(item?._id)}
                            > Propose now </button>
                            : <small className="text-muted">Proposal send</small>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;