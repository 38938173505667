import { createSlice } from '@reduxjs/toolkit'

export const MessageBoxStateSlice = createSlice({
    name: "messageboxstate",
    initialState: { view: false, obj: {} },
    reducers: {
        stateSetter: (state, action) => {
            return {
                ...state,
                view: !state.view,
                obj: action.payload
            }
        },
        setTrue: (state, action) => {
            return {
                ...state,
                view: true,
                obj: action.payload
            }
        },
        setFalse: (state) => {
            return {
                ...state,
                view: false,
            }
        },
        hidden: (state, action) => {
            return {
                ...state,
                view: false,
                obj: action.payload
            }
        }
    },
});

export const { stateSetter, setTrue, hidden, setFalse } = MessageBoxStateSlice.actions

export default MessageBoxStateSlice.reducer;