
import React, { useState, useEffect, useContext } from 'react'
import ProfileCard from '../../../components/Connection/ProfileCard'
import Loading from '../../../components/Loading/Index'
import Requests from '../../../utils/Requests/Index'
// import { UserContext } from "../../../utils/context/userContext";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { singleProContext } from '../../../utils/context/SingleProfileContext';
import { useQuery } from 'react-query';

const Index = () => {
    const {handleLocalStoreIds} = useContext(singleProContext);
    // const { handleRefetch } = useContext(UserContext);
    // const [data, setData] = useState([])
    // const [isLoading, setLoading] = useState(true)
    const [isUpdate, setUpdate] = useState({ value: null, status: false })
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageload, setPageload] = useState(true)

    // Fetch data
    const fetchData = async () => {
        try {
            const response = await Requests.Connection.Send(header)
            if (response && response.users) {
                setPageload(false)
                return response.users
            }
        } catch (error) {
            setPageload(false)
        }
    }
    const { data, isLoading } = useQuery("sendRequest", fetchData, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageload)
    })

    // const fetchData = useCallback(async () => {
    //     setLoading(true)
    //     const response = await Requests.Connection.Send(header)
    //     if (response && response.users) setData(response.users)
    //     setLoading(false)
    // }, [header])

    // useEffect(() => {
    //     fetchData()
    // }, [header, fetchData])

    useEffect(() => {
        // Set ids For Single Profile...
        const userIds = data?.map(info => info._id);
        handleLocalStoreIds(userIds);
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    // Update request type
    const Updatetype = async data => {
        try {
            setUpdate({ value: data, status: true })
            const response = await Requests.Connection.UpdateRequestType(data, "cancel", header)
            if (response && response.status) {
                toast.success(response.message)
                // fetchData()
                setPageload(true)
                // handleRefetch();
            }
            setUpdate({ value: null, status: false })
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <Loading />

    return (
        <div className="connection-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 text-center text-sm-left px-sm-4 mb-2">
                        <h6 className="mb-0">You send {data.length} proposal</h6>
                        <small className="text-muted"><i>You send {data.length} proposals from your account.</i></small>
                    </div>

                    <div className="col-12">
                        {data && data.map((item, i) =>
                            <ProfileCard
                                key={i}
                                item={item}
                                buttonTitle="Cancel"
                                changetype={Updatetype}
                                loading={isUpdate}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;