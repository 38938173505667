import React from 'react'
import './style.scss'
import Carousel from "react-elastic-carousel";
import Item from './Item';

const ProfileSlider = ({ items, setPageload }) => {
    
    // Profile sliders items
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1024, itemsToShow: 4 },
        { width: 1400, itemsToShow: 5 },
    ];

    return (
        <div className="custom-profile-slider d-none d-lg-block ">

            <Carousel breakPoints={breakPoints}>

                {items && items.length ?
                    items.map((item, i) =>
                        <Item item={item} key={i} setPageload={setPageload}/>
                    ) : null}

            </Carousel>

        </div>
    );
};

export default ProfileSlider;