import React, { useState, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { useQuery } from 'react-query'
import ProfileSlider from '../../components/ProfileSlider/Index'
import Loading from '../../components/Loading/Index'
import Requests from '../../utils/Requests/Index'
import PostCard from '../../components/Post/Index'
import PostCreateCard from '../../components/Post/Create'
import ConfirmDeleteCard from '../../components/Post/ConfirmDelete'
import { UserContext } from "../../utils/context/userContext"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Index = () => {
    const { user } = useContext(UserContext)
    const [show, setShow] = useState(false)
    const history = useHistory()
    const [showDelete, setShowDelete] = useState({ status: false, value: null })
    const [pageReload, setPageReload] = useState(true);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    // Fetch data
    const getData = async () => {
        const response = await Requests.TimelinePost.Index(header)
        if (response && response.users) {
            setPageReload(false)
            return response;
        }
    }
    // react query;
    let data = useQuery('timeline', getData,
        {
            retry: false,
            cacheTime: 600000,
            refetchOnWindowFocus: false,
            enabled: Boolean(pageReload)
        })

    const onDelete = id => {
        setShowDelete({ status: true, value: id })
    }

    const handlePackagePage = () => {
        history.push("/my-biyesadi/package")
    }

    const handleLike = async (id, type) => {
        setPageReload(true)
        const response = await Requests.TimelinePost.UpdateLike(id, { type }, header)
        if (response.status) {
            setPageReload(false)
            toast.success(response?.message)
        }
    }


    if (data.isLoading) return <Loading />;

    return (
        <div className="timeline-container">
            <div className="container-fluid">

                <ProfileSlider items={data.data.users} />

                <div className="row mb-4 pt-lg-4">
                    <div className="col-12">
                        <img src={data?.data?.adds?.timelineContainer} className="img-fluid" width="100%" alt="..." />
                    </div>
                </div>


                <div className="row">

                    {/* Add container */}
                    <div className="col-12 col-lg-3 d-none d-lg-block pr-lg-2">
                        <div className="card addsense-card flex-center flex-column">
                            <img src={data?.data?.adds?.timelineSide} className="img-fluid" alt="..." />
                        </div>
                    </div>

                    {/* Post box */}
                    <div className="col-12 col-lg-6 pl-lg-2 post-box-container">
                        <div className="card border-0 shadow-sm mb-4">
                            <div className="card-body">
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Write your post."
                                    onClick={() => user?.profileType !== "Paid" ? handlePackagePage() : setShow(true)}
                                />
                            </div>
                        </div>

                        {/* Posts */}
                        {data?.data?.posts && data?.data?.posts.map(item =>
                            <PostCard
                                key={item.id}
                                userId={user?._id}
                                userType={user?.profileType}
                                onDelete={onDelete}
                                item={item}
                                setLoading={status => setPageReload(status)}
                                handleLike={handleLike}
                            />
                        )}
                    </div>

                    {/* Chat container */}
                    <div className="col-12 col-lg-3"></div>

                </div>
            </div>

            {show ?
                <PostCreateCard
                    show={show}
                    onHide={() => {
                        setShow(false)
                        setPageReload(true)
                    }}
                />
                : null}

            {showDelete.status ?
                <ConfirmDeleteCard
                    show={showDelete.status}
                    deleteId={showDelete.value}
                    onHide={() => {
                        setShowDelete({ state: false, value: null })
                        setPageReload(true)
                    }}
                />
                : null}
        </div>
    );
};

export default Index;