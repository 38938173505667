import React, { useState, createContext } from "react";
import Profile from '../../utils/Requests/Profile';
import { useDispatch } from 'react-redux';
import { connectedSetter, matchSetter, previousSetter} from '../../utils/Redux/Slice/Chat/ChatMemberListSlice'
import { dashboardDataSetter } from '../../utils/Redux/Slice/UserSlice'
import { initialProfileViewed } from '../../utils/Redux/Slice/NotificationSlice'
import { useQuery } from 'react-query'

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    const dispatch = useDispatch()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageLoad, setPageload] = useState(true)
    
    const handleRefetch = () => {
        setPageload(true)
    }

    const fetchUser = async () => {
        try {
            const response = await Profile.Index(header)
            let data = response.user
            let obj = {
                profileType: data.user.profileType,
                image: data.user.profilePicture.clearImage,
                userID: data.user._id,
                name: data.user.name
            }
            dispatch(dashboardDataSetter(obj))
            dispatch(connectedSetter(data.connectedMembers))
            dispatch(matchSetter(data.matches))
            dispatch(previousSetter(data.previousChats))
            let notifObj = {
                dataList : data.notification,
                count: data.notification.length
            }
            dispatch(initialProfileViewed(notifObj))
            setPageload(false)
            return data.user
        } catch (error) {
            console.log(error.message)
        }
    }

    const { data } = useQuery("userData", fetchUser, {
        retry: false,
        cacheTime: 1200000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    const { Provider } = UserContext

    return (
        <Provider value={{ user: data, handleRefetch }}>
            {children}
        </Provider>
    );
}
export default UserContextProvider;