import React, { useState, useContext } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { x } from 'react-icons-kit/feather'
import 'react-toastify/dist/ReactToastify.css'
import Requests from '../../../utils/Requests/Index'
import DeleteModal from '../../Delete/Index'
import { UserContext } from '../../../utils/context/userContext'
import { Images } from '../../../utils/Images'
toast.configure({ autoClose: 2000 })

const Index = (props) => {

    const [isDelete, setDelete] = useState({ show: false, loading: false, image: null, _id: null })
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const { handleRefetch } = useContext(UserContext)

    const handleDelete = data => {
        setDelete({ ...isDelete, show: true, image: data.image, _id: data._id })
    }
    
    const [data] = useState(`${process.env.REACT_APP_IMAGE_URL}/${props.item.name}`)

    const submitDelete = async () => {
        setDelete({ ...isDelete, loading: true })
        let obj = {
            imageId: props.item._id
        }
        const response = await Requests.MyPhoto.DeleteImage(obj, header)
        if (response.status) {
            setDelete({ loading: false, show: false, image: null, _id: null })
            handleRefetch()
            toast.success('Image successfully deleted.')
            window.location.reload()
        }
        else {
            setDelete({ loading: false, show: false, image: null, _id: null })
            toast.error('Image is not deleted.')
        }

    }

    return (
        <div className="gallery">
            <div className="img-container">
                <img src={data ? data : Images.NoImage} className="img-fluid p-3" alt="..." />
                <div className="overlay">
                    <button
                        type="button"
                        className="btn shadow-none rounded-circle"
                        onClick={() => handleDelete({ _id: 1, image: data })}
                    >
                        <Icon icon={x} size={22} />
                    </button>
                </div>
            </div>

            {isDelete.show ?
                <DeleteModal
                    show={isDelete.show}
                    image={isDelete.image}
                    loading={isDelete.loading}
                    doDelete={submitDelete}
                    onHide={() => setDelete({ ...isDelete, show: false })}
                />
                : null}
        </div>
    );
};

export default Index;