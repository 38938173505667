import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Index of profile
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}profile`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update user short descriptions
const UpdateShortDescription = async (data, header) => {
    try {
        const response = await Axios.put(`${api}shortdescription/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update user Basic Life style informations
const UpdateBasicLifeStyle = async (data, header) => {
    try {
        const response = await Axios.put(`${api}basiclifestyle/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update user Family details
const UpdateFamilyDetails = async (data, header) => {
    try {
        const response = await Axios.put(`${api}familydetails/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

//update Edication and career infos
const UpdateEducationAndCareer = async (data, header) => {
    try {
        const response = await Axios.put(`${api}educationandcareer/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// update Location of Groom
const UpdateLocationOfGroom = async (data, header) => {
    try {
        const response = await Axios.put(`${api}locationofgroom/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePersonalActivities = async (data, header) => {
    try {
        const response = await Axios.put(`${api}personalactivities/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UpdatePartnersBasicInfos = async (data, header) => {
    try {
        const response = await Axios.put(`${api}partnerbasicinfo/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UpdatePartnersLocationDetails = async (data, header) => {
    try {
        const response = await Axios.put(`${api}partnerlocationdetails/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UpdatePartnersEducationAndCareer = async (data, header) => {
    try {
        const response = await Axios.put(`${api}partnereducationandcareer/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePartnersOther = async (data, header) => {
    try {
        const response = await Axios.put(`${api}partnerothers/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UpdateContactDetails = async (data, header) => {
    try {
        const response = await Axios.put(`${api}contactdetails/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateReligiousBackground = async (data, header) => {
    try {
        const response = await Axios.put(`${api}religiousbackground/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UpdatePhoneNumber = async (data, header) => {
    try {
        const response = await Axios.put(`${api}phonenumber/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const VerifyPhoneNumber = async (data, header) => {
    try {
        const response = await Axios.put(`${api}verifyphonenumber/update`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateContactViewed = async (userId, header) => {
    try {
        const response = await Axios.put(`${api}packageInfo/contactviewed/update`, { userId }, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Profile = {
    Index,
    UpdateShortDescription,
    UpdateBasicLifeStyle,
    UpdateFamilyDetails,
    UpdateEducationAndCareer,
    UpdateLocationOfGroom,
    UpdatePersonalActivities,
    UpdatePartnersBasicInfos,
    UpdatePartnersLocationDetails,
    UpdatePartnersEducationAndCareer,
    UpdatePartnersOther,
    UpdateContactDetails,
    UpdateReligiousBackground,
    UpdatePhoneNumber,
    VerifyPhoneNumber,
    UpdateContactViewed
}

export default Profile