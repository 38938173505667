import React, { useState, useEffect, useContext } from 'react'
// import Search from '../../../components/Search/Index'
import Profile from '../../../components/Profile/Index'
import Loading from '../../../components/Loading/Index'
import FilterItems from '../../../components/Matches/Filter/Index'
import Requests from '../../../utils/Requests/Index'
import { singleProContext } from '../../../utils/context/SingleProfileContext'
import { RefineContext } from '../../../utils/context/RefineSearchContext'
// import { UserContext } from '../../../utils/context/userContext'
import { useQuery } from 'react-query'
import { RefineSearchFunc } from '../../../utils/RefineSearch/RefineSearchFunc'
import Icon from 'react-icons-kit'
import { plus, minus } from 'react-icons-kit/feather'


const Index = () => {
    // const { user } = useContext(UserContext)
    const { handleLocalStoreIds } = useContext(singleProContext);
    const { refineSearchObj, setRefineSearchObj } = useContext(RefineContext);
    const [pageReload, setPageReload] = useState(true);
    const [refineReload, setRefineReload] = useState(false);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [RefineShow, setRefineShow] = useState(true);
    const handleRefineShowHide = () => {
        setRefineShow(!RefineShow)
    }
    useEffect(() => {
        if (window.innerWidth <= 992) {
            setRefineShow(false)
        }
    }, [window])


    // Fetch data
    const getData = async () => {
        const response = await Requests.Matches.NewMatch(header)
        if (response && response.users) {
            setPageReload(false)
            return response.users;
        }
    }
    // react query;
    let users = useQuery('newMatches', getData,
        {
            retry: false,
            cacheTime: 600000,
            refetchOnWindowFocus: false,
            enabled: Boolean(pageReload)
        })

    // get refine search data
    useEffect(() => {
        setRefineSearchObj({
            "age": [],
            "height": [],
            "materialStatus": [],
            "professionArea": [],
            "qualification": [],
            "workingWith": [],
            "income": [],
            "language": [],
            "livingCountry": [],
            "recentlyJoined": [],
            "religion": [],
            "title": [],
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRefineReload(true);
    }, [refineSearchObj]) // eslint-disable-line react-hooks/exhaustive-deps

    const getRefineData = async () => {
        const response = await RefineSearchFunc(users.data, refineSearchObj)        // , user
        setRefineReload(false)
        return response;
    }
    const handleRefine = useQuery("newRefineResults", getRefineData,
        {
            retry: false,
            cacheTime: 600000,
            refetchOnWindowFocus: false,
            enabled: Boolean(refineReload)
        })

    // Set ids For Single Profile...
    useEffect(() => {
        if (users?.data || handleRefine?.data) {
            const userIds = handleRefine?.data ? handleRefine?.data?.map(info => info._id) : users?.data?.map(info => info._id);
            handleLocalStoreIds(userIds);
        }
    }, [users.data, handleRefine.data]); // eslint-disable-line react-hooks/exhaustive-deps

    if (users.isLoading || handleRefine.isLoading) return <Loading />;

    return (
        <div className="matches-results-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-11 col-xxl-9 m-auto px-2">
                        <div className="d-xl-flex">

                            {/* Filter container */}
                            <div className="filter-container mb-2">
                                <div onClick={handleRefineShowHide} className="d-flex header p-3">
                                    <div><h6>Refine search</h6></div>
                                    <div className="ml-auto" style={{ color: '#fff' }}>
                                        {RefineShow ? <Icon icon={minus} size={25} /> : <Icon icon={plus} size={25} />}
                                    </div>
                                </div>
                                {RefineShow ?
                                    <div className="body">
                                        <FilterItems />
                                    </div>
                                    : null}
                            </div>

                            {/* Result container */}
                            <div className="result-container">
                                <div className="card border-0 mb-3 info-card">
                                    <div className="card-body">
                                        {
                                            users?.data?.length > 0 ? <h6 className="mb-1"> {handleRefine?.data && `${handleRefine.data.length} out of `} {users?.data?.length} New matches are available as your partner preference.</h6>
                                                : <h6 className="mb-1">New matches is not available as your partner preference.</h6>
                                        }
                                    </div>
                                </div>
                                {
                                    handleRefine?.data
                                        ?
                                        handleRefine?.data?.map((item, i) => <Profile key={i} item={item} />)
                                        :
                                        users?.data?.map((item, i) => <Profile key={i} item={item} />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;