import React, { useState, useEffect, createContext } from 'react';


export const singleProContext = createContext();

const SingleProfileContext = ({ children }) => {
    const [ids, setIds] = useState([]);


    const handleLocalStoreIds = (storeIds) => {
        setIds(storeIds);
        localStorage.setItem("single_profile_ids", storeIds);
    }

    const storageIds = async () => {
        let myLocal = await localStorage.getItem("single_profile_ids") || [];
        if (myLocal.length > 0) {
            myLocal = myLocal.split(",")
        }
        setIds(myLocal);
    }

    useEffect(() => {
        storageIds();
    }, [])

    const { Provider } = singleProContext;

    return (
        <Provider value={{ ids, setIds, handleLocalStoreIds }} >
            {children}
        </Provider>
    );
};

export default SingleProfileContext;