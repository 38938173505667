import { createSlice } from '@reduxjs/toolkit'

export const OnlineUserSlice = createSlice({
    name: "onlineusers",
    initialState: { onlineUsers: null },
    reducers: {
        onlineUsersSetter: (state, action) => {
            state.onlineUsers = action.payload
        },
    },
});

export const { onlineUsersSetter } = OnlineUserSlice.actions

export default OnlineUserSlice.reducer;