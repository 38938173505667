import React, { useContext, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Form } from 'react-bootstrap'
import { plus, minus } from 'react-icons-kit/feather'
import { RefineContext } from '../../utils/context/RefineSearchContext'

const Index = (props) => {
    const { handleRefineSearch, refineSearchObj } = useContext(RefineContext);
    const [show, setShow] = useState(false);
    const handleShowHide = () => {
        setShow(!show)
    }

    const toCapitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="custom-collapse boder-bottom">

            {/* Collapse header */}
            <div
                onClick={handleShowHide}
                className={show ? "collapse-header" : "collapse-header"}
            >
                <div className="d-flex">
                    <div><h6>{props.header}</h6></div>
                    <div className="ml-auto">
                        {show ? <Icon icon={minus} size={18} /> : <Icon icon={plus} size={18} />}
                    </div>
                </div>
            </div>

            {/* Collapse body */}
            {show ?
                <div className="collapse-body">
                    {props.options && props.options.length ?
                        props.options.map((item, i) =>
                            <Form.Check
                                key={i}
                                custom
                                name={props.name}
                                value={item.value}
                                id={`${item.value}-radio-button-${props?.name}`}
                                label={toCapitalize(item.label)}
                                onClick={(event) => {
                                    handleRefineSearch(event);
                                }}
                                defaultChecked={refineSearchObj[props?.name]?.indexOf(item.value) > -1}
                            />
                        ) : null}
                </div>
                : null}
        </div>
    );
};

export default Index;