import { createSlice } from '@reduxjs/toolkit'

export const ArrivalMessageSlice = createSlice({
    name: "arrivalMessage",
    initialState: { data: [], count: 0 },
    reducers: {
        arrivalMessageSetter: (state, action ) => {
            return {
                ...state,
                data: [action.payload, ...state.data],
                count: state.count + 1
            }
        },
        seenMessage : ( state, action) => {
            return {
                ...state,
                data : state.data.filter(one => one.sender !== action.payload),
                count : state.count - state.data.filter(one => one.sender === action.payload).length
            }
        }
    },
});

export const { arrivalMessageSetter, seenMessage } = ArrivalMessageSlice.actions

export default ArrivalMessageSlice.reducer;