import React from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { phoneOutgoing } from 'react-icons-kit/feather';
import { chatbubbleWorking, socialWhatsapp } from 'react-icons-kit/ionicons';


const ContactViewConfirm = (props) => {
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Contact View Confirm.</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <div className="flex-column flex-center">

                        <p>
                            Your need Click Confirm for contact view
                        </p>

                        <div className="d-flex justify-content-between">
                            <button
                                type="button"
                                className="contact_btn call"
                            >
                                <Icon icon={phoneOutgoing} size={14} /> Call
                            </button>

                            <button
                                type="button"
                                className="contact_btn whatsapp"
                            >
                                <Icon icon={chatbubbleWorking} size={16} /> WhatsApp
                            </button>

                            <button
                                type="button"
                                className="contact_btn sadi_chat"
                            >
                                <Icon icon={socialWhatsapp} size={16} /> Chat
                            </button>
                        </div>

                        <p className="text-center mt-3"> For </p>

                        <div className="d-flex justify-content-center mb-3">
                            <button
                                onClick={props.handleViewConfirm}
                                className="view_plan_btn">
                                View Confirm
                            </button>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ContactViewConfirm;