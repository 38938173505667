import React from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'
import FourOFour from '../FourOfour/Index'

const Master = () => {
    return (
        <div className="timeline-master">
            <Switch>
                <Route exact path="/timeline/" component={Index} />

                <Route path="*" component={FourOFour} />
            </Switch>
        </div>
    );
};

export default Master;