import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../Form/SingleSelect'
import { Height } from '../../OptionsData/HeightData/Heightdata'
import { BloodGroup } from '../../OptionsData/BloodGroup/BloodGroup'
import { MaterialStatus } from '../../OptionsData/MaterialStatus/MaterialStatus'
import { HealthInfo } from '../../OptionsData/HealthInfo/HealthInfo'
import { Diet } from '../../OptionsData/Diet/Diet'
import { Disability } from '../../OptionsData/Disability/Disability'
import Requests from '../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const BasicLifeStyleModal = (props) => {
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const { handleSubmit, register } = useForm()

    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        options: MaterialStatus
    })

    const [height, setHeight] = useState({
        value: null,
        options: Height
    })

    const [bloodGroup, setBloodGroup] = useState({
        value: null,
        options: BloodGroup
    })

    const [healthInformation, setHealthInformation] = useState({
        value: null,
        options: HealthInfo
    })

    const [diet, setDiet] = useState({
        value: null,
        options: Diet
    })

    const [disability, setDisability] = useState({
        value: null,
        options: Disability
    })

    const onSubmit = async (data) => {

        const obj = {
            age: data.age ? data.age : null,
            name: data.name ? data.name : null,
            materialStatus: materialStatus.value,
            bloodGroup: bloodGroup.value,
            height: height.value,
            healthInformation: healthInformation.value,
            diet: diet.value,
            disability: disability.value,
            bodyWeight: data.bodyWeight ? data.bodyWeight : null
        }
        setLoading(true)
        let response = await Requests.Profile.UpdateBasicLifeStyle(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }
    const handleAge = (age) => {
        var date = new Date(age);
        return date.toISOString().substring(0, 10);
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Basic Life Style</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Name</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Enter your fullname"
                                defaultValue={props.name || ''}
                                {...register('name')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Your Date of birth</p>
                            <input
                                type="date"
                                className="form-control shadow-none"
                                defaultValue={handleAge(props.data.age) || ''}
                                {...register("age", {
                                    valueAsDate: true,
                                })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Material status</p>

                            <SingleSelect
                                options={materialStatus.options}
                                data={props.data.materialStatus}
                                value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Your Height</p>

                            <SingleSelect
                                options={height.options}
                                data={props.data.height}
                                value={(data) => setHeight({ ...height, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Your Blood Group</p>

                            <SingleSelect
                                options={bloodGroup.options}
                                data={props.data.bloodGroup}
                                value={(data) => setBloodGroup({ ...bloodGroup, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Diet</p>

                            <SingleSelect
                                options={diet.options}
                                data={props.data.diet}
                                value={(data) => setDiet({ ...diet, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Health Information</p>

                            <SingleSelect
                                options={healthInformation.options}
                                data={props.data.healthInformation}
                                value={(data) => setHealthInformation({ ...healthInformation, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Disability</p>

                            <SingleSelect
                                options={disability.options}
                                data={props.data.disability}
                                value={(data) => setDisability({ ...disability, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Body Weight</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                placeholder="Enter body wight in kg..."
                                defaultValue={props.data.bodyWeight || ''}
                                {...register('bodyWeight')}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BasicLifeStyleModal;