import React, { useState, useEffect, useRef } from 'react';
import './App.scss'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { io } from "socket.io-client";
import AppBar from './components/AppBar/Index'
import ScrollToTop from './components/ScrollToTop/Index'
import SingleProfile from './pages/SingleProfile/Master'
import MyBiyesadiMaster from './pages/MyBiye/Master'
import MatchesMaster from './pages/Matches/Master'
import SearchMaster from './pages/Search/Master'
import ConnectionMaster from './pages/Connection/Master'
import TimelineMaster from './pages/Timeline/Master'
import Messanger from './pages/Messanger/Index'
import FourOFour from './pages/FourOfour/Index'
import queryString from 'query-string'
import OptionContextProvider from './utils/context/optionContext'
import UserContextProvider from './utils/context/userContext'
import SingleProfileContextProvider from './utils/context/SingleProfileContext'
import Icon from 'react-icons-kit'
import { chevronUp } from 'react-icons-kit/feather'
import RefineSearchContext from './utils/context/RefineSearchContext';
import { useSelector, useDispatch } from 'react-redux'
import { arrivalMessageSetter } from './utils/Redux/Slice/Chat/ArraivalMessageSlice'
import { onlineUsersSetter } from './utils/Redux/Slice/Chat/OnlineUserSlice'
import { doRefatch } from './utils/Redux/Slice/RefatchSlice'
import { incomingProposal, acceptedProposal, doProfileViewed } from './utils/Redux/Slice/NotificationSlice'

toast.configure({ autoClose: 2000 })

function App() {
  const socket = useRef()
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.user.userData.userID)
  const [isVisible, setIsVisible] = useState(false)
  const query = queryString.parse(window.location.search)

  let token
  const savedToken = localStorage.getItem('token')
  if (savedToken) {
    token = savedToken
  } else if (query.token) {
    token = query.token
    localStorage.setItem('token', query.token)
  }

  useEffect(() => {
    socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
  }, [])

  useEffect(() => {
    socket.current.emit('addUser', userID)
    socket.current.on("onlineUsers", users => {
      if (users && users.length > 0) {
        dispatch(onlineUsersSetter(users))
      }
    })
  }, [userID, dispatch])

  useEffect(() => {
    socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })

    socket.current.on("getMessage", (data) => {
      const { messageImage, senderId, text } = data

      if (!messageImage && !text) {
        console.log("invalid message");
      }
      else {
        if (messageImage) {
          let data = {
            sender: senderId,
            text: text,
            messageImage: messageImage,
            createdAt: Date.now(),
          }
          setTimeout(() => {
            dispatch(arrivalMessageSetter(data))
          }, 3000)
        }
        else {
          let data = {
            sender: senderId,
            text: text,
            createdAt: Date.now()
          }
          dispatch(arrivalMessageSetter(data))
        }
      }
    })

    socket.current.on("sendProposalAlert", (data) => {
      const { name } = data
      toast.success(`${name} send you a proposal`)
      dispatch(incomingProposal())
      if (window.location.pathname === '/connection/pending') {
        dispatch(doRefatch())
      }
    })

    socket.current.on("acceptProposalAlert", (data) => {
      const { name } = data
      toast.success(`${name} accepted your proposal`)
      dispatch(acceptedProposal())
      if (window.location.pathname === '/connection/') {
        dispatch(doRefatch())
      }
    })

    socket.current.on("profileViewedAlert", (data) => {
      dispatch(doProfileViewed(data))
    })

  }, [dispatch]);

  // useEffect(() =>{
  //   socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket"] })
  //   socket.current.on("profileViewedAlert", (data) => {
  //     dispatch(doProfileViewed(data))
  //   })
  // },[dispatch])

  const listenToScroll = () => {
    let heightToShow = 700;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop

    if (winScroll > heightToShow) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () =>
      window.removeEventListener("scroll", listenToScroll)
  }, [])

  const gotoTop = e => {
    e.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (!token) {
    setTimeout(() => {
      window.location.replace(process.env.REACT_APP_BIYESADI_URL)
    }, 3000)

    return (
      <div className="text-center py-4">
        <p className="mb-0">You have to need login first.</p>
        <p>Redirecting to main application after 3s...</p>
      </div>
    )
  }

  return (
    <div className="App">
      {
        isVisible ?
          <div className="d-none d-lg-block">
            <button className="upBtn shadow-none" onClick={gotoTop}>
              <Icon icon={chevronUp} size={20} />
            </button>
          </div> :
          null
      }

      <OptionContextProvider>
        <UserContextProvider>
          <SingleProfileContextProvider>
            <RefineSearchContext>

              <Router>
                <ScrollToTop>
                  <AppBar />
                  <Switch>

                    <Route path="/my-biyesadi" component={MyBiyesadiMaster} />
                    <Route path="/matches" component={MatchesMaster} />
                    <Route path="/single-profile/:id" component={SingleProfile} />
                    <Route path="/search" component={SearchMaster} />
                    <Route path="/connection" component={ConnectionMaster} />
                    <Route path="/timeline" component={TimelineMaster} />
                    <Route path="/messanger" component={Messanger} />
                    <Route exact path="/" render={() => (<Redirect to="/my-biyesadi" />)} />
                    <Route path="*" component={FourOFour} />

                  </Switch>
                </ScrollToTop>
              </Router>

            </RefineSearchContext>
          </SingleProfileContextProvider>
        </UserContextProvider>
      </OptionContextProvider>
    </div>
  );
}

export default App;
