import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// All match profiles
const All = async (header, page) => {
    try {
        const response = await Axios.get(`${api}match/all?page=${page}`, header);
        if (response.status === 200) {
            // console.log("REsponse : ", response.data)
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// All match profiles
const NewMatch = async (header) => {
    try {
        const response = await Axios.get(`${api}match/new-match`, header);
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// Today match profiles
const Today = async (header) => {
    try {
        const response = await Axios.get(`${api}match/today`, header);
        if (response.status === 200) {
            return response.data
        };
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Near match profiles
const Near = async (header) => {
    try {
        const response = await Axios.get(`${api}match/near`, header);
        if (response.status === 200) {
            return response.data
        };
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Recent viewed profiles
const RecentViewed = async (header) => {
    try {
        const response = await Axios.get(`${api}match/recent-viewed`, header);
        if (response.status === 200) {
            return response.data
        };
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Matches = {
    All,
    NewMatch,
    Today,
    Near,
    RecentViewed
}

export default Matches