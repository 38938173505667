import React, { useContext } from 'react'
import './style.scss'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { Images } from "../../../utils/Images"
import { u0031, u0032, u0033 } from 'react-icons-kit/noto_emoji_regular'
import { checkCircle } from 'react-icons-kit/feather'
import { UserContext } from '../../../utils/context/userContext'
import Loading from '../../../components/Loading/Index'
import { useHistory } from 'react-router-dom'
const Index = () => {

    const { user } = useContext(UserContext)
    let history = useHistory();
    let myContact = user?.contactInformation

    const changeUrl = () => {
        history.push('/my-biyesadi/profile?dv=con')
    }

    if (!user) return <Loading />

    return (
        <div className="contact-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />
                            <div className="flex-fill main-section px-lg-4 pb-4">
                                {/* hello  */}
                                <div className="row">
                                    <div className="col-12 col-lg-7">
                                        <div className="card shadow contact-card pb-5" >
                                            <div className="card-header px-4 pt-3 pb-5 ">
                                                <div className="d-flex">
                                                    <div className="mb-0"><h5 >Contact Number</h5></div>
                                                    {/* <div className="ml-auto">
                                                        <button className="btn rounded-circle shadow" onClick={toggleShowA}>
                                                            <Icon icon={edit2} size={20} />
                                                        </button>
                                                        <Toast show={showA} onClose={toggleShowA} className="toast-style" >
                                                            <Toast.Header>
                                                                <strong className="mr-auto">Confirm your password</strong>
                                                                {/* <small>11 mins ago</small> */}
                                                    {/* </Toast.Header>
                                                            <Toast.Body>
                                                                <form onSubmit={verifyPassword}>
                                                                    <div className="form-row">
                                                                        <div className="col-8">
                                                                            <input type="text" className="form-control " placeholder="Enter password" />
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <button className="btn  rounded shadow ml-2 btn-sm active">Submit</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </Toast.Body>
                                                        </Toast>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="card-body text-center">
                                                <div className="pb-2">
                                                    <img src={Images.PhoneIcon} alt="..." />
                                                </div>
                                                <table className="text-center m-auto">
                                                    <tbody>
                                                        <tr>
                                                            <th>Mobile</th>
                                                            <td className="pl-2">:</td>
                                                            <td >{user.phone ? user.phone : " - "}</td>
                                                            <td className="pl-1"> <i>{user.isPhoneVerified ? "Verified" : "Unverified"}</i></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <div className="card shadow contact-card mt-4" >
                                            <div className="card-header px-4 pt-3 pb-0">
                                                <div className="d-flex ">
                                                    <div className="mb-0"><h5 >Contact Number</h5></div>
                                                    <div className="ml-auto ">
                                                        <button className="btn rounded-circle shadow"
                                                            onClick={changeUrl}
                                                        >
                                                            <Icon icon={edit2} size={20} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body px-5">
                                                <div className="row" >
                                                    <div className=" col-12  ">
                                                        <table className="font-size-base">
                                                            <thead>
                                                                <tr>
                                                                    <th>Mobile No</th>
                                                                    <td className="pl-5">:</td>
                                                                    <td >{user.phone ? user.phone : " --- "}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Contact Person</th>
                                                                    <td className="pl-5">:</td>
                                                                    <td>{myContact?.contactPersonName ? myContact.contactPersonName : " --- "}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Relationship with this person</th>
                                                                    <td className="pl-5">:</td>
                                                                    <td>{myContact?.relationship ? myContact.relationship : " --- "}</td>
                                                                </tr>
                                                                {/* </thead>
                                                        </table>
                                                    </div>
                                                    <div className="col-12 col-md-6 ">
                                                        <table >
                                                            <thead> */}
                                                                <tr>
                                                                    <th>Convineant time to call</th>
                                                                    <td className="pl-5">:</td>
                                                                    <td>{myContact?.convenientTimeToCall ? myContact.convenientTimeToCall : "---"}</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th>Display option</th>
                                                                    <td className="pl-md-5">:</td>
                                                                    <td>you have choose to show contact details only premium members</td>
                                                                </tr> */}
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <div className="card shadow contact-card" >
                                            <div className="card-header px-4 pt-3 pb-0">
                                                <div className="d-flex ">
                                                    <div className="mb-0"><h5 >3 Step verification</h5></div>

                                                </div>
                                            </div>
                                            <div className="card-body px-5">
                                                <div className="verification-steps">
                                                    <div className="varticale-line-style"></div>
                                                    <div className="vartical-content">
                                                        <div className="media">

                                                            <Icon className="mr-3 rounded-circle shadow" icon={u0031} size={20} />
                                                            <div className="media-body">
                                                                Click on the 'Verify Now' button
                                                            </div>
                                                        </div>
                                                        <div className="media mt-5">

                                                            <Icon className="mr-3 rounded-circle shadow" icon={u0032} size={20} />
                                                            <div className="media-body">
                                                                You will receive a 4 digit verification PIN for validation
                                                            </div>
                                                        </div>
                                                        <div className="media mt-5">
                                                            <Icon className="mr-3  rounded-circle shadow" icon={u0033} size={20} />
                                                            <div className="media-body">
                                                                Enter the PIN number and click 'Verify' to verify your number
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-5" />
                                                <div className="verification-benefits mt-5">
                                                    <div className="media mt-3">
                                                        <Icon className="mr-3  rounded-circle shadow" icon={checkCircle} size={20} />
                                                        <div className="media-body">
                                                            Verify profile and trusted by others
                                                        </div>
                                                    </div>
                                                    <div className="media mt-3">
                                                        <Icon className="mr-3  rounded-circle shadow" icon={checkCircle} size={20} />
                                                        <div className="media-body">
                                                            Get Important Notifications and matches
                                                        </div>
                                                    </div>
                                                    <div className="media mt-3">
                                                        <Icon className="mr-3  rounded-circle shadow" icon={checkCircle} size={20} />
                                                        <div className="media-body">
                                                            Get Potencial Matches
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;