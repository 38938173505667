import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SingleSelect from '../../../components/Form/SingleSelect'
import { useHistory } from "react-router-dom";
import { optionContext } from '../../../utils/context/optionContext'
import Loading from '../../../components/Loading/Index'
import { Height } from '../../../components/OptionsData/HeightData/Heightdata'
import { Age } from '../../../components/OptionsData/Age/Age'
import QuickLink from '../../../components/MyBiye/QuickItems/Index'
import { useQuery } from 'react-query';

const Index = () => {
    
    const { options } = useContext(optionContext)
    let history = useHistory()
    const [isLoading, setLoading] = useState(false)
    let [pageload, setPageload] = useState(true)

    const [age1, setAge1] = useState({
        value: null,
        options: Age
    })

    const [age2, setAge2] = useState({
        value: null,
        options: Age
    })

    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        options: [
            { value: 'null', label: 'Any' },
            { value: 'never married', label: 'Never Married' },
            { value: 'divorced', label: 'Divorced' },
            { value: 'annulled', label: 'Annulled' },
            { value: 'widowed', label: 'Widowed' },
            { value: 'married', label: 'Married' }
        ]
    })

    const [height1, setHeight1] = useState({
        value: null,
        options: Height
    })

    const [height2, setHeight2] = useState({
        value: null,
        options: Height
    })

    const [religion, setReligion] = useState({
        value: null,
        options: []
    })

    const [motherTongue, setMotherTongue] = useState({
        value: null,
        options: []
    })

    function setAllOptions() {
        let arr = []
        let fields = options.settingFiled2
        for (let index = 0; index < fields.length; index++) {
            const element = fields[index]
            if (element.label === "Language") {
                setMotherTongue({ ...motherTongue, options: element.value })
                arr.push(element)
            }
            else if (element.label === "Religion") {
                setReligion({ ...religion, options: element.value })
                arr.push(element)
            }
        }
        setPageload(false)
        return arr
    }

    const multipleOptions = useQuery('basicOptions', setAllOptions, {
        cacheTime: 1500000,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageload)
    })

    // Submit form
    const submitForm = event => {
        event.preventDefault()
        setLoading(true)

        if (!materialStatus.value && !religion.value && !motherTongue.value
            && (!height1.value || !height2.value)
            && (!age1.value || !age2.value)) {
            toast.warn('please provide values.')
            setLoading(false)
        }
        else {
            setTimeout(() => {
                setLoading(false)
                history.push(`/search/results?page=1&limit=20&gender=male&age1=${age1.value}&age2=${age2.value}&height1=${height1.value}&height2=${height2.value}&religion=${religion.value}&materialStatus=${materialStatus.value}&motherTongue=${motherTongue.value}`)
            }, 2000)
        }
    }

    if (!options) return <Loading />
    if (multipleOptions.isLoading) return <Loading />

    return (
        <div className="container search-container">
            <div className="row">
                <QuickLink />
                {/* main search */}
                <div className="col-12 col-lg-8 mx-auto">
                    <div className="card border-0">
                        <div className="card-body p-lg-4">
                            <form onSubmit={submitForm}>
                                <div className="row">

                                    <div className="col-12 col-lg-8 col-xl-6 mb-2">
                                        {/* Age range */}
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                <div className="form-group">
                                                    <p>Age</p>
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={age1.options}
                                                        value={(data) => setAge1({ ...age1, value: data })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-2"><h6>To</h6></div>
                                            <div className="flex-fill pt-4">
                                                <div className="form-group">
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={age2.options}
                                                        value={(data) => setAge2({ ...age2, value: data })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Height range */}
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                <div className="form-group">
                                                    <p>Height</p>
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={height1.options}
                                                        value={(data) => setHeight1({ ...height1, value: data })} />
                                                </div>
                                            </div>
                                            <div className="px-2"><h6>To</h6></div>
                                            <div className="flex-fill pt-4">
                                                <div className="form-group">
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={height2.options}
                                                        value={(data) => setHeight2({ ...height2, value: data })} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Material status */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Material status</p>

                                            <SingleSelect
                                                options={materialStatus.options}
                                                value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Religion / Community */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Religion / Community</p>

                                            <SingleSelect
                                                options={religion.options}
                                                value={(data) => setReligion({ ...religion, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Mother tongue */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Mother tongue</p>

                                            <SingleSelect
                                                options={motherTongue.options}
                                                value={(data) => setMotherTongue({ ...motherTongue, value: data })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 text-right">
                                        <button
                                            type="submit"
                                            className="btn shadow-none"
                                            disabled={isLoading}
                                        >{isLoading ? 'Searching...' : 'Search'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;