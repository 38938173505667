import React, { useState, useContext } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import FamilyModal from './Modal/FamilyInfo'
import { UserContext } from '../../utils/context/userContext';

const FamilyDetails = (props) => {

    const [familyModel, setFamilyModel] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    
    const { familyLocation, familyType, familyAffluence, familyValue, father, mother,
        nativePlace, yourOrder, numberOfBrothers, numberOfBrothersMarried,
        numberOfSisters, numberOfSistersMarried
    } = user.familyInfo

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Family Details</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setFamilyModel({ ...familyModel, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>

                                    <tr>
                                        <th>Family Location</th>
                                        <td className="pl-5">:</td>
                                        <td>{familyLocation ? familyLocation : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Family Type</th>
                                        <td className="pl-5">:</td>
                                        <td>{familyType ? familyType : "N/A"}</td>
                                    </tr>

                                    <tr>
                                        <th>Father's Name</th>
                                        <td className="pl-5">:</td>
                                        <td>{father.name ? father.name : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Father's Occupation</th>
                                        <td className="pl-5">:</td>
                                        <td>{father.status ? father.status : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Mother's Name</th>
                                        <td className="pl-5">:</td>
                                        <td>{mother.name ? mother.name : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Mother's Occupation</th>
                                        <td className="pl-5">:</td>
                                        <td>{mother.status ? mother.status : "N/A"}</td>

                                    </tr>
                                    <tr>
                                        <th>Your order as child</th>
                                        <td className="pl-5">:</td>
                                        <td>{yourOrder ? yourOrder : 'N/A'}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>No of Brother</th>
                                        <td className="pl-5">:</td>
                                        <td>{numberOfBrothers ? numberOfBrothers : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Married Brother</th>
                                        <td className="pl-5">:</td>
                                        <td>{numberOfBrothersMarried ? numberOfBrothersMarried : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>No of Sister</th>
                                        <td className="pl-5">:</td>
                                        <td>{numberOfSisters ? numberOfSisters : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Married Sister</th>
                                        <td className="pl-5">:</td>
                                        <td>{numberOfSistersMarried ? numberOfSistersMarried : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Family Affluence</th>
                                        <td className="pl-5">:</td>
                                        <td>{familyAffluence ? familyAffluence : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Family Value</th>
                                        <td className="pl-5">:</td>
                                        <td>{familyValue ? familyValue : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Grow up in</th>
                                        <td className="pl-5">:</td>
                                        <td >{nativePlace ? nativePlace : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {familyModel.show ?
                <FamilyModal
                    show={familyModel.show}
                    data={user.familyInfo}
                    loading={familyModel.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setFamilyModel({ ...familyModel, show: false, loading: false })}
                />
                : null}
        </div>
    )
};

export default FamilyDetails;