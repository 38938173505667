import React, { useRef, useState, useContext} from 'react'
import './style.scss'
import Modal from 'react-bootstrap/Modal'
import Icon from 'react-icons-kit'
import { x } from 'react-icons-kit/feather'
import AvatarEditor from 'react-avatar-editor'
// import { useForm } from 'react-hook-form'
import Requests from '../../utils/Requests/Index'
import { toast } from 'react-toastify'
import { UserContext } from '../../utils/context/userContext'
toast.configure({ autoClose: 2000 })

const Picture = (props) => {

    const [loading, setLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false)
    const editor = useRef()
    const [show, setShow] = useState(false)
    const [propImage, setPropImage] = useState(props.image)
    const [previewLink, setPreviewLink] = useState(null)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const { handleRefetch } = useContext(UserContext)

    const onClickSave = async (event) => {
        event.preventDefault()
        if (editor.current) {
            const canvasScaled = editor.current.getImageScaledToCanvas()
            const croppedImg = canvasScaled.toDataURL()
            const blob = await (await fetch(croppedImg)).blob();
            const file = new File([blob], 'fileName.jpg', { type: "image/jpg", lastModified: new Date() });
            var formData = new FormData()
            formData.append('image', file)
            setLoading(true)
            const response = await Requests.Dashboard.UploadProfilePicture(formData, header)
            if (response.status) {
                props.setPageload(true)
                props.onHide()
                toast.success('Image upload successful')
                setLoading(false)
                handleRefetch()
            }
            else {
                props.onHide()
            }
        }
        else {
            props.onHide()
            toast.error('Select image from filelocation')
        }
    }

    const onClickDelete = async (event) => {
        event.preventDefault()
        setDelLoading(true)
        const response = await Requests.Dashboard.DeleteProfilePicture(header)
        if (response.status) {
            props.setPageload(true)
            props.onHide()
            toast.success('Profile pictute is deleted.')
            setDelLoading(false)
            handleRefetch()
        }
        else {
            props.onHide()
        }
    }

    const onChange = (e) => {
        var tmppath = URL.createObjectURL(e.target.files[0])
        setShow(true)
        setPropImage(null)
        setPreviewLink(tmppath)
    }
    
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                className="picture-crop-modal"
            >
                <Modal.Header className="border-0">
                    <div className="w-100 text-right">
                        <button type="button" className="btn shadow-none" onClick={props.onHide}>
                            <Icon icon={x} size={22} />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="text-center p-0">
                    <div className="form-group mb-4">
                        <input
                            type="file"
                            className="form-control shadow-none"
                            placeholder="Currently living city"
                            onChange={onChange}
                        />
                    </div>
                    {
                        propImage ?
                            <img src={propImage} className="img-fluid rounded-circle" alt="..." /> :
                            show ?
                                <AvatarEditor
                                    image={previewLink}
                                    // width={400}
                                    // height={400}
                                    border={50}
                                    color={[255, 255, 255, 0.6]}
                                    scale={1.1}
                                    rotate={0}
                                    ref={editor}
                                />
                                : null
                    }

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button
                        type="button"
                        className="btn-outline-danger shadow-none del"
                        disabled={delLoading}
                        onClick={onClickDelete}>
                        {delLoading ? 'Deleting...' : 'Delete'}
                    </button>
                    <button
                        type="button"
                        className="btn shadow-none ml-auto"
                        disabled={loading}
                        onClick={onClickSave}>
                        {loading ? 'Uploading...' : 'Upload'}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Picture;