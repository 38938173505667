import React, { useContext } from 'react'
import Collapse from '../../Collapse/Index';
import { optionContext } from '../../../utils/context/optionContext'
import { MaterialStatus } from '../../OptionsData/MaterialStatus/MaterialStatus';

const Index = () => {
    const { options } = useContext(optionContext)

    const materialStatus = MaterialStatus;

    // OptionContext To search data... Function
    const optionToData = (search) => {
        let findSearch = options?.settingFiled?.find(info => info.label === search) || null;
        if (!findSearch) return null;
        let info = [];

        if (search === "Income") {
            for (let i = 0; i < findSearch?.value?.length; i++) {
                info.push({ label: `${findSearch?.value[i]} BDT`, value: findSearch?.value[i] });
            }
        } else {
            for (let i = 0; i < findSearch?.value?.length; i++) {
                info.push({ label: `${findSearch?.value[i]}`, value: findSearch?.value[i] });
            }
        }

        return info;
    };

    // Statics...
    // const match = [
    //     { value: 'all', label: 'All' },
    //     { value: '2-way', label: '2-way matches' }
    // ]

    const joinAdMember = [
        { value: 'all', label: 'All' },
        { value: 'today', label: 'Within a day' },
        { value: 'week', label: 'Within a week' },
        { value: 'month', label: 'Within a month' }
    ]

    const age = [
        { label: '18 - 21', value: '18-21' },
        { label: '22 - 25', value: '22-25' },
        { label: '26-30', value: '26-30' },
        { label: '31 - 40', value: '31-40' },
        { label: '41 - 60', value: '41-60' },
    ];

    const height = [
        { label: "4'5 - 5'0", value: '134-152' },
        { label: "5'1 - 5'6", value: '153-167' },
        { label: "5'7 - 6'0", value: '168-182' },
        { label: "6'1 - 6'6", value: '183-198' },
        { label: "6'7 - 7'0", value: '199-212' },
    ];

    return (
        <div>

            {/* Static Values */}
            {/* <Collapse name="matches" header="matches" options={match} /> */}
            <Collapse name="recentlyJoined" header="Recently Joined" options={joinAdMember} />
            <Collapse name="age" header="age" options={age} />
            <Collapse name="height" header="height" options={height} />
            <Collapse name="materialStatus" header="material status" options={materialStatus} />

            {optionToData("Language") && <Collapse name="language" header="language" options={optionToData("Language")} />}
            {optionToData("Religion") && <Collapse name="religion" header="religions" options={optionToData("Religion")} />}
            {optionToData("Social title") && <Collapse name="title" header="Social title" options={optionToData("Social title")} />}
            {optionToData("Country") && <Collapse name="livingCountry" header="living country" options={optionToData("Country")} />}
            {optionToData("Qualification") && <Collapse name="qualification" header="qualification" options={optionToData("Qualification")} />}
            {optionToData("Profession area") && <Collapse name="professionArea" header="profession area" options={optionToData("Profession area")} />}
            {optionToData("Working with") && <Collapse name="workingWith" header="working With" options={optionToData("Working with")} />}
            {optionToData("Income") && <Collapse name="income" header="income" options={optionToData("Income")} />}

        </div>
    );
};

export default Index;
