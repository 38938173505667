import React, { useState, useContext, useEffect, useRef } from 'react';
// import { Images } from '../../../utils/Images'
import ImageCrop from '../../../components/Form/Picture'
import { useLocation } from 'react-router-dom'
import About from "../../../components/MyProfile/About"
import BasicLifeStyle from "../../../components/MyProfile/BasicLifeStyle"
import ContactDetails from "../../../components/MyProfile/ContactDetails"
import EducationCareer from "../../../components/MyProfile/EducationCareer"
import FamilyDetails from "../../../components/MyProfile/FamilyDetails"
import LocationOfGroom from "../../../components/MyProfile/LocationOfGroom"
import PersonalInterest from "../../../components/MyProfile/PersonalInterest"
import ReligiousBackground from "../../../components/MyProfile/ReligiousBackground"
// partner preference
import BasicInformations from "../../../components/MyProfile/PartnerPreference/BasicInformations"
import LocationDetails from "../../../components/MyProfile/PartnerPreference/LocationDetails"
import PartnerEducationCareer from "../../../components/MyProfile/PartnerPreference/PartnerEducationCareer"
import Others from "../../../components/MyProfile/PartnerPreference/Others"
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import Loading from '../../../components/Loading/Index'
import { AgeCalculate, HeightCalculate } from '../../../utils/helpers'
import { UserContext } from '../../../utils/context/userContext'
import './style.scss'


const Index = () => {

    let { search } = useLocation();
    const [show, setShow] = useState(false)
    const { user } = useContext(UserContext)
    const contact = useRef(null)
    const parterpreference = useRef(null)

    
    useEffect(()=>{
        if (search.split('=')[1] === 'con') {
            contact.current.scrollIntoView({behavior: "smooth"})
        }
        else if (search.split('=')[1] === 'parpre') {
            parterpreference.current.scrollIntoView({behavior: "smooth"})
        }
    },[search])


    if (!user) return <Loading />
    return (
        <div className="myprofile-container">
            <div className="container">
                <div className="row ">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />
                            <div className="flex-fill main-section px-lg-4 pt-4 pt-lg-0 pb-4">
                                <div className="row pt-3 pb-5">
                                    <div className="col-12 col-md-4">
                                        <div className="profileImage">
                                            <img src={user.profilePicture.clearImage} className="rounded-circle img-fluid" alt="" />
                                            {/* <button type="button" className="btn shadow rounded-circle" onClick={() => setShow(true)}>
                                                <Icon icon={edit2} size={18} />
                                            </button> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 profileBasicInfo">
                                        <h5 className="">{user.name}</h5>
                                        <div className="pt-2 pb-5 ml-3" >
                                            <table >
                                                <tr>
                                                    <th>ID</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td >{user.profileId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Material Status</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user?.basicAndLifestyleInformation?.materialStatus ? user.basicAndLifestyleInformation.materialStatus : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Posted By</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user?.profileCreatedFor ? user?.profileCreatedFor : "Not Available"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Mother Tongue</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user?.language.motherTongue ? user?.language.motherTongue : "Not Available"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Age & Height</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user.dob ? AgeCalculate(user.dob) : "Age"} & {user?.basicAndLifestyleInformation?.height ? HeightCalculate(user.basicAndLifestyleInformation.height) : "Height not available"}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="progress shadow">
                                            <div className="progress-bar" role="progressbar" style={{ width: user.profileUpdateRange + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">{user.profileUpdateRange}%</div>
                                        </div>
                                    </div>
                                </div>
                                <About />
                                <BasicLifeStyle profileId={user.profileId} name={user.name} />
                                <ReligiousBackground />
                                <FamilyDetails />
                                <EducationCareer />
                                <LocationOfGroom />
                                <PersonalInterest />
                                <div ref={parterpreference} id="parterpreference" name="parterpreference" className="shadow p-3 mb-3 mt-5 bg-body rounded">Partner Preference</div>
                                <BasicInformations />
                                <LocationDetails />
                                <PartnerEducationCareer />
                                <Others />
                                {/* <Scroll.Element id="contact" name="contact"> */}
                                <div ref={contact} id="contact" name="contact-div" className="shadow p-3 mb-3 mt-5 bg-body rounded">Contact Details</div>
                                {/* </Scroll.Element> */}
                                <ContactDetails />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show ? <ImageCrop show={show} onHide={() => setShow(false)} /> : null}
        </div>
    );
};

export default Index;