import React, { useState, useContext, useEffect, useRef } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { users, check } from 'react-icons-kit/feather'
import { phoneOutgoing } from 'react-icons-kit/feather';
import { chatbubbleWorking, socialWhatsapp } from 'react-icons-kit/ionicons';
import { chevronRight, chevronLeft } from 'react-icons-kit/fa'
import { commentsO } from 'react-icons-kit/fa'
import { UserContext } from "../../utils/context/userContext";
import { Carousel } from 'react-bootstrap';
import Requests from '../../utils/Requests/Index'
import { HeightCalculate } from '../../utils/helpers'
import { Link, useHistory } from 'react-router-dom'
import ContactInfo from '../SingleProfile/Modal/ContactInfo';
import ContactNumber from "../SingleProfile/Modal/ContactNumber";
import ContactViewConfirm from '../SingleProfile/Modal/ContactViewConfirm'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { mobileMemberSetter } from '../../utils/Redux/Slice/Chat/SingleChatMemberSlice'
import { memberSetter } from '../../utils/Redux/Slice/Chat/ChatMembersSlice'
import { setTrue } from '../../utils/Redux/Slice/Chat/MessageBoxStateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { io } from "socket.io-client";

toast.configure({ autoClose: 2000 })

const Index = ({ item }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { user, handleRefetch } = useContext(UserContext)
    const [index, setIndex] = useState(0)
    const [isLoading, setLoading] = useState({ id: null, status: false })
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [send, setSend] = useState(false)
    // contact modals
    const [contactShow, setContactShow] = useState(false)
    const [viewConfirmModal, setViewConfirmModal] = useState(false)
    const [contactModal, setContactModal] = useState(false)
    const [contactNumberModal, setContactNumberModal] = useState({ show: false, number: '', numberType: '' });
    const socket = useRef()
    const userInfo = useSelector((state) => state.user.userData)

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
    }, [])


    // Send request
    const sendRequest = async id => {
        try {
            setLoading({ id: id, status: true })
            const response = await Requests.Connection.Connect(id, header)
            if (response) {
                toast.success(response.message)

                socket.current.emit("sendProposal", {
                    senderId: userInfo.userID,
                    name: userInfo.name,
                    receiverId: id
                })

                if (response.status) {
                    setSend(true);
                }
                handleRefetch()
            }
            setLoading({ id: null, status: false })
        } catch (error) {
            if (error) console.log(error)
        }
    }

    // Check sent or pending requests && check contactViewed
    useEffect(() => {
        if (user) {
            const check = user?.connections.find(info => info._id === item._id)
                // || user?.sentRequests.find(info => info._id === item._id)
                // || user?.pendingRequests.find(info => info._id === item._id)
                || user?.sentRequests.indexOf(item?._id) > -1 
                || user?.pendingRequests.indexOf(item?._id) > -1;

            if (check) {
                setSend(true)

            } else {
                setSend(false)
            }
        }

        if (user?.profileType === "Paid" && user?.packageInfo?.contactViewed?.indexOf(item._id) > -1) {
            setContactShow(true);
        }
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    // handleViewConfirm 
    const handleViewConfirm = async (userId) => {
        try {
            // This if else for UX;
            if (user.packageInfo.proposal.contactViewLimit <= 0) {
                toast.warning("Your contact viewed limit finished!");
                return;
            } else {
                setViewConfirmModal(false);
                setContactShow(true);
            }

            const response = await Requests.Profile.UpdateContactViewed(userId, header)
            if (response?.status) {
                toast.success(response.message);
                handleRefetch()
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Images slider prev && next function;
    const onPrevClick = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else if (index === 0) setIndex(item?.images?.length - 1 || 0);
    };
    const onNextClick = () => {
        if (index === item?.images?.length - 1) {
            setIndex(0);
        } else if (index === 0 || item?.images?.length - 1 > index) setIndex(index + 1);
    };

    const handleWebChatting = e => {
        e.preventDefault()
        let data = {
            _id: item._id,
            name: item.name,
            profileId: item.profileId ? item.profileId : null,
            birthCountry: item.birthCountry ? item.birthCountry : null,
            age: item.age ? item.age : null,
            materialStatus: item.materialStatus ? item.materialStatus : null,
            height: item.height ? HeightCalculate(item.height) : null,
            bloodGroup: item.bloodGroup ? item.bloodGroup : null,
            religion: item.religion ? item.religion : null,
            city: item.city ? item.city : null,
            workingWith: item.workingWith ? item.workingWith : "Not working",
            images: [item.images[0]]
        }
        dispatch(memberSetter(data))
        dispatch(setTrue(data))
    }

    const handleMobileChatting = e => {
        e.preventDefault()
        let data = {
            _id: item._id,
            name: item.name,
            profileId: item.profileId ? item.profileId : null,
            birthCountry: item.birthCountry ? item.birthCountry : null,
            age: item.age ? item.age : null,
            materialStatus: item.materialStatus ? item.materialStatus : null,
            height: item.height ? HeightCalculate(item.height) : null,
            bloodGroup: item.bloodGroup ? item.bloodGroup : null,
            religion: item.religion ? item.religion : null,
            city: item.city ? item.city : null,
            workingWith: item.workingWith ? item.workingWith : "Not working",
            images: [item.images[0]]
        }
        dispatch(mobileMemberSetter(data))
        history.push('/messanger')
    }
    return (
        <div className="profile-card">
            <div className="card shadow-sm mb-2 mb-xl-4">
                <div className="d-xl-flex align-items-center">
                    <div className="img-flex">
                        <div className="img-container">

                            <Carousel
                                activeIndex={index}
                                indicators={false}
                                controls={false}>
                                {item.images?.map((info, i) =>
                                    <Carousel.Item key={i}>
                                        <img className="img-fluid" src={info.name} alt={`${i + 1} no images`} />
                                        {
                                            item?.images?.length > 1 && <>
                                                <Carousel.Caption className="carousel_box">
                                                    <div className="carousel_caption">
                                                        <span onClick={onPrevClick} className="carousel-control-prev-icon" />
                                                        {" "} {i + 1} of {item?.images?.length} {" "}
                                                        <span onClick={onNextClick} className="carousel-control-next-icon" />
                                                    </div>
                                                </Carousel.Caption>

                                                <button onClick={onPrevClick} className="carousel-control-prev" type="button" >
                                                    <Icon icon={chevronLeft} size={35} />
                                                </button>
                                                <button onClick={onNextClick} className="carousel-control-next" type="button">
                                                    <Icon icon={chevronRight} size={35} />
                                                </button>
                                            </>
                                        }

                                    </Carousel.Item>

                                )
                                }
                            </Carousel>

                            {/* Overlay in mobile */}
                            <div className="mobile-overlay d-xl-none">
                                <div className="content">
                                    <div className="d-flex justify-content-between intro">
                                        <div>
                                            <Link to={`/single-profile/${item?._id}`} >
                                                <h5 className="profile_id">@{item.profileId}</h5>
                                            </Link>
                                            <Link to={`/single-profile/${item?._id}`} >
                                                <h5 className="profile_name">{item.name}</h5>
                                            </Link>
                                            <p> {item.religion}, {item.socialOrder}</p>
                                            <p> {item.age} years, {item.height ? HeightCalculate(item.height) : 'N/A'}</p>
                                            <p>instant chatting
                                                <Icon icon={commentsO}
                                                    size={18}
                                                    style={{ marginLeft: "5px", cursor: "pointer" }}
                                                    onClick={handleMobileChatting}
                                                /></p>
                                        </div>
                                        <div>
                                            <p> {item.livingCountry}</p>
                                            <p> {item.materialStatus}</p>
                                            <p> {item.professionArea}</p>
                                            <p> {item.workingWith}</p>
                                        </div>
                                    </div>
                                    <div className="boder"></div>
                                    <div className="action d-flex justify-content-between">
                                        {
                                            !send ?
                                                <>
                                                    <div><p><i>Like this profile ?</i></p></div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn shadow-none"
                                                            disabled={isLoading.status && item._id === isLoading.id}
                                                            onClick={() => sendRequest(item._id)}
                                                        >Propose Now</button>
                                                    </div>
                                                </>
                                                :
                                                <>

                                                    <button
                                                        onClick={() => {
                                                            contactShow ?
                                                                setContactNumberModal({
                                                                    ...contactNumberModal,
                                                                    show: true,
                                                                    numberType: "call",
                                                                    number: item.phone
                                                                })
                                                                :
                                                                user?.profileType === "Paid"
                                                                    ? setViewConfirmModal(true)
                                                                    : setContactModal(true)
                                                        }}
                                                        type="button"
                                                        className="contact_btn call"
                                                    >
                                                        <Icon icon={phoneOutgoing} size={14} /> Call
                                                    </button>

                                                    <button
                                                        onClick={() => {
                                                            contactShow ?
                                                                setContactNumberModal({
                                                                    ...contactNumberModal,
                                                                    show: true,
                                                                    numberType: "whatsapp",
                                                                    number: item.phone
                                                                })
                                                                :
                                                                user?.profileType === "Paid"
                                                                    ? setViewConfirmModal(true)
                                                                    : setContactModal(true)
                                                        }}
                                                        type="button"
                                                        className="contact_btn whatsapp"
                                                    >
                                                        <Icon icon={chatbubbleWorking} size={16} /> WhatsApp
                                                    </button>

                                                    <button
                                                        onClick={() => {
                                                            user?.profileType === "Free" && setContactModal(true)
                                                        }}
                                                        type="button"
                                                        className="contact_btn sadi_chat"
                                                    >
                                                        <Icon icon={socialWhatsapp} size={16} /> Chat
                                                    </button>

                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" flex-fill d-none d-xl-block ml-3">
                        <div className="info-container">

                            <div className="d-flex">
                                <div className={`flex-fill`}>
                                    <Link to={`/single-profile/${item?._id}`} >
                                        <h5 className="profile_name mr-1">{item.name}</h5>
                                    </Link>
                                </div>
                                <div className="flex-fill">
                                    <Link to={`/single-profile/${item?._id}`} >
                                        <span className="profile_id ml-1">ID-{item.profileId}</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className={`flex-fill`}>
                                    <p><Icon icon={commentsO}
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={handleWebChatting}
                                    /> Instant chatting</p>
                                </div>
                                <div className="flex-fill">
                                    <p><Icon icon={users} size={18} /> You & Her</p>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="d-flex">

                                <div className="flex-fill">

                                    <p> <span className="ul_circle"></span> {item.religion || "Religion --"}, {item.socialOrder}</p>
                                    <p> <span className="ul_circle"></span>{item.age || "---"} years</p>
                                    <p> <span className="ul_circle"></span> {item.materialStatus || "Material Status --"}</p>
                                    <p> <span className="ul_circle"></span> {item.professionArea || "Profession Area --"}</p>

                                </div>

                                <div className="flex-fill">

                                    <p> <span className="ul_circle"></span>Blood: {item.bloodGroup || "---"}</p>
                                    <p> <span className="ul_circle"></span>Birth: {item.birthYear || "---"} </p>
                                    <p> <span className="ul_circle"></span>Hight: {item.height ? HeightCalculate(item.height) : 'N/A'}</p>
                                    <p> <span className="ul_circle"></span>{item.livingCountry || "---"} </p>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="btn-container d-none d-xl-block">
                        {
                            !send ?
                                <div className="flex-column flex-center">
                                    <p><i>Like this profile ?</i></p>
                                    <button
                                        type="button"
                                        className="btn shadow-none"
                                        onClick={() => sendRequest(item._id)}
                                        disabled={isLoading.status && item._id === isLoading.id}
                                    >
                                        <Icon icon={check} size={22} />
                                    </button>
                                    <small>Propose Now</small>
                                </div>
                                :
                                <div className="flex-column flex-center">

                                    {
                                        user?.profileType === "Paid"
                                            ? null
                                            : <p>
                                                <Link to={"/my-biyesadi/package"} >Upgrade </Link>
                                                to Contact her directly
                                            </p>
                                    }

                                    <button
                                        onClick={() => {
                                            contactShow ?
                                                setContactNumberModal({
                                                    ...contactNumberModal,
                                                    show: true,
                                                    numberType: "call",
                                                    number: item.phone
                                                })
                                                :
                                                user?.profileType === "Paid"
                                                    ? setViewConfirmModal(true)
                                                    : setContactModal(true)
                                        }}
                                        type="button"
                                        className="contact_btn call"
                                    >
                                        <Icon icon={phoneOutgoing} size={14} /> Call
                                    </button>

                                    <button
                                        onClick={() => {
                                            contactShow ?
                                                setContactNumberModal({
                                                    ...contactNumberModal,
                                                    show: true,
                                                    numberType: "whatsapp",
                                                    number: item.phone
                                                })
                                                :
                                                user?.profileType === "Paid"
                                                    ? setViewConfirmModal(true)
                                                    : setContactModal(true)
                                        }}
                                        type="button"
                                        className="contact_btn whatsapp"
                                    >
                                        <Icon icon={chatbubbleWorking} size={16} /> WhatsApp
                                    </button>

                                    <button
                                        onClick={() => {
                                            user?.profileType === "Free" && setContactModal(true)
                                        }}
                                        type="button"
                                        className="contact_btn sadi_chat"
                                    >
                                        <Icon icon={socialWhatsapp} size={16} /> Chat
                                    </button>

                                </div>
                        }

                    </div >
                </div >
            </div >

            {/* Modal for unpaid/free accounts */}
            {
                contactModal ?
                    <ContactInfo
                        show={contactModal}
                        onHide={() => setContactModal(false)}
                    />
                    : null
            }

            {/* Modal for paid accounts. But, not contactViewed. */}
            {
                viewConfirmModal ?
                    <ContactViewConfirm
                        show={viewConfirmModal}
                        onHide={() => setViewConfirmModal(false)}
                        handleViewConfirm={() => handleViewConfirm(item?._id)}
                    />
                    : null
            }

            {/* Modal for paid && contactViewed accounts */}
            {
                contactNumberModal.show ?
                    <ContactNumber
                        show={contactNumberModal.show}
                        numberType={contactNumberModal.numberType}
                        number={contactNumberModal.number}
                        onHide={() => setContactNumberModal({ ...contactNumberModal, show: false })}
                    >

                    </ContactNumber>
                    : null
            }

        </div >
    );
};

export default Index;