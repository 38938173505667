import React, { useState, useContext } from 'react'
import Icon from 'react-icons-kit'
import HtmlParser from 'react-html-parser'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss'
import AboutModal from './Modal/About'
import { UserContext } from '../../utils/context/userContext';

const About = () => {
    const [aboutModel, setAboutModel] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">About my-self</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setAboutModel({ ...aboutModel, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <p className="text-justify">{user.shortDescription ? HtmlParser(user.shortDescription) : "Not available"}</p>
                </div>
            </div>
            {aboutModel.show ?
                <AboutModal
                    show={aboutModel.show}
                    data={user.shortDescription ? user.shortDescription : null}
                    loading={aboutModel.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setAboutModel({ ...aboutModel, show: false, loading: false })}
                />
                : null}
        </div>
    )
}

export default About