import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../Form/SingleSelect'
import Requests from '../../../utils/Requests/Index'


toast.configure({ autoClose: 2000 })
const FamilyModal = (props) => {
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [familyType, setFamilyType] = useState({
        value: null,
        options: [
            { label: 'Joint', value: 'Joint' },
            { label: 'Nuclear', value: 'Nuclear' }
        ]
    })
    const [familyAffluence, setFamilyAffluence] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Affluence', value: 'Affluence' },
            { label: 'Upper Middle Class', value: 'Upper Middle Class' },
            { label: 'Middle Class', value: 'Middle Class' },
            { label: 'Lower Middle Class', value: 'Lower Middle Class' }
        ]
    })

    const [familyValue, setFamilyValue] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Traditional', value: 'Traditional' },
            { label: 'Moderate', value: 'Moderate' },
            { label: 'Liberal', value: 'Liberal' }
        ]
    })
    const [fatherOccupation, setFatherOccupation] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Employed', value: 'Employed' },
            { label: 'Business', value: 'Business' },
            { label: 'Retired', value: 'Retired' },
            { label: 'Not Employed', value: 'NotEmployed' },
            { label: 'Passed Away', value: 'Passed Away' },
        ]
    })
    const [motherOccupation, setMotherOccupation] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Employed', value: 'Employed' },
            { label: 'Business', value: 'Business' },
            { label: 'Retired', value: 'Retired' },
            { label: 'Not Employed', value: 'NotEmployed' },
            { label: 'Passed Away', value: 'Passed Away' },
        ]
    })
    
    const onSubmit = async (data) => {
        const obj = {
            familyAffluence: familyAffluence.value,
            familyType: familyType.value,
            familyValue: familyValue.value,
            familyLocation: data.familyLocation ? data.familyLocation : null,
            nativePlace: data.nativePlace ? data.nativePlace : null,
            fatherName: data.fatherName ? data.fatherName : null,
            fatherStatus: fatherOccupation.value,
            motherName: data.motherName ? data.motherName : null,
            motherStatus: motherOccupation.value,
            yourOrder: data.yourOrder ? data.yourOrder : null,
            numberOfBrothers: data.brother ? data.brother : null,
            numberOfBrothersMarried: data.marriedbrother ? data.marriedbrother : null,
            numberOfSisters: data.sister ? data.sister : null,
            numberOfSistersMarried: data.marriedsister ? data.marriedsister : null
        }
        setLoading(true)
        let response = await Requests.Profile.UpdateFamilyDetails(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Family Information</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Family Location</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Family Location"
                                defaultValue={ props.data.familyLocation || ''}
                                {...register('familyLocation')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Family Type</p>

                            <SingleSelect
                                options={familyType.options}
                                data={props.data.familyType}
                                value={(data) => setFamilyType({ ...familyType, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Family Affluence	</p>

                            <SingleSelect
                                options={familyAffluence.options}
                                data={props.data.familyAffluence}
                                value={(data) => setFamilyAffluence({ ...familyAffluence, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Family Value</p>

                            <SingleSelect
                                options={familyValue.options}
                                data={props.data.familyValue}
                                value={(data) => setFamilyValue({ ...familyValue, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Growup in</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={ props.data.nativePlace || ''}
                                {...register('nativePlace')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Father's Name</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={ props.data.father.name || ''}
                                {...register('fatherName')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Father's Occupation</p>

                            <SingleSelect
                                options={fatherOccupation.options}
                                data={props.data.father.status}
                                value={(data) => setFatherOccupation({ ...fatherOccupation, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Mother's Name</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={ props.data.mother.name || ''}
                                {...register('motherName')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Mother's Occupation</p>

                            <SingleSelect
                                options={motherOccupation.options}
                                data={props.data.mother.status}
                                value={(data) => setMotherOccupation({ ...motherOccupation, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Your order as child</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                defaultValue={ props.data.yourOrder }
                                {...register('yourOrder')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>No of Brother</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                defaultValue={ props.data.numberOfBrothers }
                                {...register('brother')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Married Brother</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                defaultValue={ props.data.numberOfBrothersMarried }
                                {...register('marriedbrother')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>No of Sister</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                defaultValue={ props.data.numberOfSisters }
                                {...register('sister')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Married Sister</p>
                            <input
                                type="number"
                                className="form-control shadow-none"
                                defaultValue={ props.data.numberOfSistersMarried }
                                {...register('marriedsister')}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default FamilyModal;