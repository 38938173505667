import React, { useState, useContext, useEffect } from 'react'
import '../style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../../Form/SingleSelect'
import { optionContext } from '../../../../utils/context/optionContext'
import Loading from '../../../Loading/Index'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const PartnerEducationAndCareerModal = (props) => {

    const { handleOptionToData } = useContext(optionContext)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit } = useForm()
    const [optionsData, setOptionsData] = useState(false)

    const [qualification, setQualification] = useState({
        value: null,
        options: []
    })

    const [workingWith, setWorkingWith] = useState({
        value: null,
        options: []
    })

    const [professionArea, setProfessionArea] = useState({
        value: null,
        options: []
    })

    const [annualIncome, setAnnualIncome] = useState({
        value: null,
        options: []
    })

    useEffect(() => {
        setWorkingWith({ ...workingWith, options: handleOptionToData("Working with") })
        setProfessionArea({ ...professionArea, options: handleOptionToData("Profession area") })
        setAnnualIncome({ ...annualIncome, options: handleOptionToData("Income") })
        setQualification({ ...qualification, options: handleOptionToData("Qualification") })
        setOptionsData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        let obj = {
            qualification: qualification.value,
            workingWith: workingWith.value,
            professionArea: professionArea.value,
            annualIncome: annualIncome.value
        }
        setLoading(true)
        let response = await Requests.Profile.UpdatePartnersEducationAndCareer(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    if (!optionsData) return <Loading />


    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Education & Career</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Qualification</p>

                            <SingleSelect
                                options={qualification.options}
                                data={props.qualification}
                                value={(data) => setQualification({ ...qualification, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Working with</p>

                            <SingleSelect
                                options={workingWith.options}
                                data={props.workingWith}
                                value={(data) => setWorkingWith({ ...workingWith, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Profession area</p>

                            <SingleSelect
                                options={professionArea.options}
                                data={props.professionArea}
                                value={(data) => setProfessionArea({ ...professionArea, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Annual income in $USD</p>

                            <SingleSelect
                                options={annualIncome.options}
                                data={props.income}
                                value={(data) => setAnnualIncome({ ...annualIncome, value: data })}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PartnerEducationAndCareerModal;