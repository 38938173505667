import React, { useState, useContext } from 'react'
import './style.scss';
import Requests from "../../utils/Requests/Index"
import Icon from 'react-icons-kit'
import { Images } from '../../utils/Images'
import { NavLink, Link } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { thumbsUp, search, users, messageSquare, chevronUp, bell } from 'react-icons-kit/feather'
import { person } from 'react-icons-kit/oct'
import { envelopeO } from 'react-icons-kit/fa'
import { UserContext } from '../../utils/context/userContext';
import ChatDialog from '../ChatDialog/Index'
import Chatbar from '../Chatbar/Index'
import MessageContainer from '../MessageContainer/Index'
import { useSelector, useDispatch } from 'react-redux';
import { seenProfileViewed } from '../../utils/Redux/Slice/NotificationSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const Index = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const { user } = useContext(UserContext)
    const [dialog, setDialog] = useState({ view: false, state: null })
    const messageBoxState = useSelector((state) => state.messageBoxState.view)
    const connectedList = useSelector((state) => state.membersList.connected)
    const matchList = useSelector((state) => state.membersList.match)
    const previousList = useSelector((state) => state.membersList.previous)
    const arrivalMsgCount = useSelector((state) => state.arrivalMessage.count)
    const connectionNotificationCount = useSelector((state) => state.notification.count)
    const notifications = useSelector((state) => state.notification)
    const [view, setView] = useState(false)
    const dispatch = useDispatch()

    const doLogout = async () => {
        try {
            await Requests.Auth.Logout(header);
            localStorage.clear()
            window.location.reload()
        } catch (err) {
            if (err) console.log(err);
        }
    }
    const handleNotificationView = e => {
        e.preventDefault()
        setView(!view)
    }
    const removeViewPerson = async() =>{
        try {
            const res = await Requests.Auth.DeleteProfileViewNotification(header);
            if(res.status){
                toast.success(res.message)
                dispatch(seenProfileViewed())
            }
            else{
                toast.errpr(res.message)
            }
        } catch (err) {
            if (err) console.log(err);
        }
    }

    if (!user) return 'loading'
    return (
        <div className="appbar-container">
            <div className="custom-appbar shadow">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between">

                                <div>
                                    <Link to="/">
                                        <img src={Images.Logo} className="img-fluid" alt="Logo" />
                                    </Link>
                                </div>

                                {/* Page links */}
                                <div className="d-none d-lg-block">
                                    <ul>
                                        <li><NavLink activeClassName="isActive" to="/my-biyesadi">my biyesadi</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/matches">matches</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/search">search</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/connection">connections</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/timeline">timeline</NavLink></li>
                                    </ul>
                                </div>

                                {/* Actions button */}
                                <div className="d-block">
                                    <ul>
                                        <li>
                                            <div className='alert'>
                                                <Icon icon={bell} size={26} onClick={handleNotificationView} />
                                                {connectionNotificationCount > 0 ? <spna className='count'>{connectionNotificationCount}</spna> : null}
                                            </div>
                                        </li>
                                        <li>
                                            <DropdownButton
                                                title={<img src={user.gender === "male" ? Images.Avatar : Images.FemaleAvatar} className="img-fluid rounded-circle" alt="..." />}>
                                                <Dropdown.Item as={Link} to="/my-biyesadi/profile">Profile</Dropdown.Item>
                                                <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
                                            </DropdownButton>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="d-lg-none">
                                    <DropdownButton
                                        title={<img src={user.gender === "male" ? Images.Avatar : Images.FemaleAvatar} className="img-fluid rounded-circle" alt="..." />}>
                                        <Dropdown.Item as={Link} to="/my-biyesadi/profile">Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
                                    </DropdownButton>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="break"></div>

            <div className="d-lg-none menu-bar">
                {/* <NavLink activeClassName="isActive" to="/timeline"><span><Icon icon={messageSquare} size={25} /><p>timeline</p></span></NavLink> */}
                <NavLink activeClassName="isActive" to="/connection"><span><Icon icon={users} size={25} /><p>
                    connections {(notifications.incomingProposal + notifications.acceptedProposal) > 0 ? <span className='notificationCount'>{notifications.incomingProposal + notifications.acceptedProposal}</span> : null} </p></span></NavLink>
                <NavLink activeClassName="isActive" to="/matches"><span><Icon icon={thumbsUp} size={25} /><p>matches</p></span></NavLink>
                <NavLink activeClassName="isActive" to="/search"><span><Icon icon={search} size={25} /><p>search</p></span></NavLink>
                <NavLink activeClassName="isActive" to="/"><span><Icon icon={person} size={25} /><p>mybiyesadi</p></span></NavLink>
                <NavLink activeClassName="isActive" to="/messanger"><span><Icon icon={messageSquare} size={25} /><p>
                    chat {arrivalMsgCount > 0 ? <span className='notificationCount'>{arrivalMsgCount}</span> : null}</p></span></NavLink>
            </div>

            {/* chat systems starts here */}
            {
                !dialog.view ?
                    <div className='d-none d-lg-block chatAlert' onClick={() => setDialog({ ...dialog, view: true, state: 'matched' })}>
                        {arrivalMsgCount > 0 ?
                            <p>
                                <Icon data={arrivalMsgCount} icon={envelopeO} size={24} />
                                <span>{arrivalMsgCount}</span>
                            </p>
                            : null
                        }
                        <p>Chat Online <Icon icon={chevronUp} size={24} /></p>
                    </div>
                    :
                    <div className="d-none d-lg-block chat-bar">
                        <button onClick={() => setDialog({ ...dialog, view: true, state: 'previous' })}>Chat <span className={arrivalMsgCount > 0 ? 'arrivalMsgCount' : 'd-none'}>{arrivalMsgCount > 0 ? arrivalMsgCount : null}</span></button>
                        <button onClick={() => setDialog({ ...dialog, view: true, state: 'connected' })}>Connected</button>
                        <button onClick={() => setDialog({ ...dialog, view: true, state: 'matched' })}>All</button>
                    </div>
            }

            <Chatbar />

            {dialog.view ?
                <ChatDialog
                    handleDialog={() => setDialog({ ...dialog, view: false, state: null })}
                    data={dialog.state === 'connected' ? connectedList
                        : dialog.state === 'matched' ? matchList
                            : dialog.state === 'previous' ? previousList : null} />
                : null
            }
            {messageBoxState ?
                <div className='d-none d-lg-block message-container'><MessageContainer /> </div>
                : null
            }
            {
                view ?
                    <div className={connectionNotificationCount > 0 ? 'notification-section' : 'd-none'}>
                        {notifications.incomingProposal > 0 ?
                            <NavLink
                                exact
                                type="button"
                                className={ notifications.incomingProposal > 0 ? "notify" : 'd-none'}
                                to="/connection/pending"
                            >You got {notifications.incomingProposal} proposal request</NavLink> : null}

                        {notifications.acceptedProposal > 0 ?
                            <NavLink
                                exact
                                type="button"
                                className={ notifications.acceptedProposal > 0 ? "notify" : 'd-none'}
                                to="/connection/"
                            >{notifications.acceptedProposal} user accepted your proposal</NavLink>
                            : null}

                        {notifications.profileViewed > 0 ?
                            <div className='profileView'>
                                <div className='header'>
                                <p className='title'>{notifications.profileViewed} user viewed yout profile</p>
                                <div className='seenbtn' onClick={removeViewPerson}>clear</div>
                                </div>
                                {notifications.profileViewPerson.map((one, idx) =>
                                    <NavLink key={idx} className='info-container'
                                        type="button"
                                        to={`/single-profile/${one._id}`}
                                    >
                                        <img src={one.image} alt="noimg" className='image-container' />
                                        <p className='description'>{one.description}</p>
                                    </NavLink>
                                )}
                            </div>
                            : null
                        }
                    </div>
                    : null
            }
        </div>
    );
};

export default Index;