import React, { useState } from 'react';
import './style.scss';
import { format } from 'timeago.js'
import Icon from 'react-icons-kit'
import { trashO } from 'react-icons-kit/fa'
import Index from '../Form/ImageFullScreen'

const Message = ({ own, data, handleMessage }) => {
    const [show, setShow] = useState({ status: false, url: '' })
    let arr = Array.isArray(data.messageImage)
    const viewImage = data => {
        setShow({ ...show, status: true, url: `${process.env.REACT_APP_IMAGE_URL}/${data}` })
    }
    return (
        <div className={own ? "message own" : "message"}>
            <div className="messagetop">
                <div className='msg-body'>
                    {
                        arr ? data.messageImage.map((one, idx) => {
                            return (
                                <img
                                    key={idx}
                                    className={one ? 'img mt-1' : 'd-none'}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${one}`}
                                    alt="noimg"
                                    onClick={() => viewImage(one)}
                                />
                            )
                        })
                            :
                            <img
                                className={data.messageImage ? 'img' : 'd-none'}
                                src={`${process.env.REACT_APP_IMAGE_URL}/${data.messageImage}`}
                                alt="noimg"
                                onClick={() => viewImage(data.messageImage)}
                            />
                    }
                    <p className={data.text ? 'messagetext' : 'd-none'}>{data.text}</p>
                </div>
                <div className='icon'>
                    <Icon className='ml-3' icon={trashO} size={14} onClick={handleMessage} />
                </div>
            </div>
            <div className="messagebottom">{format(data.createdAt)}</div>
            {show.status ? <Index show={show.status} onHide={() => setShow({ status: false, url: '' })} image={show.status ? show.url : null} /> : null}
        </div>
    );
};

export default Message;