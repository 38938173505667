import React, { useState, useContext, useEffect } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import { UserContext } from '../../../utils/context/userContext'
import InputRange from 'react-input-range'
import { HeightCalculatetoNumber } from '../../../utils/helpers'
import Loading from '../../../components/Loading/Index'
import { useHistory } from 'react-router-dom'


const Index = () => {

    let history = useHistory();
    const { user } = useContext(UserContext)
    const data = user?.partnerPreference
    const [ages, setAges] = useState({
        min: null,
        max: null
    })
    const [heights, setheights] = useState({
        min: null,
        max: null
    })

    useEffect(() => {
        setAges({
            min: data?.ageRange.startFrom,
            max: data?.ageRange.endTo
        })
        setheights({
            min: HeightCalculatetoNumber(data?.heightRange.startFrom),
            max: HeightCalculatetoNumber(data?.heightRange.endTo)
        })
    }, [data])

    const changeUrl = () => {
        history.push('/my-biyesadi/profile?dv=parpre')
    }
    if (!user) return <Loading />

    return (
        <div className="preference-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />

                            <div className="flex-fill main-section px-lg-4 pt-4 pt-lg-0 pb-4">
                                <div className="d-flex mb-4">
                                    <div className="mb-0"><h4>Overviews</h4></div>
                                    <div className="ml-auto ">
                                        <button className="btn rounded-circle shadow"
                                            onClick={changeUrl}
                                        >
                                            <Icon icon={edit2} size={20} />
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* Age Section */}
                                    <div className="col-8 mb-4">
                                        <div className="form-group">
                                            <p className="mb-4">Age range</p>
                                            {
                                                ages.min ?
                                                    <InputRange
                                                        className="form-control shadow-none"
                                                        maxValue={50}
                                                        minValue={18}
                                                        value={ages}
                                                        disabled
                                                    />
                                                    :
                                                    "----"
                                            }

                                        </div>
                                    </div>
                                    {/* Height Section */}
                                    <div className="col-8 mb-4">
                                        <div className="form-group">
                                            <p className="mb-4">Height range</p>
                                            {
                                                heights.min && heights.min !== 'NaN.NaN' ?
                                                    <InputRange
                                                        className="form-control shadow-none"
                                                        maxValue={7}
                                                        minValue={4}
                                                        value={heights}
                                                        disabled
                                                    />
                                                    :
                                                    "----"
                                            }

                                        </div>
                                    </div>
                                    {/* Material status */}
                                    <div className="col-12 mb-4">
                                        <div className="form-group mb-4">
                                            <p>Material status</p>
                                            <p className="custom-input">
                                                {data?.materialStatus?.length ? data.materialStatus : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Religion / Community */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Religion / Community</p>
                                            <p className="custom-input">
                                                {data?.religion?.length ? data.religion[0] : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Mother tongue */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Mother tongue</p>
                                            <p className="custom-input">
                                                {data?.motherTongue ? data.motherTongue : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Location & grew up in details */}
                                    <div className="col-12 py-3">
                                        <h5 className="mb-0">Location & grew up in details</h5>
                                    </div>

                                    {/* Living country */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Country</p>
                                            <p className="custom-input">
                                                {data?.location?.country.length ? data.location.country : "----"}
                                            </p>

                                        </div>
                                    </div>

                                    {/* Living state */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Living state / Divition</p>
                                            <p className="custom-input">
                                                {data?.location?.stateDivision.length ? data.location.stateDivision : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Residency status */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>City</p>
                                            <p className="custom-input">
                                                {data?.location?.city.length ? data.location.city : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Education & profession details */}
                                    <div className="col-12 py-3">
                                        <h5 className="mb-0">Education & profession details</h5>
                                    </div>

                                    {/* Qualification */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Qualification</p>
                                            <p className="custom-input">
                                                {data?.educationAndProfession?.qualification.length ? data.educationAndProfession.qualification : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Working with */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Working with</p>
                                            <p className="custom-input">
                                                {data?.educationAndProfession?.workingWith.length ? data.educationAndProfession.workingWith : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Profession area */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Profession area</p>
                                            <p className="custom-input">
                                                {data?.educationAndProfession?.professionArea.length ? data.educationAndProfession.professionArea : "----"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Annual income */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Annual income</p>
                                            <p className="custom-input">
                                                {data?.annualIncome?.startFrom ? `${data.annualIncome.startFrom}-${data.annualIncome.endTo}` : "Doesn't Matter"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Index;

