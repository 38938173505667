import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'
import Requests from '../../utils/Requests/Index'
import Icon from 'react-icons-kit'
import { users, image, loader } from 'react-icons-kit/feather'
import { close } from 'react-icons-kit/fa'
import { ic_send } from 'react-icons-kit/md'
import { useSelector } from 'react-redux'
import Message from '../Messages/Index';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { io } from "socket.io-client";
import { Modal, Button } from 'react-bootstrap'

const Index = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const scrollRef = useRef();
    const history = useHistory();
    const memberInfo = useSelector((state) => state.singleChatMember.obj)
    const profileType = useSelector((state) => state.user.userData.profileType)
    const arrivalMessage = useSelector((state) => state.arrivalMessage.data)
    const userID = useSelector((state) => state.user.userData.userID)
    const [newMessage, setNewMessage] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [messages, setMessages] = useState(null)
    const socket = useRef()
    const [tempImage, setTempImage] = useState([]);
    const [previewLink, setPreviewLink] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [deleteMsg, setDeleteMsg] = useState(null);

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
    }, [])

    useEffect(() => {
        if (arrivalMessage.length > 0 && arrivalMessage.some(one => one.sender === memberInfo._id)) {
            let obj = arrivalMessage.find(one => {
                return one.sender === memberInfo._id
            })
            setMessages((prev) => [...prev, obj])
        }
    }, [arrivalMessage, memberInfo]);


    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleRedirect = e => {
        e.preventDefault()
        history.push('/my-biyesadi/package')
    }

    const sendMessage = async (e) => {
        e.preventDefault()

        // if (profileType === 'Free') {
        //     toast.warn('Upgrade now to message directly')
        // }
        // else {
        var formData = new FormData();
        formData.append("profileType", profileType)
        formData.append("receiverId", memberInfo._id)

        if ((tempImage.length > 0) && newMessage) {
            formData.append("text", newMessage)
            tempImage.forEach((item) => formData.append("messageImage", item))
        }
        else if ((tempImage.length === 0) && newMessage) {
            formData.append("text", newMessage)
        }
        else if ((tempImage.length > 0) && !newMessage) {
            tempImage.forEach((item) => formData.append("messageImage", item))
        }
        else {
            toast.warn('Message can not be empty')
        }
        setLoading(true)
        const response = await Requests.Message.MessageSend(formData, header)
        if (response && response.status) {
            setPreviewLink([])
            setTempImage([])
            setNewMessage("")
            const { messageImage, sender, text } = response.newMessage
            const receiverId = memberInfo._id
            if (messageImage.length > 0) {
                setTimeout(() => {
                    setMessages([...messages, response.newMessage])
                    setLoading(false)
                }, 2500)
                socket.current.emit("sendMessage", {
                    senderId: sender,
                    receiverId,
                    text,
                    messageImage: messageImage,
                })
            }
            else {
                setMessages([...messages, response.newMessage])
                setLoading(false)
                socket.current.emit("sendMessage", {
                    senderId: sender,
                    receiverId,
                    text,
                })
            }
        }
        else {
            setLoading(false)
        }
        // }
    }

    const imageSetter = (e) => {
        const MAX_FILE_SIZE = 6
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > MAX_FILE_SIZE) {
            toast.warn('File Size must be less than 6MB')
        }
        else {
            setTempImage([...tempImage, e.target.files[0]])
            var tmppath = URL.createObjectURL(e.target.files[0]);
            setPreviewLink([...previewLink, tmppath])

        }
    };

    const removePreview = (data) => {
        setTempImage(tempImage.filter(item => item !== data));
        setPreviewLink(previewLink.filter(item => item !== data))
    };

    useEffect(() => {
        const getMessages = async () => {
            try {
                const res = await Requests.Message.MessagesOfConversation(memberInfo._id, header)
                setMessages(res.data)
            } catch (err) {
                console.log(err);
            }
        }
        getMessages();
    }, [memberInfo, header]);

    const deleteMessage = data => {
        setDeleteMsg(data)
        setDeleteAlert(true)
    }

    const handleClose = () => setDeleteAlert(false)

    const handleDeleteMessage = async e => {
        e.preventDefault()
        try {
            setLoading(true)
            const res = await Requests.Message.MessagesDelete(deleteMsg, header)
            if (res.status) {
                setLoading(false)
                setDeleteAlert(false)
                setMessages(messages.filter(item => item._id !== res.data._id))
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="d-block d-lg-none mobile-message-container">
            <div className='mobile-header'>
                <p className="mobile-member-name"><Icon icon={users} size={16} /> {memberInfo.name ? memberInfo.name : "Namecoming"}</p>
                <img className='mobile-member-image' src={memberInfo.images[0].name} alt="no img" />
            </div>
            <div className={tempImage.length > 0 ? "temp mobile-body" : "mobile-body"}>
                {/* {
                    profileType === 'Free' ?
                        <div className={tempImage.length > 0 ? "temp mobile-msg-section" : "mobile-msg-section"}>
                            {
                                (messages && messages.length > 0) ?
                                    messages.map((one, index) => {
                                        return (
                                            <div key={index} ref={scrollRef}>
                                                <Message
                                                    own={one.sender === userID}
                                                    data={one}
                                                    handleMessage={() => deleteMessage(one)}
                                                />
                                            </div>
                                        )
                                    })
                                    : null
                            }
                            <div className='upgrade mt-3'>
                                <div>
                                    <p className='tag'>Upgrade Now</p>
                                    <p>to start Chatting</p>
                                </div>
                                <button className='btn' onClick={handleRedirect}>View Plans</button>
                            </div>
                        </div>
                        : */}
                <div className={tempImage.length > 0 ? "temp mobile-msg-section" : "mobile-msg-section"}>
                    {
                        (messages && messages.length > 0) ?
                            messages.map((one, index) => {
                                return (
                                    <div key={index} ref={scrollRef}>
                                        <Message
                                            own={one.sender === userID}
                                            data={one}
                                            handleMessage={() => deleteMessage(one)}
                                        />
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
                {/* } */}

                {previewLink.length > 0 ?
                    <div className='tempMobile-image-container'>
                        {previewLink.map((one, id) => {
                            return (
                                <div className="mobile-preview" key={id}>
                                    <img
                                        className="mobile-imagePreview"
                                        src={one}
                                        alt="noimg"
                                    />
                                    <span className="mobile-crossButton">
                                        <Icon
                                            icon={close} size={14}
                                            onClick={() => removePreview(one)}
                                        />
                                    </span>
                                </div>
                            )
                        })
                        }
                    </div>
                    : null
                }
                {
                    deleteAlert
                        ?
                        <Modal
                            show={deleteAlert}
                            onHide={handleClose}
                            dialogClassName='mobile-custom-settings-modal'
                            centered
                        >
                            <Modal.Body>
                                <div><h6>Do you want to delete this message ?</h6></div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    close
                                </Button>
                                <Button className='mobile-save-btn' onClick={handleDeleteMessage}>
                                    yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        : null
                }
                {
                    isLoading ?
                        <div className='mobile-loader'> <Icon size={30} icon={loader} /></div>
                        : null
                }
            </div>
            <div className='mobile-footer'>
                <div className='inputSection'>
                    <input
                        placeholder='type here...'
                        onChange={(e) => setNewMessage(e.target.value)}
                        value={newMessage}
                    />
                </div>
                <div className='btn-container'>
                    <label htmlFor="upload-button">
                        <span className='sendBtn'>
                            <Icon icon={image} size={16} disabled={isLoading} />
                        </span>
                    </label>

                    <input
                        type="file"
                        name="messageImage"
                        id="upload-button"
                        onChange={(e) => imageSetter(e)}
                        style={{ display: "none" }}
                    />
                    <button className='sendBtn' disabled={isLoading} onClick={sendMessage}><Icon icon={ic_send} size={16} /></button>
                </div>
            </div>
        </div>
    )
}

export default Index;