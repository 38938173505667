import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Connect with user
const Connect = async (connectId, header) => {
    try {
        const response = await Axios.post(`${api}connection/connect`, { connectId }, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) {
            return errorHandeller(error)
        }
    }
}

// Accepted connection
const Accepted = async (header) => {
    try {
        const response = await Axios.get(`${api}connection/accepted`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Send connection
const Send = async (header) => {
    try {
        const response = await Axios.get(`${api}connection/send`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Pending connection
const Pending = async (header) => {
    try {
        const response = await Axios.get(`${api}connection/pending`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update request type
const UpdateRequestType = async (user, type, header) => {
    try {
        const response = await Axios.get(`${api}connection/request/change/${user}/${type}`, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update user FavoriteList 
const UpdateFavoriteList = async (user, type, header) => {
    try {
        const response = await Axios.get(`${api}connection/favorite/change/${user}/${type}`, header)
        if (response.status === 201) {
            return response.data;
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Connection = {
    Connect,
    Accepted,
    Send,
    Pending,
    UpdateRequestType,
    UpdateFavoriteList
}

export default Connection