import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Index of myphotos
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}myphotos`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UploadImage = async ( image , header) =>{
    try {
        const response = await Axios.post(`${api}uploadimage`,image, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const DeleteImage = async ( data, header) =>{
    try {
        const response = await Axios.put(`${api}deleteimage`, data, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const ImageSettings = async ( data, header) =>{
    try {
        const response = await Axios.put(`${api}imagesettings`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const MyPhoto = {
    Index,
    UploadImage,
    DeleteImage,
    ImageSettings
}

export default MyPhoto