
import React, { useState, useContext } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Form } from 'react-bootstrap'
import { chevronDown, chevronUp } from 'react-icons-kit/feather'
import { toast } from 'react-toastify'
import Loading from '../../Loading/Index'
import 'react-toastify/dist/ReactToastify.css'
import { optionContext } from '../../../utils/context/optionContext'
toast.configure({ autoClose: 2000 })

const Hobbies = (props) => {
    const [show, setShow] = useState(true)
    const { options } = useContext(optionContext)
    const hobbiesIndex = options?.settingFiled.map(function (e) { return e.label }).indexOf("Hobbies")

    if (!options) return <Loading />
    if (!props.hobbies) return <Loading />

    return (
        <div className="hobby-interest">
            <div className="container">
                <div className="card shadow mb-4">
                    {
                        show ?
                            <>
                                <div className="card-header"
                                    onClick={() => setShow(!show)}
                                >
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center"><h5 className="mb-0">Hobbies</h5></div>
                                        <div className="ml-auto">
                                            <button className="btn shadow"
                                            >
                                                <Icon icon={chevronUp} size={14} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Form>
                                        {options.settingFiled[hobbiesIndex].value.map((item, i) =>
                                            <div key={i} className="mb-3 singleHobby d-inline-flex">
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    label={item}
                                                    id={item}
                                                    value={item}
                                                    checked={props.hobbies.includes(item)}
                                                    onChange={(e) => props.pushToHobbies(item, e.target.checked)}
                                                />
                                            </div>
                                        )}
                                    </Form>
                                </div>
                            </>
                            :
                            <div className="card-header"
                                onClick={() => setShow(!show)}
                            >
                                <div className="d-flex">
                                    <div className="d-flex align-items-center"><h5 className="mb-0">Hobbies</h5></div>
                                    <div className="ml-auto">
                                        <button className="btn shadow"
                                        >
                                            <Icon icon={chevronDown} size={14} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>

    );
};

export default Hobbies;