import React, { useState,useContext } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import EducationAndCareerModal from './Modal/EducationAndCareer'
import { UserContext } from '../../utils/context/userContext';

const EducationCareer = (props) => {
    const [model, setModel] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const {qualification, institute, workingWith, professionArea} = user.educationAndProfession
    const { startFrom, endTo }= user.annualIncome
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Education & Career</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setModel({ ...model, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Highest Qualification</th>
                                        <td className="pl-5">:</td>
                                        <td>{qualification ? qualification : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>College(s) Attended</th>
                                        <td className="pl-5">:</td>
                                        <td>{institute ? institute : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Annual Income</th>
                                        <td className="pl-5">:</td>
                                        <td>{ startFrom ? startFrom : "N/A"} - { endTo ? endTo: "N/A" }</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Working with</th>
                                        <td className="pl-5">:</td>
                                        <td>{ workingWith ? workingWith :"N/A" }</td>
                                    </tr>
                                    <tr>
                                        <th>Profession Area</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{professionArea ? professionArea : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {model.show ?
                    <EducationAndCareerModal
                        show={model.show}
                        data={user.educationAndProfession}
                        income={`${user.annualIncome.startFrom}-${user.annualIncome.endTo}`}
                        loading={model.loading}
                        handleRefetch={handleRefetch}
                        onHide={() => setModel({ ...model, show: false, loading: false })}
                    />
                : null}
        </div>
    )
};

export default EducationCareer;