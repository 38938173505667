import React, { useState, useContext } from 'react'
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import { UserContext } from '../../../utils/context/userContext'
import Loading from '../../Loading/Index'
import Requests from '../../../utils/Requests/Index'
import ForgotOption from './Modals/Option'
import Otp from './Modals/Otp'
import Number from './Modals/Number'
import Email from './Modals/Email'
import NewPassword from './Modals/Newpassword'
toast.configure({ autoClose: 2000 })

const Account = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const { user } = useContext(UserContext)
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm()
    const [show, setShow] = useState(null)
    const [isLoading, setLoading] = useState(false)


    // Submit Form
    const onSubmit = async (data) => {
        setLoading(true)
        if (data.newPassword !== data.confirmNewPassword) {
            setError("confirmNewPassword", {
                type: "manual",
                message: "confirm password didn't match",
            });
            setLoading(false)
        }
        else {
            let obj = {
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            }
            const response = await Requests.Settings.UpdatePassword(obj, header)
            if (response.status) {
                setLoading(false)
                setShow(!show)
                reset("currentPassword")
                reset("newPassword")
                reset("confirmNewPassword")
                toast.success('Successfully updated your password')
            }
            else {
                setLoading(false)
                setError("currentPassword", {
                    type: "manual",
                    message: "current password is not correct",
                });
            }
        }

    }

    if (!user) return <Loading />
    return (
        <div className="card border-0">
            <div className="card-body px-0">


                {/* email */}
                <div className="content shadow mb-4">
                    <h6>E-mail</h6>
                    <p className="title">E-mail</p>
                    <p className="colon">:</p>
                    <p className="values">{user.email} </p>
                </div>

                <div className="content shadow mb-4">
                    <div className="d-flex">
                        <h6>Password</h6>
                        <button className="ml-auto"
                            onClick={() => setShow('passwordField')}
                        >
                            edit
                        </button>
                    </div>
                    <p className="title">Password</p>
                    <p className="colon">:</p>
                    <p className="values">**********</p>
                </div>

                {
                    show && show === "passwordField" ?

                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* password */}
                            <div className="form-group mb-4">
                                {errors.currentPassword && errors.currentPassword.message ? (
                                    <p className="text-danger">{errors.currentPassword && errors.currentPassword.message}</p>
                                ) : <p>Current Password</p>}

                                <input
                                    type="password"
                                    placeholder="current password"
                                    className="form-control shadow-none"
                                    {...register('currentPassword', {
                                        required: "Please enter your current password",
                                        minLength: {
                                            value: 8,
                                            message: "Minimun length 8 character"
                                        }
                                    })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                {errors.newPassword && errors.newPassword.message ? (
                                    <p className="text-danger">{errors.newPassword && errors.newPassword.message}</p>
                                ) : <p>New password</p>}

                                <input
                                    type="password"
                                    placeholder="new password"
                                    className="form-control shadow-none"
                                    {...register('newPassword', {
                                        required: "Please enter new password",
                                        minLength: {
                                            value: 8,
                                            message: "Minimun length 8 character"
                                        }
                                    })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                {errors.confirmNewPassword && errors.confirmNewPassword.message ? (
                                    <p className="text-danger">{errors.confirmNewPassword && errors.confirmNewPassword.message}</p>
                                ) : <p>Confirm new password</p>}

                                <input
                                    type="password"
                                    placeholder="confirm new password"
                                    className="form-control shadow-none"
                                    {...register('confirmNewPassword', {
                                        required: "Please confirm new password",
                                        minLength: {
                                            value: 8,
                                            message: "Minimun length 8 character"
                                        }
                                    })}
                                />
                            </div>

                            <div className="text-right d-flex">
                                <p>Don't remember password?
                                    <button
                                        onClick={() =>setShow("option")}
                                        type="submit"
                                        disabled={isLoading}>
                                        forget now
                                    </button>
                                </p>

                                <button
                                    type="submit"
                                    className="btn shadow-none ml-auto"
                                    disabled={isLoading}>
                                    {isLoading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                        :
                        null
                }
                {
                    show && show === 'option' ?
                        <ForgotOption setShow={setShow}/>
                        : null
                }
                {
                    show && show === 'phone' ?
                        <Number phone={user.phone} setShow={setShow}/>
                        : null
                }
                {
                    show && show === 'email' ?
                        <Email email={user.email} setShow={setShow}/>
                        : null
                }
                {
                    show && show === 'otp' ?
                        <Otp setShow={setShow} />
                        : null
                }
                {
                    show && show === 'newpassword' ?
                        <NewPassword  setShow={setShow}/>
                        : null
                }
            </div>
        </div>
    );
};

export default Account;