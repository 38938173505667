import React, { createContext, useState } from 'react';


export const RefineContext = createContext({});

const RefineSearchContext = ({ children }) => {

    const [refineSearchObj, setRefineSearchObj] = useState({
        "age": [],
        // "bloodGroup": [],
        // "diet": [],
        // "disability": [],
        // "healthInfo": [],
        "height": [],
        "materialStatus": [],
        "professionArea": [],
        "qualification": [],
        "workingWith": [],
        "income": [],

        // next
        "language": [],
        "livingCountry": [],
        "recentlyJoined": [],
        "religion": [],
        "title": [],

    });

    // const forCheckInCheckbox = ["age", "bloodGroup", "diet", "disability", "healthInfo", "height", "materialStatus", "professionArea", "qualification", "workingWith", "income"]

    // Refine Search function
    const handleRefineSearch = (event) => {
        let { name, value } = event.target;
        // if (forCheckInCheckbox.indexOf(name) > -1) {
        let copy = { ...refineSearchObj };
        if (copy[name].indexOf(value) > -1) {
            copy[name].splice(copy[name].indexOf(value), 1);
            setRefineSearchObj({ ...copy })
        } else {
            copy[name].push(value);
            setRefineSearchObj({ ...copy });
        }
        // } else {
        //     if (refineSearchObj[name] === value) {
        //         setRefineSearchObj({ ...refineSearchObj, [name]: "" });
        //     } else {
        //         setRefineSearchObj({ ...refineSearchObj, [name]: value });
        //     }
        // }
    }

    return (

        <RefineContext.Provider value={{ handleRefineSearch, refineSearchObj, setRefineSearchObj }} >
            {children}
        </RefineContext.Provider>
    );
};

export default RefineSearchContext;