import { createSlice } from '@reduxjs/toolkit'

export const OptionSlice = createSlice({
    name:"options",
    initialState :{},
    reducers :{
        optionSetter : (state, action) =>{
            state.OptionObject = action.payload
        },
    },
});
 
export const { optionSetter } = OptionSlice.actions

export default OptionSlice.reducer;