import React, { useState } from 'react'
import '../style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../../Form/SingleSelect'
import { Diet } from '../../../OptionsData/Diet/Diet'
import { BloodGroup } from '../../../OptionsData/BloodGroup/BloodGroup'
import { HealthInfo } from '../../../OptionsData/HealthInfo/HealthInfo'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const OtherModal = (props) => {
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [bloodGroup, setBloodGroup] = useState({
        value: null,
        options: BloodGroup
    })

    const [healthInformation, setHealthInformation] = useState({
        value: null,
        options: HealthInfo
    })

    const [diet, setDiet] = useState({
        value: null,
        options: Diet
    })

    const onSubmit = async () => {
        let obj = {
            diet: diet.value,
            healthInformation:healthInformation.value,
            bloodGroup: bloodGroup.value
        }
        setLoading(true)
        let response = await Requests.Profile.UpdatePartnersOther(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Others Infomations</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Blood Group</p>
                            <SingleSelect
                                options={bloodGroup.options}
                                data={props.bloodGroup}
                                value={(data) => setBloodGroup({ ...bloodGroup, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Health Information</p>
                            <SingleSelect
                                options={healthInformation.options}
                                data={props.healthInformation}
                                value={(data) => setHealthInformation({ ...healthInformation, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Diet</p>
                            <SingleSelect
                                options={diet.options}
                                data={props.diet}
                                value={(data) => setDiet({ ...diet, value: data })}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default OtherModal;