import { createSlice } from '@reduxjs/toolkit'

export const RefatchSlice = createSlice({
    name:"refatch",
    initialState: { sideEffect: false },
    reducers : {
        doRefatch : () =>{
            return {
                sideEffect : true
            }
        },
        stopRefatch : () =>{
            return {
                sideEffect: false
            }
        }
    }
})
export const {doRefatch, stopRefatch} = RefatchSlice.actions
export default RefatchSlice.reducer