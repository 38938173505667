
import React, { useState, useContext, useEffect, useCallback } from 'react'
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import Hobbies from '../../../../components/MyProfile/PersonalInterest/Hobbies'
import QuickItems from '../../../../components/MyBiye/QuickItems/Index'
import Interest from '../../../../components/MyProfile/PersonalInterest/Interest'
import Sports from '../../../../components/MyProfile/PersonalInterest/Sports'
import Requests from '../../../../utils/Requests/Index'
import {UserContext} from '../../../../utils/context/userContext'
import Loading from '../../../../components/Loading/Index'
toast.configure({ autoClose: 2000 })

const Index = () => {

    let history = useHistory();
    const {user,handleRefetch} = useContext(UserContext)
    const [isLoading, setLoading] = useState(false)
    const [hobbies, setHobbies] = useState(null)
    const [interests, setInterest] = useState(null)
    const [sports, setSports] = useState(null)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })


    const pushToHobbies = (data, isON) => {
        if (isON && !hobbies.includes(data)) setHobbies((prev) => [...prev, data])
        else if (!isON && hobbies.includes(data)) {
            setHobbies(hobbies.filter(one => one !== data))
        }
    }

    const pushToInterest = (data, isON) => {
        if (isON && !interests.includes(data)) setInterest((prev) => [...prev, data])
        else if (!isON && interests.includes(data)) {
            setInterest(interests.filter(one => one !== data))
        }
    }

    const pushToSports = (data, isON) => {
        if (isON && !sports.includes(data)) setSports((prev) => [...prev, data])
        else if (!isON && sports.includes(data)) {
            setSports(sports.filter(one => one !== data))
        }
    }
    const handleUserpersonalActivities =useCallback(() =>{
        if(user){
            setHobbies(user.personalActivities.hobbies)
            setInterest(user.personalActivities.interests)
            setSports(user.personalActivities.sports)

        }
    },[user])

    useEffect(()=>{
        handleUserpersonalActivities()
    },[handleUserpersonalActivities])

    const onSubmit = async () => {
        let data = {
            hobbies: hobbies,
            interests: interests,
            sports: sports
        }
        setLoading(true)
        const response = await Requests.Profile.UpdatePersonalActivities(data, header)
        if (response.status) {
            toast.success('Successfully saved your Personal Activities')
            handleRefetch()
            history.push("/my-biyesadi/profile")
        }
        else{
            history.push("/my-biyesadi/profile")
        }
    }
    if(!user && !hobbies) return <Loading />
    return (
        <div className="personal-interest">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />
                            <div className="flex-fill main-section px-lg-4 pb-4 pt-4 pt-lg-0">
                                <Hobbies pushToHobbies={pushToHobbies} hobbies={hobbies} />
                                <Interest pushToInterest={pushToInterest} interests={interests}/>
                                <Sports pushToSports={pushToSports} sports={sports}/>
                                <div className="text-center pt-3">
                                    <button
                                        type="submit"
                                        className="btn shadow-none save-btn"
                                        disabled={isLoading}
                                        onClick={onSubmit}
                                    >
                                        {isLoading ? 'Saving...' : 'Save my Infos'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;