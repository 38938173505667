import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/feather'
import { Images } from '../../../utils/Images'
// import PurchaseModal from './Purchase'
import Request from '../../../utils/Requests/Index'
import { toast } from 'react-toastify'

const Index = (props) => {

    const [loading, setLoading] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const { image, title, currency, finalCurrency, currencyType, type, durationType, duration, proposal, discountPercentage } = props.data

    const surjoRequest = async (data) => {
        setLoading(true)
        const res = await Request.Package.SurjoRequest(data, header)
        setLoading(false)
        if (res.link) {
            window.location.replace(`${res.link}`)
        }
        else {
            toast.error(res.message, {
                autoClose: 4000
            })
        }
    }

    return (
        <div className="card package-card border-0">
            <div className="card-body shadow-sm">
                <div className="image-container rounded-circle">
                    <img src={image ? image : Images.PackageSticker} className="img-fluid" alt="..." />
                </div>
                <br />

                <h5>{title}</h5>  {/* Package title */}
                <div className="pricing mb-3">
                    <h6>{type}</h6> {/* Package type */}
                    <h3 className={discountPercentage === 0 ? "hidden" : "p-1"}>{discountPercentage}% off</h3> {/* Discaount percentage*/}
                    <h5 className={discountPercentage === 0 ? "p-1" : "p-1 cross text-dark"}>{currency} {currencyType}</h5> {/* Package price */}
                    <h5 className={discountPercentage === 0 ? "hidden" : "p-1"}>{finalCurrency} {currencyType}</h5> {/* Package price */}
                    {
                        durationType === 'Day' ? <p>For {duration} day</p>
                        : <p>For {duration} month</p>
                    }
                </div>

                <ul>
                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Total proposal limit: {proposal.totalLimit} person</p></li>
                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Contact view limit: {proposal.contactViewLimit}</p></li>
                    {/* <li><p><Icon icon={check} size={20} className="text-success mr-2" />Message limit:{proposal.messageLimit}</p></li> */}
                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Unlimited Messaging</p></li>
                </ul>

                <div className="watermark" />
                <div className="text-right">
                    <button
                        type="button"
                        className="btn shadow-sm rounded"
                        // onClick={() => setShow(true)}
                        onClick={() => surjoRequest(props.data)}
                    >{loading ? "Requesting..." : "Purchase Now"}</button>
                </div>
            </div>

            {/* {show ? <PurchaseModal show={show} onHide={() => setShow(false)} data={data} /> : null} */}
        </div>
    );
};

export default Index;