import React, { useState } from 'react'
import './style.scss'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import AccountSetting from '../../../components/MyBiye/Settings/Account'
// import ContactSetting from '../../../components/MyBiye/Settings/Contact'
// import EmailSmsSetting from '../../../components/MyBiye/Settings/EmailSms'
// import PrivacySetting from '../../../components/MyBiye/Settings/Privacy'
// import ProfileSetting from '../../../components/MyBiye/Settings/Profile'

const Index = () => {
    const [key, setKey] = useState('account')

    return (
        <div className="settings-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />
                            <div className="flex-fill main-section px-lg-4 pb-4 pt-4 pt-lg-0">

                                <div className="custom-tabs">
                                    <div className="btn-container">
                                        <button
                                            type="button"
                                            className={key === 'account' ? "btn active" : "btn"}
                                            onClick={() => setKey('account')}
                                        >Account settings</button>
                                        {/* <button
                                            type="button"
                                            className={key === 'contact' ? "btn active" : "btn"}
                                            onClick={() => setKey('contact')}
                                        >Contact filters</button>
                                        <button
                                            type="button"
                                            className={key === 'emailsms' ? "btn active" : "btn"}
                                            onClick={() => setKey('emailsms')}
                                        >Email/SMS alert</button>
                                        <button
                                            type="button"
                                            className={key === 'privacy' ? "btn active" : "btn"}
                                            onClick={() => setKey('privacy')}
                                        >Privacy option</button>
                                        <button
                                            type="button"
                                            className={key === 'profile' ? "btn active" : "btn"}
                                            onClick={() => setKey('profile')}
                                        >Hide/Delete profile</button> */}
                                    </div>
                                </div>

                                {/* Tab content */}
                                <div className="custom-tab-content">
                                    {key === 'account' && <AccountSetting />}
                                    {/* {key === 'contact' && <ContactSetting />}
                                    {key === 'emailsms' && <EmailSmsSetting />}
                                    {key === 'privacy' && <PrivacySetting />}
                                    {key === 'profile' && <ProfileSetting />} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Index;