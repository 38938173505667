import React, { useState, useContext, useEffect, useCallback } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import ContactDetailsModal from './Modal/ContactDetails'
import PhoneNumberModal from './Modal/PhoneNumberModal'
import OtpModal from './Modal/OtpModel';
import { UserContext } from '../../utils/context/userContext';

const ContactDetails = (props) => {
    const [modal, setModal] = useState({ show: false, loading: false })
    const [phoneNumberModel, setPhoneNumberModel] = useState({ show: false, loading: false })
    const [otpModal, setOtpModal] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const [contactPersone, setContactPerson] = useState(null)
    const [relationship, setRelationship] = useState(null)
    const [timeToCall, setTimeToCall] = useState(null)
    const [displayOption, setDisplayOption] = useState(null)
    const [guardianMobile, setGuardianMobile] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.contactInformation) {
            setContactPerson(user.contactInformation.contactPersonName)
            setRelationship(user.contactInformation.relationship)
            setTimeToCall(user.contactInformation.convenientTimeToCall)
            setDisplayOption(user.contactInformation.settings)
            setGuardianMobile(user.contactInformation.guardianMobile)
        }
    }, [user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    const handleOtpModel = () => {
        setOtpModal({ ...otpModal, show: true })
    }
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Contact Details</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setModal({ ...modal, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Mobile No</th>
                                        <td className="pl-5">:</td>
                                        <div className="d-flex justify-content-between">
                                            <td>{user.phone ? user.phone : "-"}</td>
                                            <button className="ml-2 border-0 rounded-circle shadow"
                                                onClick={() => setPhoneNumberModel({ ...modal, show: true })}
                                            >
                                                <Icon icon={edit2} size={15} />
                                            </button>
                                        </div>
                                    </tr>
                                    <tr>
                                        <th>Contact Person</th>
                                        <td className="pl-5">:</td>
                                        <td>{contactPersone ? contactPersone : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Relationship with this person</th>
                                        <td className="pl-5">:</td>
                                        <td>{relationship ? relationship : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Convineant time to call</th>
                                        <td className="pl-5">:</td>
                                        <td>{timeToCall ? timeToCall : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Display option</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{displayOption ? displayOption : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Guardian Mobile No</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{guardianMobile ? guardianMobile : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                modal.show ?
                    <ContactDetailsModal
                        contactPersone={contactPersone}
                        guardianMobile={guardianMobile}
                        relationship={relationship}
                        timeToCall={timeToCall}
                        displayOption={displayOption}
                        show={modal.show}
                        loading={modal.loading}
                        handleRefetch={handleRefetch}
                        onHide={() => setModal({ ...modal, show: false, loading: false })}
                    />
                    : null
            }
            {
                phoneNumberModel.show ?
                    <PhoneNumberModal
                        data={user.phone}
                        show={phoneNumberModel.show}
                        handleOtpModel={handleOtpModel}
                        loading={phoneNumberModel.loading}
                        onHide={() => setPhoneNumberModel({ ...phoneNumberModel, show: false, loading: false })}
                    />
                    : null
            }
            {
                otpModal.show ?
                    <OtpModal
                        data={props.value}
                        show={otpModal.show}
                        loading={otpModal.loading}
                        handleRefetch={handleRefetch}
                        onHide={() => setOtpModal({ ...otpModal, show: false, loading: false })}
                    />
                    : null
            }
        </div>
    )
};

export default ContactDetails;