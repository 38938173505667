import React from 'react'
import './style.scss'
import Modal from 'react-bootstrap/Modal'
import Icon from 'react-icons-kit'
import { x } from 'react-icons-kit/feather'

const Index = (props) => { 
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                className="picture-crop-modal"
            >
                <Modal.Header className="border-0">
                    <div className="w-100 text-right">
                        <button type="button" className="btn shadow-none" onClick={props.onHide}>
                            <Icon icon={x} size={22} />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="text-center p-0">
                    <img src={props.image} className="img-fluid w-100 h-100" alt="..." />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Index;