import React, { useCallback, useContext, useEffect, useState } from 'react';
import Icon from 'react-icons-kit'
import { useHistory } from 'react-router-dom'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import { UserContext } from '../../utils/context/userContext';

const PersonalInterest = () => {

    let history = useHistory();
    const { user } = useContext(UserContext)
    const [hobbies, setHobbies] = useState(null)
    const [interests, setInterests] = useState(null)
    const [sports, setSports] = useState(null)

    const changeUrl = () => {
        history.push("/my-biyesadi/profile/personal-interest");
    }

    const valuesSetter =useCallback (() => {
        if (user.personalActivities) {
            setHobbies(user.personalActivities.hobbies)
            setInterests(user.personalActivities.interests)
            setSports(user.personalActivities.sports)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Personal Interest</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={changeUrl}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Hobbies</th>
                                        <td className="pl-5">:</td>
                                        <td>{ hobbies && hobbies.length ? hobbies.map((one, idx) => (
                                                <p className="d-inline-block pl-2" id={idx}> {one}, </p>)): "N/A"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Interests</th>
                                        <td className="pl-5">:</td>
                                        <td>{interests && interests.length ? interests.map((one, idx) => (
                                                <p className="d-inline-block pl-2" id={idx}> {one}, </p>)): "N/A"
                                        }</td>
                                    </tr>
                                    <tr>
                                        <th>Sport / fitness</th>
                                        <td className="pl-5">:</td>
                                        <td >{ sports && sports.length ? sports.map((one, idx) => (
                                                <p className="d-inline-block pl-2" id={idx}> {one}, </p>)): "N/A"
                                        }</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PersonalInterest;