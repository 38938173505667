import { createSlice } from '@reduxjs/toolkit'

export const SingleChatMemberSlice = createSlice({
    name: "singleChatMember",
    initialState: { obj:{}},
    reducers: {
        mobileMemberSetter: (state, action) => {
                return {
                    ...state,
                    obj: action.payload
                }
        }
    },
});

export const { mobileMemberSetter } = SingleChatMemberSlice.actions

export default SingleChatMemberSlice.reducer;