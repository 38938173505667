import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { search } from 'react-icons-kit/feather'
import { Accordion, Card } from 'react-bootstrap'


const Index = () => {
    return (
        <div className="quick-items-container">

            {/* Show in mobile */}
            {/* <div className="d-lg-none">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <h5 className="mb-0">Quick Links</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul>
                                    <li><Link to="/">short list</Link></li>
                                    <li><Link to="/">new matches</Link></li>
                                    <li><Link to="/">my matches</Link></li>
                                    <li><Link to="/">near me</Link></li>
                                    <li><Link to="/">my help</Link></li>
                                    <li><Link to="/">my saved searches</Link></li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            <h5 className="mb-0">Profile Search</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <form>
                                    <div className="form-group mb-0">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control shadow-none"
                                        />
                                        <Icon icon={search} size={20} />
                                    </div>
                                </form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            <h5 className="mb-0">Usefull Links</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <ul>
                                    <li><Link to="/">refer a friend</Link></li>
                                    <li><Link to="/">need help ?</Link></li>
                                    <li><Link to="/">security tips</Link></li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div> */}

            {/* Show in Desktop  */}
            <div className="d-none d-lg-block">
                <div className="card border-0 mb-4">
                    <div className="card-body p-4">
                        <h5 className="mb-0">Quick Links</h5>
                        <ul>
                            <li><Link to="/">short list</Link></li>
                            <li><Link to="/">new matches</Link></li>
                            <li><Link to="/">my matches</Link></li>
                            <li><Link to="/">near me</Link></li>
                            <li><Link to="/">my help</Link></li>
                            <li><Link to="/">my saved searches</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="card border-0 mb-4">
                    <div className="card-body p-4">
                        <h5 className="mb-4">Profile Search</h5>
                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="form-control shadow-none"
                                />
                                <Icon icon={search} size={20} />
                            </div>
                        </form>
                    </div>
                </div>

                <div className="card border-0 mb-4">
                    <div className="card-body p-4">
                        <h5 className="mb-0">Usefull Links</h5>
                        <ul>
                            <li><Link to="/">refer a friend</Link></li>
                            <li><Link to="/">need help ?</Link></li>
                            <li><Link to="/">security tips</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;