import React, { useCallback, useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import "../style.scss";
import Loading from '../../../components/Loading/Index'
import Requests from '../../../utils/Requests/Index'
import ProfileDetails from '../../../components/SingleProfile/ProfileDetails/Index'
import PartnerPreference from '../../../components/SingleProfile/PartnerPreference/Index';
import Profile from "../../../components/Profile/Index"
import { singleProContext } from '../../../utils/context/SingleProfileContext';
import { io } from "socket.io-client";
import Icon from 'react-icons-kit'
import { circleRight, circleLeft } from 'react-icons-kit/iconic';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector} from 'react-redux'
toast.configure({ autoClose: 2000 });

const Index = () => {
    const history = useHistory()
    const { id } = useParams();
    const { ids } = useContext(singleProContext);
    const [key, setKey] = useState("details");
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const partnerPre = useRef(null)
    const socket = useRef()
    // const userID = useSelector((state) => state.user.userData.userID)
    const userInfo = useSelector((state) => state.user.userData)

    

    // Fetch data
    const fetchData = useCallback(async () => {
        setLoading(true)
        const response = await Requests.MyProfile.Single_Profile(id, header)
        if (response && response.user) setData(response.user)
        setLoading(false)
    }, [header, id]);

    useEffect(() => {
        fetchData()
    }, [header, fetchData, id]);

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
      }, [])

      useEffect(() => {
        if(!isLoading){
            socket.current.emit("profileViewed", {
                id: userInfo.userID,
                name: userInfo.name,
                image: userInfo.profilePicture,
                to: id
            })
        }
      }, [id, isLoading, userInfo])

    // Profile prev & next Function..
    let currentIndex = ids?.indexOf(id) || 0;
    const prevProfile = () => history.push(`/single-profile/${ids[currentIndex - 1]}`);
    const nextProfile = () => history.push(`/single-profile/${ids[currentIndex + 1]}`);

    if (isLoading) return <Loading />

    return (
        <>
            <div className="matches-results-container single_profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-11 col-xxl-9 m-auto px-2">

                            {/* Result container */}
                            {/* <div className="result-container"> */}
                            <div className="card border-0 mb-3 info-card">
                                <div className="this_body d-flex justify-content-between">
                                    <div className=" d-flex justify-content-center align-content-center flex-column">
                                        <h6 className="">Here are today's top matches for you. Connect with them now! </h6>
                                    </div>

                                    <div className="prev_next">
                                        <button
                                            disabled={currentIndex ? false : true}
                                            onClick={() => prevProfile()}
                                            className="btn"
                                        >
                                            <Icon icon={circleLeft} size={20} /> prev
                                        </button>
                                        <button
                                            disabled={currentIndex >= (ids?.length - 1 || 0) ? true : false}
                                            onClick={() => nextProfile()}
                                            className="btn"
                                        >
                                            Next  <Icon icon={circleRight} size={20} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Profile item={data} />
                            {/* </div> */}

                            <div className="d-flex">
                                <div className="adds_area d-none d-xl-block">
                                    <img className="img-fluid" src={data.addsense} alt="addsense" />
                                </div>
                                {/* Details */}
                                <section className="details ">
                                    <button
                                        type="button"
                                        className={key === 'details' ? "btn active" : "btn active"}
                                        onClick={() => setKey('details')}

                                    >Details Profile</button>
                                    <button
                                        type="button"
                                        className={key === 'partner' ? "btn active" : "btn active"}
                                        onClick={() => {
                                            setKey('partner');
                                            partnerPre.current.scrollIntoView({ behavior: "smooth" })
                                        }}
                                    >
                                        Partner Preference
                                    </button>

                                    <div className="boder"></div>

                                    <section className="details_area">
                                        <ProfileDetails data={data} />

                                        <div ref={partnerPre} ></div>
                                        <PartnerPreference data={data} />
                                    </section>


                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;