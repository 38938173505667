import React, { useState, useContext, useEffect } from 'react'
import '../style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../../Form/SingleSelect'
import { MaterialStatus } from '../../../OptionsData/MaterialStatus/MaterialStatus'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { Height } from '../../../OptionsData/HeightData/Heightdata'
import { optionContext } from '../../../../utils/context/optionContext'
import Requests from '../../../../utils/Requests/Index'
import Loading from '../../../Loading/Index'

toast.configure({ autoClose: 2000 })

const BasicLifeStyleModal = (props) => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit } = useForm()
    const { handleOptionToData } = useContext(optionContext)
    const [optionsData, setOptionsData] = useState(false)


    const [religion, setReligion] = useState({
        value: null,
        options: []
    })

    const [motherTongue, setMotherTongue] = useState({
        value: null,
        options: []
    })

    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        options: MaterialStatus
    })

    const [ages, setAges] = useState({
        min: 25,
        max: 35
    })

    const [height1, setHeight1] = useState({
        value: null,
        options: Height
    })

    const [height2, setHeight2] = useState({
        value: null,
        options: Height
    })

    useEffect(() => {
        setMotherTongue({ ...motherTongue, options: handleOptionToData("Language") })
        setReligion({ ...religion, options: handleOptionToData("Religion") })
        setOptionsData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        const obj = {
            materialStatus: materialStatus.value,
            age1: ages ? ages.max : null,
            age2: ages ? ages.min : null,
            height1: height1 ? height1.value : null,
            height2: height2 ? height2.value : null,
            motherTongue: motherTongue.value,
            religion: religion.value
        }
        setLoading(true)
        let response = await Requests.Profile.UpdatePartnersBasicInfos(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    if (!optionsData) return <Loading />
    
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Basic Life Style of partner</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Age</p>
                            <InputRange
                                className="form-control shadow-none"
                                maxValue={45}
                                minValue={18}
                                value={ages}
                                onChange={value => setAges({ max: value.max, min: value.min })}
                            />
                        </div>
                        <div className="d-flex">
                            <p>Height</p>
                            <div className="flex-fill">
                                <div className="form-group mx-4">
                                    <p>From</p>
                                    <SingleSelect
                                        options={Height}
                                        data={props.heightStartFrom?.toString()}
                                        value={(data) => setHeight1({ ...height1, value: data })}
                                    />
                                </div>
                            </div>
                            <div className="flex-fill">
                                <div className="form-group mx-4">
                                    <p>To</p>
                                    <SingleSelect
                                        options={Height}
                                        data={props.heightEndTo?.toString()}
                                        value={(data) => setHeight2({ ...height2, value: data })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <p>Religion</p>
                            <SingleSelect
                                options={religion.options}
                                data={props.religion}
                                value={(data) => setReligion({ ...religion, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Material status</p>
                            <SingleSelect
                                options={materialStatus.options}
                                data={props.materialStatus}
                                value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Mother Tongue</p>
                            <SingleSelect
                                options={motherTongue.options}
                                data={props.motherTongue}
                                value={(data) => setMotherTongue({ ...motherTongue, value: data })}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BasicLifeStyleModal;