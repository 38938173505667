import React, { useContext } from 'react';
import "./style.scss"
import { UserContext } from '../../../utils/context/userContext';
import { HeightCalculate } from "../../../utils/helpers"
// icons 
import Icon from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md'
import { heartBroken } from 'react-icons-kit/ionicons/heartBroken'
import Table from 'react-bootstrap/Table'
// import heartLove from "../../../assets/static/single-profile/"

const Index = ({ data }) => {
    const { user } = useContext(UserContext)

    return (
        <div id="partner_preference">
            <div className="info_box">
                <h5 className="details_title"><Icon icon={heartBroken} size={30} />
                    What {data.gender === 'male' ? 'he' : data.gender === 'female' ? 'she' : "he/she"} is looking for
                </h5>
                <div className="text_area">

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="img_box">
                            <img src={data.images[0].name} alt="..." />

                            <br />
                            <span>Her Preferences</span>
                        </div>

                        <div className="progress shadow mt-4 mt-lg-5">
                            <div className="progress-bar" role="progressbar" style={{ width: data?.partner?.count * 14 + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">{data?.partner?.count} / 7</div>
                        </div>

                        <div className="img_box">

                            <img src={user?.profilePicture?.clearImage} alt="..." />
                            <br />
                            <span>2-way Match</span>
                        </div>
                    </div>
                </div>


                <Table responsive className="mt-5">
                    <tbody>
                        <tr className="">
                            <td style={{ width: "80%" }}>Age <br /> {data.partner.ageRange?.startFrom || "---"} To {data.partner.ageRange?.endTo || "---"} </td>
                            <td
                                className={
                                    data.partner.ageStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Height <br /> {data.partner?.heightRange?.startFrom
                                ?
                                `${HeightCalculate(data.partner?.heightRange?.startFrom)} 
                            (${data.partner?.heightRange?.startFrom}) `
                                : "--- "}
                                To
                                {data.partner?.heightRange?.endTo
                                    ?
                                    ` ${HeightCalculate(data.partner?.heightRange?.endTo)} 
                            (${data.partner?.heightRange?.endTo}) `
                                    : "---"} </td>
                            <td
                                className={
                                    data.partner.heightStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Marital Status <br /> {data.partner.material || "---"}</td>
                            <td
                                className={
                                    data.partner.materialStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Religion / Community<br /> {data.partner.religion || "---"}</td>
                            <td
                                className={
                                    data.partner.religionStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Mother Tongue<br /> {data.partner.motherTongue || "---"}</td>
                            <td
                                className={
                                    data.partner.motherTongueStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Country Living In<br />{data.partner.country || "---"}</td>
                            <td
                                className={
                                    data.partner.countryStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%" }}>Qualification<br /> {data.partner.qualification || "---"}</td>
                            <td
                                className={
                                    data.partner.qualificationStatus
                                        ? "icon_active"
                                        : "icon_de_active"
                                }
                            > <Icon icon={ic_check_circle} size={20} /> </td>
                        </tr>
                    </tbody>
                </Table>

            </div>
        </div>
    );
};

export default Index;