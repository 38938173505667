import React, { useState, useContext, useEffect, useCallback } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import '../style.scss';
import PartnerEducationAndCareerModal from '../Modal/PartnerPreference/PartnerEducationCareer'
import { UserContext } from '../../../utils/context/userContext'


const PartnerEducationCareer = (props) => {
    const [modal, setModal] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const [annualIncome, setAnnualIncome] = useState(null)
    const [qualification, setQualification] = useState(null)
    const [workingWith, setWorkingWith] = useState(null)
    const [professionArea, setProfessionArea] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.partnerPreference) {
            setAnnualIncome(user.partnerPreference.annualIncome)
            setQualification(user.partnerPreference.educationAndProfession.qualification)
            setWorkingWith(user.partnerPreference.educationAndProfession.workingWith)
            setProfessionArea(user.partnerPreference.educationAndProfession.professionArea)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Partner Education Career</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow">
                                <Icon icon={edit2} size={20}
                                    onClick={() => setModal({ ...modal, show: true })}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Qualifications</th>
                                        <td className="pl-5">:</td>
                                        <td>{qualification && qualification.length ? qualification : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Working with</th>
                                        <td className="pl-5">:</td>
                                        <td>{workingWith && workingWith.length ? workingWith : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Profession Area</th>
                                        <td className="pl-5">:</td>
                                        <td>{professionArea && professionArea.length ? professionArea : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Annual Income</th>
                                        <td className="pl-5">:</td>
                                        <td>{annualIncome && annualIncome.startFrom !== null ? `${annualIncome.startFrom}-${annualIncome.endTo}` : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                modal.show ?
                    <PartnerEducationAndCareerModal
                        show={modal.show}
                        qualification={qualification}
                        workingWith={workingWith}
                        professionArea={professionArea}
                        income={annualIncome?.startFrom ? `${annualIncome.startFrom}-${annualIncome.endTo}` : null}
                        loading={modal.loading}
                        handleRefetch={handleRefetch}
                        onHide={() => setModal({ ...modal, show: false, loading: false })}
                    />
                    : null
            }
        </div>
    )
};

export default PartnerEducationCareer;