import React, { useState,useContext, useEffect, useCallback } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import '../style.scss';
import PartnerLocationModal from '../Modal/PartnerPreference/LocationOfPartner'
import { UserContext } from '../../../utils/context/userContext'

const LocationDetails = (props) => {
    const [modal, setModal] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const [country, setCountry] = useState(null)
    const [stateDivition, setStateDivition] = useState(null)
    const [city, setCity] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.partnerPreference) {
            setCountry(user.partnerPreference.location.country)
            setStateDivition(user.partnerPreference.location.stateDivision)
            setCity(user.partnerPreference.location.city)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Location Details</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setModal({ ...modal, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Current Residence</th>
                                        <td className="pl-5">:</td>
                                        <td >{country && country.length ? country[0] : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>State living in</th>
                                        <td className="pl-5">:</td>
                                        <td>{stateDivition && stateDivition.length ? stateDivition[0] : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>City / District</th>
                                        <td className="pl-5">:</td>
                                        <td >{city && city !== '' ? city : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                modal.show ?
                    <PartnerLocationModal
                        show={modal.show}
                        country={country}
                        city={city}
                        stateDivition={stateDivition}
                        loading={modal.loading}
                        handleRefetch={handleRefetch}
                        onHide={() => setModal({ ...modal, show: false, loading: false })}
                    />
                    : null
            }
        </div>
    )
};

export default LocationDetails;