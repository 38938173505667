import React, { useState } from 'react'
import './style.scss'
import ShortCard from '../../components/ShortCard/Index'
import { useSelector, useDispatch } from 'react-redux';
import { mobileMemberSetter } from '../../utils/Redux/Slice/Chat/SingleChatMemberSlice'
import { seenMessage } from '../../utils/Redux/Slice/Chat/ArraivalMessageSlice'
import MessageContainer from '../../components/MessageContainerMobile/Index'

const Index = () => {
    const connectedList = useSelector((state) => state.membersList.connected)
    const matchList = useSelector((state) => state.membersList.match)
    const previousList = useSelector((state) => state.membersList.previous)
    const arrivalMsgCount = useSelector((state) => state.arrivalMessage.count)
    const [persons, setPersons] = useState({ data: null, state: '' })
    const dispatch = useDispatch()
    const singleChatMember = useSelector(state => state.singleChatMember.obj)

    const handleChatMembers = data => {
        dispatch(mobileMemberSetter(data))
        dispatch(seenMessage(data._id))
    }
    const handlePrevious = personsLists => {
        setPersons({ ...persons, data: personsLists, state: 'previous' })
        dispatch(mobileMemberSetter({}))
    }
    const handleMatches = personsLists => {
        setPersons({ ...persons, data: personsLists, state: 'previous' })
        dispatch(mobileMemberSetter({}))
    }
    const handleConnect = personsLists => {
        setPersons({ ...persons, data: personsLists, state: 'previous' })
        dispatch(mobileMemberSetter({}))
    }

    return (
        <div className="d-block d-lg-none messanger-container">
            <div className='messanger-navbar'>
                <div className='btn' onClick={() => handlePrevious(previousList)}>
                    Chat
                    {
                        arrivalMsgCount > 0 ?
                            <p>{arrivalMsgCount}</p>
                            : null
                    }
                </div>
                <div className='btn' onClick={() => handleConnect(connectedList)}>Connected</div>
                <div className='btn' onClick={() => handleMatches(matchList)}>All</div>
            </div>

            <div>
                {
                    Object.entries(singleChatMember).length > 0 ?
                        <MessageContainer />
                        :
                        <div className='member-container'>
                            {persons.data ?
                                persons.data.map((one, idx) => {
                                    return (
                                        <div key={idx} onClick={() => handleChatMembers(one)}>
                                            <ShortCard one={one} />
                                        </div>
                                    )
                                })
                                : matchList ?
                                    matchList.map((one, idx) => {
                                        return (
                                            <div key={idx} onClick={() => handleChatMembers(one)}>
                                                <ShortCard one={one} />
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                }
            </div>
        </div>
    );
};

export default Index;