import React from 'react';

const Index = () => {
    return (
        <div>
            <h5 className="text-center">Page not found !</h5>
        </div>
    );
};

export default Index;