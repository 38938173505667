import React, { useState } from 'react'
import '../style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const Otp = (props) => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, setError,  formState: { errors } } = useForm()

    const onSubmit = async (data) => {
        setLoading(true)
        const response = await Requests.Settings.VerifyOtp(data, header)
        if (response.status) {
            setLoading(false)
            props.setShow('newpassword')
            setTimeout(()=>{
                toast.success('verification code is verified')
            },500)
        }
        else{
            setLoading(false)
            setError("otpCode", {
                type: "manual",
                message: "otp code is not correct",
              });
        }
    }
    return (
        <div className="card border-0">
            <div className="card-body px-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-4">
                        {errors.otpCode && errors.otpCode.message ? (
                            <p className="text-danger">{errors.otpCode && errors.otpCode.message}</p>
                        ) : <p>Otp code</p>}

                        <input
                            type="number"
                            placeholder="enter otp code"
                            className="form-control shadow-none"
                            {...register('otpCode', {
                                required: "Please enter 4 digit otp code"
                            })}
                        />
                    </div>
                    <div className="text-right d-flex">
                        <button
                            type="submit"
                            className="btn shadow-none"
                            disabled={isLoading}>
                            {isLoading ? 'verifying...' : 'verify'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Otp;