import React from 'react'
import ReactDOM from 'react-dom'
import App from './App';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools'
import store from './utils/Redux/Store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
export const queryClient = new QueryClient()

let persistor = persistStore(store);

ReactDOM.render(
  <QueryClientProvider client={queryClient} >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    {/* <ReactQueryDevtools /> */}
  </QueryClientProvider>,
  document.getElementById('root')
);

