import React from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import NavBar from '../../components/NavBar/Connection'

import AcceptedIndex from './Accepted/Index'
import PendingIndex from './Pending/Index'
import SendIndex from './Send/Index'
// import FourOFour from '../FourOfour/Index'

const Master = () => {
    return (
        <div className="connection-master">
            <NavBar />
            <Switch>
                <Route exact path="/connection/" component={AcceptedIndex} />
                <Route exact path="/connection/send" component={SendIndex} />
                <Route exact path="/connection/pending" component={PendingIndex} />

                {/* <Route path="*" component={FourOFour} /> */}
            </Switch>
        </div>
    );
};

export default Master;