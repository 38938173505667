import React, { useState, useContext, useEffect, useCallback } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import ReligionModal from './Modal/ReligiousBackground'
import { UserContext } from '../../utils/context/userContext';

const ReligiousBackground = (props) => {

    const [model, setModel] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const [motherTongue, setMotherTongue] = useState(null)
    const [socialOrder, setSocialOrder] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.language.motherTongue) {
            setMotherTongue(user.language.motherTongue)
        }
        if (user.socialOrder) {
            setSocialOrder(user.socialOrder)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Religious Background</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setModel({ ...model, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Mother Tongue</th>
                                        <td className="pl-5">:</td>
                                        <td>{motherTongue ? motherTongue : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Family Title</th>
                                        <td className="pl-5">:</td>
                                        <td>{user.title ? user.title : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>

                        </div>
                        <div className="col-12 col-md-6 ">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Religion</th>
                                        <td className="pl-5">:</td>
                                        <td>{user.religion ? user.religion : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Community</th>
                                        <td className="pl-5">:</td>
                                        <td>{socialOrder ? socialOrder : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {model.show ?
                <ReligionModal
                    show={model.show}
                    socialOrder={socialOrder}
                    motherTongue={motherTongue}
                    title={user.title}
                    religion={user.religion}
                    loading={model.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setModel({ ...model, show: false, loading: false })}
                />
                : null}
        </div>
    )
};

export default ReligiousBackground;