import React, { useState, useContext } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../Form/SingleSelect'
import { optionContext } from '../../../utils/context/optionContext'
import Loading from '../../Loading/Index'
import Requests from '../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const ContactDetailsModal = (props) => {
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const optionData = useContext(optionContext)
    const [settings, setSetting] = useState({
        value: null,
        options:[
            {label:"Hide",value:"Hide"},
            {label:"only open for paid members",value:"Paid Members"},
            {label:"Public",value:"Public"},
        ]
    })
    const onSubmit = async (data) => {
        let obj = {
            contactPersonName: data.contactPerson ? data.contactPerson : null,
            relationship:data.relationship ? data.relationship : null,
            guardianMobile: data.guardianMobile ? data.guardianMobile : null,
            convenientTimeToCall: data.timeToCall ? data.timeToCall : null,
            settings:settings.value
        }
        setLoading(true)
        let response = await Requests.Profile.UpdateContactDetails(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }
    if (!optionData) return <Loading />
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Contact Details</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Contact Person</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={props.contactPersone || ''}
                                {...register('contactPerson')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Guardian Mobile No</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={props.guardianMobile || ''}
                                {...register('guardianMobile')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Relationship with this person</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={props.relationship || ''}
                                {...register('relationship')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Convineant time to call</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="10am TO 10pm"
                                defaultValue={props.timeToCall || ''}
                                {...register('timeToCall')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Display option</p>
                            <SingleSelect
                                data={props.displayOption}
                                options={settings.options}
                                value={(data) => setSetting({ ...settings, value: data })}
                            />
                        </div>

                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ContactDetailsModal;