import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import Requests from '../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })
const PhoneNumberModal = (props) => {
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const onSubmit = async (data) => {
        setLoading(true)
        const response = await Requests.Profile.UpdatePhoneNumber(data, header)
        if (response.status) {
            props.onHide()
            toast.success('Please Verify your phone Number')
            props.handleOtpModel()
        }
        else {
            props.onHide()
        }
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Phone Number</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Enter your phone number"
                                defaultValue={props.data || ''}
                                {...register('phone')}
                            />
                        </div>

                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'requestion...' : 'Verify'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PhoneNumberModal;