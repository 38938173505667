import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Index of TimelinePost
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}timeline-post`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Store = async (data, header) => {
    try {
        const response = await Axios.post(`${api}timeline-post`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateComment = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}timeline-post/${id}/comment`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateLike = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}timeline-post/${id}/like`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Delete = async (id, header) => {
    try {
        const response = await Axios.delete(`${api}timeline-post/${id}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}



const TimelinePost = {
    Index,
    Store,
    UpdateComment,
    UpdateLike,
    Delete,
}

export default TimelinePost;