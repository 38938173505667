
import Logo from '../assets/static/logo.png'
import Avatar from '../assets/static/avatar.jpg'
import FemaleAvatar from '../assets/static/femaleavatar.jpg'
import WebAdd from '../assets/add.png'
import dp from '../assets/dp.jpg'
import PackageSticker from '../assets/package.jpg'
import PhoneIcon from "../assets/phoneicon.png"
import WebAdd2 from '../assets/add2.jpg'
import Post from '../assets/post.jpg'
import Back from '../assets/back.png'
import Side from '../assets/side.png'
import Blur from '../assets/blur.png'
import Group from '../assets/group.png'
import Clear2 from '../assets/clear2.png'
import Clear from '../assets/clear.png'
import Close from '../assets/close.png'
import NoImage from '../assets/noimage.jpg'




export const Images = {
    Logo,
    Avatar,
    FemaleAvatar,
    WebAdd,
    dp,
    PackageSticker,
    PhoneIcon,
    WebAdd2,
    Post,
    Back,
    Side,
    Blur,
    Group,
    Close,
    Clear2,
    Clear,
    NoImage
}
