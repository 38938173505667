import React from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import NavBar from '../../components/NavBar/Matches'

import NewMatches from './New/Index'
import TodayMatches from './Today/Index'
import AllMatches from './All/Index'
import NearMe from './NearMe/Index'
import RecentlyViewed from './RecentlyViewed/Index'
// import FourOFour from '../FourOfour/Index'

const Master = () => {

    return (
        <div className="matches-master">
            <NavBar />
            <Switch>

                    <Route exact path="/matches/" component={NewMatches} />
                    <Route exact path="/matches/today" component={TodayMatches} />
                    <Route exact path="/matches/all" component={AllMatches} />
                    <Route exact path="/matches/near-me" component={NearMe} />
                    <Route exact path="/matches/recently-viewed" component={RecentlyViewed} />

                    {/* <Route path="*" component={FourOFour} /> */}

            </Switch>
        </div>
    );
};

export default Master;