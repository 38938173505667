import React, { useState, useEffect, useContext } from 'react'
import './style.scss'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Icon from 'react-icons-kit'
import { edit2, eye } from 'react-icons-kit/feather'
import { heart } from 'react-icons-kit/icomoon'
import { Images } from '../../../utils/Images'
import Requests from '../../../utils/Requests/Index'
import ImageCrop from '../../../components/Form/Picture'
import ProfileCard from '../../../components/Dashboard/ProfileCard'
import ProfileCard2 from "../../../components/Profile/Index"
import ProfileSlider from '../../../components/ProfileSlider/Index'
import Loading from '../../../components/Loading/Index'
import { useLocation, useHistory } from 'react-router-dom'
import { singleProContext } from '../../../utils/context/SingleProfileContext'

toast.configure({ autoClose: 2000 })


const Index = () => {
    let { search } = useLocation()
    let histroy = useHistory()
    const { handleLocalStoreIds } = useContext(singleProContext);
    const [show, setShow] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageLoad, setPageload] = useState(true)

    // Fetch data
    const getDashboardData = async () => {
        try {
            const data = await Requests.Dashboard.Index(header)
            if (data && data.user) {
                setPageload(false)
            }
            return data.user
        } catch (error) {
            // toast.error(error.message)
            console.log(error.message)
        }
    }

    const { data, isLoading, isError } = useQuery("dashboard", getDashboardData, {
        retry: false,
        cacheTime: 1200000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    //after payment
    useEffect(() => {
        if (search.split('=')[1] === "success") {
            toast.success('Congratulations,Payment Successful')
        }
        else if (search.split('=')[1] === 'failed') {
            toast.error('Payment is not Successful, try again')
        }
        else if (search.split('=')[1] === 'cancle') {
            toast.error('Payment cancled, try again')
        }
    }, [search])

    useEffect(() => {
        // Set ids For Single Profile...
        let recentViewIds = []
        let matchIds = []
        if (data?.recentViewed) {
            recentViewIds = data?.recentViewed?.map(info => info._id);
        }
        if (data?.matches) {
            matchIds = data?.matches?.map(info => info._id);
        }
        handleLocalStoreIds([...recentViewIds, ...matchIds]);
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const toPackage = e => {
        e.preventDefault()
        histroy.push('/my-biyesadi/package')
    }

    if (isLoading || isError) return <Loading />

    // console.log(data?.recentViewed)

    return (
        <div className="dashboard">
            {/* Profile container */}
            <div className="profile-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-lg-flex">
                                {/* Profile card */}
                                <div className="card border-0 profile-card mb-4 mb-xl-0">
                                    <div className="card-body text-center p-4">
                                        <div className="img-container">
                                            <img src={data.image ? data.image : Images.NoImage} className="img-fluid rounded-circle" alt="..." />
                                            <button type="button" className="btn shadow rounded-circle" onClick={() => setShow(true)}>
                                                <Icon icon={edit2} size={18} />
                                            </button>
                                        </div>
                                        <br />

                                        <div className="content">
                                            <h6 className="text-uppercase">{data.name}</h6>
                                            <p>ID: {data.profileId}</p>
                                        </div>
                                        <hr />

                                        <div className="content">
                                            <h6>Account Type</h6>
                                            <p>{data.profileType} Membership</p>
                                            <p><Icon size={14} icon={heart} className="mr-1" /> Proposal limit: {data.packageInfo?.totalLimit ? <span style={{ color: "#D02C6A" }}>{data.packageInfo?.totalLimit}</span> : <span style={{ color: "#2FA745", cursor: "pointer" }} onClick={toPackage}>Upgrade</span>}</p>
                                            <p><Icon size={14} icon={eye} className="mr-1" />Contact view: {data.profileType === 'Free' ? <span className='text-success' style={{ cursor: "pointer" }} onClick={toPackage}>Upgrade</span> : <span style={{ color: "#D02C6A" }}>{data.packageInfo?.contactViewLimit}</span>}</p>
                                            <p><Icon size={14} icon={eye} className="mr-1" />Expire Date: {data.profileType === 'Free' ? <span className='text-success' style={{ cursor: "pointer" }} onClick={toPackage}>Upgrade</span> : <span style={{ color: "#D02C6A" }}>{data.packageExpire.split("T")[0]}</span>}</p>
                                            {/* <p><Icon size={14} icon={mail} className="mr-2" />Unlimited messaging</p> */}
                                        </div>
                                        <hr />
                                        <div className="content">
                                            <h6>Mobile Verification</h6>
                                            <p>{data.isPhoneVerified ?
                                                <span className="text-success">Verified number</span> :
                                                <span className="text-warning">Unverified number</span>
                                            }</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity card */}
                                <div className="card flex-fill border-0 px-lg-4 mb-4 mb-xl-0 activity-card">
                                    <div className="card-header border-0 py-3">
                                        <h5>Activity Summary</h5>
                                    </div>
                                    <div className="card-body px-0">

                                        <div className="content-card">
                                            <div className="body shadow">
                                                <h6>Connection</h6>
                                                <h4>{data.connection}</h4>
                                                <span className="angle" />
                                            </div>
                                        </div>

                                        <div className="content-card">
                                            <div className="body shadow">
                                                <h6>Favorite</h6>
                                                <h4>{data.favorite}</h4>
                                                <span className="angle" />
                                            </div>
                                        </div>

                                        <div className="content-card">
                                            <div className="body shadow">
                                                <h6>Pending Request</h6>
                                                <h4>{data.pendingRequest}</h4>
                                                <span className="angle" />
                                            </div>
                                        </div>

                                        <div className="content-card">
                                            <div className="body shadow">
                                                <h6>Sent Request</h6>
                                                <h4>{data.sentRequest}</h4>
                                                <span className="angle" />
                                            </div>
                                        </div>

                                        <div className="px-2">
                                            <div className="progress shadow mt-4 mt-lg-5">
                                                <div className="progress-bar" role="progressbar" style={{ width: data.updateRange + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">{data.updateRange}%</div>
                                            </div>
                                        </div>
                                        <p>Profile improve steps ( ex: contact details, id card information)</p>
                                    </div>
                                </div>

                                {/* Add card */}
                                <div className="card d-none d-xl-block border-0 add-card">
                                    <img src={data?.addsense ? data.addsense : Images.WebAdd} className="img-fluid" alt="Add sense" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Viewed container */}
            <div className="visitors-container">
                <div className="">
                    <div className="col-12 mb-4 pl-xl-5">
                        <h5 className="text-center text-xl-left mb-3 pl-xl-4">Recent Visit</h5>
                    </div>

                    {data && data.recentViewed && data.recentViewed.length ?
                        <ProfileSlider items={data.recentViewed} setPageload={setPageload} />
                        : <div className='text-center'>no such user viewed your profile yet</div>
                    }

                </div>
            </div>

            {/* Matches container */}
            <div className="matches-container">
                <div className="container">

                    {/* My Matches */}
                    <div className="row">
                        <div className="col-12 mb-5 mb-xl-0 ">
                            <h5 className="text-center text-xl-left mb-3">My Matches</h5>
                        </div>
                    </div>

                    <div className="d-block d-md-none">
                        {data && data.matches && data.matches.length ? data.matches.map((item, i) =>
                            <ProfileCard2 item={item} key={i} />
                        ) : null}
                    </div>

                    <div className="d-none d-md-block">
                        <div className="row">
                            {data && data.matches && data.matches.length ? data.matches.map((item, i) =>
                                <div className="col-12 col-lg-6 mb-5 mb-xl-0" key={i}>
                                    <ProfileCard item={item} />
                                </div>
                            ) : null}
                        </div>
                    </div>



                    {/* New Matches */}
                    {/* <div className="col-12 col-lg-6">
                            <h5 className="text-center text-xl-left mb-4">New Matches</h5>

                            {result && result.length ? result.map((item, i) =>
                                <ProfileCard item={item} key={i} />
                            ) : null}
                        </div> */}

                </div>
            </div>
            {show ? <ImageCrop show={show} onHide={() => setShow(false)} image={data.image ? data.image : null} setPageload={setPageload} /> : null}
        </div>
    );
};

export default Index;