import React, { useState } from 'react'
import '../style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const NewPassword = (props) => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, setError, formState: { errors } } = useForm()

    const onSubmit = async (data) => {
        setLoading(true)
        if(data.newPassword !== data.confirmNewPassword){
            setError("confirmNewPassword", {
                type: "manual",
                message: "confirm password didn't match",
              });
            setLoading(false)
        }
        else{
            let obj = {
                newPassword:data.newPassword
            }
            const response = await Requests.Settings.ChangePassword(obj, header)
            if (response.status) {
                setLoading(false)
                props.setShow(null)
                await Requests.Auth.Logout(header);
                setTimeout(()=>{
                    toast.success('Password change successful')
                    localStorage.clear()
                    window.location.reload()
                },500)
            }
            else{
                setLoading(false)
                toast.error('Password change failed')
            }
        }
    }
    return (
        <div className="card border-0">
            <div className="card-body px-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-4">
                        {errors.newPassword && errors.newPassword.message ? (
                            <p className="text-danger">{errors.newPassword && errors.newPassword.message}</p>
                        ) : <p>New password</p>}

                        <input
                            type="password"
                            placeholder="new password"
                            className="form-control shadow-none"
                            {...register('newPassword', {
                                required: "Please enter new password",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                        />
                    </div>
                    <div className="form-group mb-4">
                        {errors.confirmNewPassword && errors.confirmNewPassword.message ? (
                            <p className="text-danger">{errors.confirmNewPassword && errors.confirmNewPassword.message}</p>
                        ) : <p>Confirm new password</p>}

                        <input
                            type="password"
                            placeholder="confirm new password"
                            className="form-control shadow-none"
                            {...register('confirmNewPassword', {
                                required: "Please confirm new password",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                        />
                    </div>
                    <div className="text-right d-flex">
                        <button
                            type="submit"
                            className="btn shadow-none"
                            disabled={isLoading}>
                            {isLoading ? 'Reseting...' : 'Reset'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewPassword;