import React, { useRef } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    const refs = useRef()
    return (
        <div className="membership-package-ghost">
            <div className="container-fluid d-flex">
                <div>
                    <Skeleton width={250} height={300} />
                    <Skeleton width={250} height={150} className="mt-2"/>
                </div>
                <div className="row ml-35">

                    {[...Array(6).keys()].map((i) => {
                        return (
                            <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-2" key={i}>
                                {/* Package card */}
                                <div className="card border-0" style={styles.card}>
                                    <div className="card-body py-4" ref={refs}>
                                        <div className="text-center">
                                            <Skeleton width={70} height={70} circle={true} className="m-auto" />
                                            <br />
                                            <br />

                                            <Skeleton width={150} height={30} />
                                            <div className="mb-3">
                                                <Skeleton width={100} height={25} />
                                                <br />
                                                <Skeleton width={150} height={15} />
                                            </div>
                                        </div>

                                        <Skeleton width={refs.innerWidth} height={15} count={5} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    );
}

export default Index;

const styles = {
    card: {
        borderRadius: 6,
        Overflow: 'hidden'
    }
}