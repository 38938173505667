import React, { useState } from 'react';
import './style.scss';
// icons 
import Icon from 'react-icons-kit'
import { heart } from 'react-icons-kit/fa/heart'
import { Link } from 'react-router-dom';

const ProfileCard = ({ item, buttonTitle, changetype, updateFavorite, favoriteShow, loading }) => {
    const [state, setState] = useState(item.favorite)

    return (
        <div className="custom-profile-card card border-0 text-center">
            <div className="card-body shadow-sm">
               <div className="icon_and_image">
                    {
                    favoriteShow && <div className="favorite_box">
                        <Icon
                            className={`favorite_icon ${state ? "add" : "remove"}`}
                            onClick={() => {
                                updateFavorite(item?._id, `${state ? "remove" : "add"}`)
                                setState(!state)
                            }
                            }
                            icon={heart}
                            size={22}
                        />
                    </div>
                }
                <div className="img-container rounded-circle">
                    <img src={item.image} className="img-fluid" alt="..." />
                </div>
               </div>
                <div className="content">
                    <Link to={`/single-profile/${item._id}`}>
                        <h6  className="profile_id">@{item.profileId}</h6>
                    </Link>
                    <h6>{item.name}</h6>
                    <p>{item.isPhoneVerified ? <span className="text-success">Verified number</span> : <span className="text-danger">Unverified number</span>}</p>
                    <p>{item.age} years</p>
                    <p>lives in {item.city}</p>

                    <p className="my-3">Citizenship: {item.birthCountry}</p>

                    <button
                        type="button"
                        className="btn shadow-sm"
                        disabled={loading?.value === item?._id && loading?.status}
                        onClick={() => changetype(item._id)}
                    >{buttonTitle}</button>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;