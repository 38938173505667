import { createSlice } from '@reduxjs/toolkit'

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {
        incomingProposal: 0,
        acceptedProposal: 0,
        profileViewed: 0,
        profileViewPerson: null,
        count: 0
    },
    reducers: {
        incomingProposal: (state) => {
            return {
                ...state,
                incomingProposal: state.incomingProposal + 1,
                count: state.count + 1
            }
        },
        seenIncomingProposal: (state) => {
            let num = state.incomingProposal
            return {
                ...state,
                incomingProposal: 0,
                count: state.count - num
            }
        },
        acceptedProposal: (state) => {
            return {
                ...state,
                acceptedProposal: state.acceptedProposal + 1,
                count: state.count + 1
            }
        },
        seenAcceptedProposal: (state) => {
            let num = state.acceptedProposal
            return {
                ...state,
                acceptedProposal: 0,
                count: state.count - num
            }
        },
        initialProfileViewed: (state, action) => {
            return {
                ...state,
                profileViewed: action.payload.count,
                profileViewPerson: action.payload.dataList,
                count:  action.payload.count
            }
        },
        doProfileViewed: (state, action) => {
            let isExists = state.profileViewPerson.find(one => one._id === action.payload._id)
            if(!isExists){
                return {
                ...state,
                profileViewed: state.profileViewed + 1,
                profileViewPerson: [action.payload, ...state.profileViewPerson],
                count: state.count + 1
            }
        }
        },
        seenProfileViewed: (state) => {
            let num = state.profileViewed
            return {
                ...state,
                profileViewed: 0,
                profileViewPerson: null,
                count: state.count - num
            }
        },
        initNotification: () => {
            return {
                count: 0,
                incomingProposal: 0,
                acceptedProposal: 0,
                profileViewed: 0,
                profileViewPerson: null
            }
        }
    }
})

export const {
    initNotification,
    incomingProposal,
    acceptedProposal,
    seenIncomingProposal,
    seenAcceptedProposal,
    initialProfileViewed,
    doProfileViewed,
    seenProfileViewed
} = NotificationSlice.actions
export default NotificationSlice.reducer