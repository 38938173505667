import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Index of dashboard
const MessageSend = async (data, header) => {
    try {
        const response = await Axios.post(`${api}message/create`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// Get all messages of a conversation
const MessagesOfConversation = async (data, header) => {
    try {
        const response = await Axios.get(`${api}messages/${data}`, header)
        if (response.status === 200) {
            return response.data

        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Delete a message
const MessagesDelete = async (data, header) => {
    try {
        const response = await Axios.patch(`${api}message/delete`, data, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Message = {
    MessageSend,
    MessagesOfConversation,
    MessagesDelete
}

export default Message