import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Index of dashboard
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}dashboard`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const UploadProfilePicture = async (image, header) => {
    try {
        const response = await Axios.post(`${api}uploadprofileimage`, image, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const DeleteProfilePicture = async (header) => {
    try {
        const response = await Axios.delete(`${api}deleteprofileimage`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const Dashboard = {
    Index,
    UploadProfilePicture,
    DeleteProfilePicture
}

export default Dashboard