// Age calculate
export const AgeCalculate = dob => Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e+10)


export const HeightCalculate = n => {
    var realFeet = ((n * 0.393700) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + '"';
}
export const HeightCalculatetoNumber = n => {
    var realFeet = ((n * 0.393700) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "." + inches;
}

// n year old's YEAR from today
// export const nYearOldsYear = (age) => {
//     const date = new Date()
//     const year = date.getFullYear()
//     const month = date.getMonth()
//     const day = date.getDate()
//     const birthYearYear = parseInt(year) - parseInt(age)
//     return birthYearYear;
// }

// export const dateToYear = dob => {
//     const date = new Date(dob)
//     const year = date.getFullYear()
//     return year
// }

export const DayMonthYear = create => {
    const date = new Date(create)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return `${day}/${month}/${year}`
}