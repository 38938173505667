import React, { useState, useContext } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal, Form } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Requests from '../../../utils/Requests/Index'
import { UserContext } from '../../../utils/context/userContext'

toast.configure({ autoClose: 2000 })

const Settings = (props) => {

    const { user, handleRefetch } = useContext(UserContext)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState(user.profilePicture.status)
    const settingOptions = [
        { label: 'Visible to all members', value: true },
        { label: 'Hide for all members', value: false }
    ]

    const handleEvent = value => {
        setSelected(value)
        setError(false)
    }

    const submitForm = async (event) => {
        event.preventDefault()
        if (selected === null) return setError(true)
        setLoading(true)
        // console.log(selected)
        let obj = {
            data: selected
        }
        const response = await Requests.MyPhoto.ImageSettings(obj, header)
        if (response.status) {
            handleRefetch()
            props.onHide()
            toast.success('Successfully saved')
            setLoading(false)
        }
        else {
            props.onHide()
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Display Settings.</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <form onSubmit={submitForm}>
                        {error ? <p className="text-danger">Select an option.</p> : <p>Select option.</p>}
                        {settingOptions.map((option, i) => (
                            <div key={i} className="mb-3">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    id={option.label}
                                    label={option.label}
                                    value={option.value}
                                    checked={selected === option.value}
                                    onChange={() => handleEvent(option.value)}
                                />
                            </div>
                        ))}
                    </form>
                    <div className="text-right pt-3">
                        <button
                            type="button"
                            className="btn shadow-none save-btn"
                            onClick={submitForm}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save my setting'}
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Settings;