import React, { useState, useContext, useEffect } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../Form/SingleSelect'
import { optionContext } from '../../../utils/context/optionContext'
import Requests from '../../../utils/Requests/Index'
import Loading from '../../Loading/Index'

toast.configure({ autoClose: 2000 })
const ReligionModal = (props) => {

    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit, register } = useForm()
    const { handleOptionToData } = useContext(optionContext)
    const [optionsData,setOptionsData] = useState(false)

    const [religion, setReligion] = useState({
        value: null,
        options: []
    })

    const [title, setTitle] = useState({
        value: null,
        options: []
    })

    const [motherTongue, setMotherTongue] = useState({
        value: null,
        options: []
    })

    useEffect(() => {
        setMotherTongue({ ...motherTongue, options: handleOptionToData("Language") })
        setTitle({ ...title, options:  handleOptionToData("Social title") })
        setReligion({ ...religion, options:  handleOptionToData("Religion") })
        setOptionsData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (data) => {
        let obj = {
            socialOrder: data.socialOrder ? data.socialOrder : null,
            religion: religion.value,
            title: title.value,
            motherTongue: motherTongue.value
        }
        setLoading(true)
        let response = await Requests.Profile.UpdateReligiousBackground(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    if(!optionsData) return <Loading />

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Religion and Community</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Mother Tongue</p>
                            <SingleSelect
                                options={motherTongue.options}
                                data={props.motherTongue}
                                value={(data) => setMotherTongue({ ...motherTongue, value: data })}
                            />
                        </div><div className="form-group mb-4">
                            <p>Family Title</p>
                            <SingleSelect
                                options={title.options}
                                data={props.title}
                                value={(data) => setTitle({ ...title, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Religion</p>
                            <SingleSelect
                                options={religion.options}
                                data={props.religion}
                                value={(data) => setReligion({ ...religion, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Community</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={props.socialOrder || ''}
                                placeholder="sunni,shia ....etc"
                                {...register('socialOrder')}
                            />
                        </div>
                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my bio'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ReligionModal;