import { createSlice } from '@reduxjs/toolkit'

export const MemberListSlice = createSlice({
    name: "membersList",
    initialState: { connected: null, match: null, previous: null, status: '' },
    reducers: {
        connectedSetter: (state, action) => {
                return{
                    ...state,
                    connected : action.payload
                }
        },
        matchSetter: (state, action) => {
            return{
                ...state,
                match : action.payload
            }
        },
        previousSetter: (state, action) => {
            return{
                ...state,
                previous : action.payload
            }
        }
    },
});

export const { connectedSetter, matchSetter, previousSetter} = MemberListSlice.actions

export default MemberListSlice.reducer;