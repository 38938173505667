import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// Fixed search
const Fixed = async (query, header) => {
    try {
        const response = await Axios.get(`${api}search/fixed?query=${query}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Basic = async (data, header) => {
    try {
        const {
            limit,
            page,
            age1,
            age2,
            height1,
            height2,
            religion,
            materialStatus,
            motherTongue } = data

        const response = await Axios.get(`${api}search/basic?page=${page}&limit=${limit}&age1=${age1}&age2=${age2}&height1=${height1}&height2=${height2}&religion=${religion}&materialStatus=${materialStatus}&motherTongue=${motherTongue}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Advance = async (data, header) => {
    try {
        const {
            limit,
            page,
            age1,
            age2,
            height1,
            height2,
            religion,
            livingCountry,
            chatStatus,
            photoSetting,
            materialStatus,
            motherTongue,
            livingState,
            birthCountry,
            qualification,
            workingWith,
            professionArea,
            annualIncome } = data

        const response = await Axios.get(`${api}search/advance?page=${page}&limit=${limit}&age1=${age1}&age2=${age2}&height1=${height1}&height2=${height2}&religion=${religion}&livingCountry=${livingCountry}&chatStatus=${chatStatus}&photoSetting=${photoSetting}&materialStatus=${materialStatus}&motherTongue=${motherTongue}&livingState=${livingState}&birthCountry=${birthCountry}&qualification=${qualification}&workingWith=${workingWith}&professionArea=${professionArea}&annualIncome=${annualIncome}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}



const Search = {
    Fixed,
    Basic,
    Advance
}

export default Search