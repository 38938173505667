import React, { useState } from 'react'
import './style.scss'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import PackegeCard from '../../../components/MyBiye/Package/Index'
import Requests from '../../../utils/Requests/Index'
import GhostPackage from '../../../components/SkeletonLoader/GhostLoader'
import { useQuery } from 'react-query'

const Index = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    // const [result, setResult] = useState(null)
    // const [isLoading, setLoading] = useState(true)
    let [pageLoad, setPageload] = useState(true)

    const getPackages = async () => {
        try {
            // setLoading(true)
            const data = await Requests.Package.Index(header)
            // setResult(data)
            // setLoading(false)
            setPageload(false)
            return data
        } catch (error) {
            setPageload(false)
        }
    }

    const { data, isLoading, isError } = useQuery("packages", getPackages, {
        retry: false,
        cacheTime: 2100000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    // const fetchData = useCallback(async () => {
    //     setLoading(true)
    //     const data = await Requests.Package.Index(header)
    //     setResult(data)
    //     setLoading(false)
    // }, [header])

    // useEffect(() => {
    //     fetchData()
    // }, [header, fetchData])

    if (isLoading || isError) return <GhostPackage />

    return (
        <div className="package-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />

                            <div className="flex-fill main-section px-lg-4 py-4 pt-lg-0">
                                {data ? data && data.map((item, i) =>
                                    <PackegeCard data={item} key={i} />)
                                    :
                                    <div>Packages are not available yet!</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Index;