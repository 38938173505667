import React, { useState, useContext } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { settings, info } from 'react-icons-kit/feather'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import Gallery from '../../../components/MyPhoto/Gallery/Index'
import SettingsModal from '../../../components/MyPhoto/Modal/Settings'
import ImageCrop from '../../../components/Form/UploadImage'
import { Images } from '../../../utils/Images'
import { UserContext } from '../../../utils/context/userContext'
import Loading from '../../../components/Loading/Index'

const Index = () => {
    const [setting, setSetting] = useState({ show: false, loading: false })
    const [modal, setModal] = useState({ show: false, loading: false })
    const { user } = useContext(UserContext)

    if (!user) return <Loading />

    return (
        <div className="photo-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />

                            <div className="flex-fill main-section px-lg-4 pb-4">
                                <div className="card">
                                    {/* Header */}
                                    <div className="card-header d-flex px-0 pb-3 pt-lg-0">
                                        <div><h5 className="mb-0">Photo Gallery</h5></div>
                                        <div className="ml-auto">
                                            <button
                                                type="button"
                                                className="btn shadow rounded-circle ml-2"
                                                onClick={() => setSetting({ ...setting, show: true })}
                                            >
                                                <Icon icon={settings} size={22} />
                                            </button>
                                        </div>
                                    </div>

                                    {/* Body */}
                                    <div className="card-body">
                                        <div className="buttonContainer">
                                            <p className="text-center">Upload photo from your directory</p>
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="shadow uploadbtn"
                                                    onClick={() => setModal({ ...modal, show: true })}
                                                >
                                                    Browse Photo
                                                </button>
                                            </div>
                                        </div>

                                        <div className="text-center heading">
                                            Get more responses by uploading upto 6 photos on your profile.
                                        </div>
                                        {
                                            user.uploadImages && user.uploadImages.length ?
                                                user.uploadImages.map((item, i) => {
                                                    return item.name.includes("clear") ?
                                                        <Gallery key={i} item={item} />
                                                        : null
                                                })
                                                : null
                                        }
                                    </div>
                                    <div className="row warning-section">
                                        <div className="col-lg-6 col-md-12">
                                            <h6>Photo you can upload.</h6>
                                            <div className="validImages">
                                                <div className="images">
                                                    <img src={Images.Close} className="singleImage" alt="..." />
                                                    <span>Close image</span>
                                                </div>
                                                <div className="images">
                                                    <img src={Images.Clear} className="singleImage" alt="..." />
                                                    <span>Full image</span>
                                                </div>
                                                <div className="images">
                                                    <img src={Images.Clear2} className="singleImage" alt="..." />
                                                    <span>Clear image</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <h6>Photo you can't upload.</h6>
                                            <div className="invalidImages">
                                                <div className="images">
                                                    <img src={Images.Blur} className="singleImage" alt="..." />
                                                    <span>Blur image</span>
                                                </div>
                                                <div className="images">
                                                    <img src={Images.Group} className="singleImage" alt="..." />
                                                    <span>Group image</span>
                                                </div>
                                                <div className="images">
                                                    <img src={Images.Side} className="singleImage" alt="..." />
                                                    <span>Side face</span>
                                                </div>
                                                <div className="images">
                                                    <img src={Images.Back} className="singleImage" alt="..." />
                                                    <span>Backward</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex mt-3">
                                            <div><Icon icon={info} size={25} /></div>
                                            <div className="pt-1 ml-2"><h6>Notes</h6></div>
                                        </div>
                                        <div>
                                            <p className="m-3">Basic Rules:</p>
                                            <ul>
                                                <li>Smile. Your matches are more likely to respond.</li>
                                                <li>Add recent,close and clear photos</li>
                                                <li>You can upload 6 photos to your profile</li>
                                            </ul>
                                            <p className="m-3">Here are some reasons we reject photos:</p>
                                            <ul>
                                                <li>Do not upload photograph which shows you with a cigarette / cigar</li>
                                                <li>Watermarked, digitally enhanced,photos with
                                                    your personal information will be rejected</li>
                                                <li>Backward,Side-face or upside-down photos are likely to be rejected.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    {/* <div className="card-footer text-center pt-4 pb-0">
                                        <button type="button" className="btn shadow-none load-more">Load More</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {setting.show ?
                <SettingsModal
                    show={setting.show}
                    loading={setting.loading}
                    onHide={() => setSetting({ ...setting, show: false, loading: false })}
                />
                : null}

            {modal.show ?
                <ImageCrop
                    show={modal.show}
                    loading={modal.loading}
                    onHide={() => setModal({ ...modal, show: false, loading: false })}
                /> : null}


        </div>
    );
};

export default Index;