import React, { useState, useContext, useEffect } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import SingleSelect from '../../Form/SingleSelect'
import { optionContext } from '../../../utils/context/optionContext'
import Loading from '../../Loading/Index'
import Requests from '../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const LocationOfGroomModal = (props) => {

    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit, register } = useForm()
    const { handleOptionToData } = useContext(optionContext)
    const [optionsData, setOptionsData] = useState(false)

    const [birthCountry, setBirthCountry] = useState({
        value: null,
        options: []
    })

    const [livingCountry, setLivingCountry] = useState({
        value: null,
        options: []
    })

    const [city, setCity] = useState({
        value: null,
        options: [
            { value: 'Bagerhat', label: "Bagerhat" },
            { value: 'Bandarban', label: "Bandarban" },
            { value: 'Barguna', label: "Barguna" },
            { value: 'Barisal', label: "Barisal" },
            { value: 'Bhola', label: "Bhola" },
            { value: 'Bogra', label: "Bogra" },
            { value: 'Brahmanbaria', label: "Brahmanbaria" },
            { value: 'Chandpur', label: "Chandpur" },
            { value: 'Chittagong', label: "Chittagong" },
            { value: 'Chuadanga', label: "Chuadanga" },
            { value: 'Comilla', label: "Comilla" },
            { value: "Cox'sBazar", label: "Cox'sBazar" },
            { value: 'Dhaka', label: "Dhaka" },
            { value: 'Dinajpur', label: "Dinajpur" },
            { value: 'Faridpur', label: "Faridpur" },
            { value: 'Feni', label: "Feni" },
            { value: 'Gaibandha', label: "Gaibandha" },
            { value: 'Gazipur', label: "Gazipur" },
            { value: 'Gopalganj', label: "Gopalganj" },
            { value: 'Habiganj', label: "Habiganj" },
            { value: 'Jaipurhat', label: "Jaipurhat" },
            { value: 'Jamalpur', label: "Jamalpur" },
            { value: 'Jessore', label: "Jessore" },
            { value: 'Jhalokati', label: "Jhalokati" },
            { value: 'Jhenaidah', label: "Jhenaidah" },
            { value: 'Khagrachari', label: "Khagrachari" },
            { value: 'Khulna', label: "Khulna" },
            { value: 'Kishoreganj', label: "Kishoreganj" },
            { value: 'Kurigram', label: "Kurigram" },
            { value: 'Kushtia', label: "Kushtia" },
            { value: 'Lakshmipur', label: "Lakshmipur" },
            { value: 'Lalmonirhat', label: "Lalmonirhat" },
            { value: 'Madaripur', label: "Madaripur" },
            { value: 'Magura', label: "Magura" },
            { value: 'Manikganj', label: "Manikganj" },
            { value: 'Maulvibazar', label: "Maulvibazar" },
            { value: 'Meherpur', label: "Meherpur" },
            { value: 'Munshiganj', label: "Munshiganj" },
            { value: 'Mymensingh', label: "Mymensingh" },
            { value: 'Naogaon', label: "Naogaon" },
            { value: 'Narail', label: "Narail" },
            { value: 'Narayanganj', label: "Narayanganj" },
            { value: 'Narsingdi', label: "Narsingdi" },
            { value: 'Natore', label: "Natore" },
            { value: 'Nawabganj', label: "Nawabganj" },
            { value: 'Netrokona', label: "Netrokona" },
            { value: 'Nilphamari', label: "Nilphamari" },
            { value: 'Noakhali', label: "Noakhali" },
            { value: 'Pabna', label: "Pabna" },
            { value: 'Panchagarh', label: "Panchagarh" },
            { value: 'Patuakhali', label: "Patuakhali" },
            { value: 'Pirojpur', label: "Pirojpur" },
            { value: 'Rajbari', label: "Rajbari" },
            { value: 'Rajshahi', label: "Rajshahi" },
            { value: 'Rangamati', label: "Rangamati" },
            { value: 'Rangpur', label: "Rangpur" },
            { value: 'Satkhira', label: "Satkhira" },
            { value: 'Shariatpur', label: "Shariatpur" },
            { value: 'Sherpur', label: "Sherpur" },
            { value: 'Sirajganj', label: "Sirajganj" },
            { value: 'Sunamganj', label: "Sunamganj" },
            { value: 'Sylhet', label: "Sylhet" },
            { value: 'Tangail', label: "Tangail" },
            { value: 'Thakurgaon', label: "Thakurgaon" },
        ]
    })

    useEffect(() => {
        setLivingCountry({ ...livingCountry, options: handleOptionToData("Country") })
        setBirthCountry({ ...birthCountry, options: handleOptionToData("Country") })
        setOptionsData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (data) => {
        let obj = {
            birthCountry: birthCountry.value,
            stateDevision: data.stateDevision ? data.stateDevision : null,
            livingCountry: livingCountry.value,
            city: city.value ? city.value : null
        }
        setLoading(true)
        let response = await Requests.Profile.UpdateLocationOfGroom(obj, header)
        if (response?.status) {
            props.onHide()
            props.handleRefetch()
            toast.success('Successfully saved your infos')
        }
        else {
            props.onHide()
        }
    }

    if (!optionsData) return <Loading />

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Location Of Groom</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <p>Birth Country</p>
                            <SingleSelect
                                options={birthCountry.options}
                                data={props.birthCountry}
                                value={(data) => setBirthCountry({ ...birthCountry, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>State / Devision</p>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                defaultValue={props.stateDevision || ''}
                                {...register('stateDevision')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>Living Country</p>
                            <SingleSelect
                                options={livingCountry.options}
                                data={props.livingCountry}
                                value={(data) => setLivingCountry({ ...livingCountry, value: data })}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <p>City</p>
                            <SingleSelect
                                options={city.options}
                                data={props.city}
                                value={(data) => setCity({ ...city, value: data })}
                            />
                        </div>

                        <div className="text-right pt-3">
                            <button
                                type="submit"
                                className="btn shadow-none save-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save my infos'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default LocationOfGroomModal;