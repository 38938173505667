import React from 'react'
import { NavLink } from 'react-router-dom'
import './style.scss'
import { useSelector } from 'react-redux'

const Connection = () => {
    const count = useSelector((state) => state.notification)
    return (
        <div className="custom-navbar">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/connection/"
                            activeClassName="isActive"
                        >Accepted {count.acceptedProposal >0 ? <span>{count.acceptedProposal}</span> : null}</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/connection/send"
                            activeClassName="isActive"
                        >Send</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/connection/pending"
                            activeClassName="isActive"
                        >Pending {count.incomingProposal >0 ? <span>{count.incomingProposal}</span> : null}</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connection;