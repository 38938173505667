import React, { useState, useContext , useEffect, useCallback } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import '../style.scss';
import OtherModal from '../Modal/PartnerPreference/Other'
import { UserContext } from '../../../utils/context/userContext'

const Others = (props) => {
    const [modal, setModal] = useState({loading:false,show:false})
    const { user, handleRefetch } = useContext(UserContext)
    const [diet, setDiet] = useState(null)
    const [bloodGroup, setBloodGroup] = useState(null)
    const [healthInformation, setHealthInformation] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.partnerPreference) {
            setDiet(user.partnerPreference.diet)
            setBloodGroup(user.partnerPreference.bloodGroup)
            setHealthInformation(user.partnerPreference.healthInformation)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Others</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow">
                                <Icon icon={edit2} size={20} 
                                    onClick={()=>setModal({...modal,show:true})}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                            <thead>
                                <tr>
                                    <th>Profile created by</th>
                                    <td className="pl-5">:</td>
                                    <td>self</td>
                                </tr>
                                <tr>
                                    <th>Blood Group</th>
                                    <td className="pl-5">:</td>
                                    <td>{bloodGroup && bloodGroup.length ? bloodGroup[0] : 'Dosent matter'}</td>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                            <thead>
                                <tr>
                                    <th>Diet</th>
                                    <td className="pl-5">:</td>
                                    <td>{diet && diet.length ? diet[0] : 'Dosent matter'}</td>
                                </tr>
                                <tr>
                                    <th>Health Information</th>
                                    <td className="pl-5">:</td>
                                    <td>{healthInformation && healthInformation.length ? healthInformation[0] : 'Dosent matter'}</td>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                modal.show?
                <OtherModal 
                    show={modal.show}
                    loading={modal.loading}
                    diet ={diet}
                    bloodGroup={bloodGroup}
                    healthInformation={healthInformation}
                    handleRefetch={handleRefetch}
                    onHide={()=>setModal({...modal,show:false,loading:false})}
                />
                : null
            }
        </div>
    )
};

export default Others;