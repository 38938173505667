import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import userReducer from '../Slice/UserSlice'
import optionReducer from '../Slice/OptionSlice'
import chatMemberReducer from '../Slice/Chat/ChatMembersSlice'
import messageBoxStateReducer from '../Slice/Chat/MessageBoxStateSlice'
import MemberListReducer from '../Slice/Chat/ChatMemberListSlice'
import ArrivalMessageReducer from '../Slice/Chat/ArraivalMessageSlice'
import SingleChatMemberReducer from '../Slice/Chat/SingleChatMemberSlice'
import OnlineUsersReducer from '../Slice/Chat/OnlineUserSlice'
import RefatchReducer from '../Slice/RefatchSlice';
import NotificationReducer from '../Slice/NotificationSlice'

const reducers = combineReducers({
    user: userReducer,
    option: optionReducer,
    chatMembers: chatMemberReducer,
    messageBoxState: messageBoxStateReducer,
    membersList: MemberListReducer,
    arrivalMessage: ArrivalMessageReducer,
    singleChatMember: SingleChatMemberReducer,
    onlineusers: OnlineUsersReducer,
    refatch: RefatchReducer,
    notification: NotificationReducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['notification'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default store;
