import { createSlice } from '@reduxjs/toolkit'

export const ChatMemberSlice = createSlice({
    name: "chatMembers",
    initialState: { members:[], status: true },
    reducers: {
        memberSetter: (state, action) => {
            let isExists = state.members.find(one => one._id === action.payload._id)
            if(!isExists){
                let userObject ={
                    name: action.payload.name,
                    _id: action.payload._id,
                    profileId:action.payload.profileId,
                    birthCountry: action.payload.birthCountry,
                    age: action.payload.age,
                    city: action.payload.city,
                    workingWith: action.payload.workingWith,
                    images:action.payload.images
                }
                return{
                    ...state,
                    members : [userObject, ...state.members]
                }
            }
        },
        memberRemove: (state, action) => {
            return{
                ...state,
                members : state.members.filter(one => one._id !== action.payload)
            } 
        },
    },
});

export const { memberSetter, memberRemove } = ChatMemberSlice.actions

export default ChatMemberSlice.reducer;