import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { messageCircle, xSquare } from 'react-icons-kit/feather'
import { heart } from 'react-icons-kit/fa/heart'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Requests from "../../utils/Requests/Index"


const Index = ({ userId, userType, onDelete, item, setLoading, handleLike }) => {
    const { register, handleSubmit, reset } = useForm()
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [like, setLike] = useState(item.like)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    // Submit Form
    const onSubmit = async (data) => {
        if (userType !== "Paid") {
            history.push("/my-biyesadi/package")
        } else {
            setLoading(true)
            const response = await Requests.TimelinePost.UpdateComment(item.id, data, header)
            if (response.status) {
                setLoading(false)
                reset()
            }
        }
    }


    return (
        <div className="card shadow-sm post-card">
            <div className="card-header bg-white">
                <div>
                    <div className="d-flex">
                        <div className="img-container rounded-circle">
                            <img src={item.profilePicture} className="img-fluid" alt="..." />
                        </div>
                        <div className="pl-2 pt-2">
                            <Link to={userId !== item.userId ? `/single-profile/${item.userId}` : "/timeline"}>{item?.userName}</Link>
                        </div>
                    </div>
                    <div className="pl-5">
                        {item.content}
                    </div>
                </div>

                {
                    userId === item.userId ?
                        <div className="delete-btn">
                            <button
                                onClick={() => onDelete(item?.id)}
                                type="button"
                                className="btn shadow-none btn-block">
                                <Icon icon={xSquare} size={22} />
                            </button>
                        </div>
                        : null
                }


            </div>
            <div className="card-body p-0">
                {
                    item.image
                        ? <img src={item.image} className="img-fluid" alt="..." />
                        : null
                }
            </div>
            <div className="card-footer d-flex p-3">
                <div className="flex-fill pr-1">
                    <button
                        onClick={() => {
                            handleLike(item.id, `${like ? "unlike" : "like"}`)
                            setLike(!like)
                        }}
                        type="button"
                        className={`btn shadow-none btn-block ${like ? "active" : "inactive"}`}>
                        <Icon icon={heart} size={22} /><span>{item.numberOfLike}</span>
                    </button>
                </div>
                <div className="flex-fill pl-1">
                    <button
                        onClick={() => setShow(!show)}
                        type="button"
                        className="btn shadow-none btn-block">
                        <Icon icon={messageCircle} size={22} /><span>Comment</span>
                    </button>
                </div>
            </div>

            {
                show ?
                    <div className="comment-area card-body">

                        {
                            userId !== item.userId ?
                                <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
                                    <input
                                        type="text"
                                        className="comment-input shadow-none"
                                        placeholder="Enter your comment"
                                        {...register('comment', { required: true })}
                                    />
                                    <input className="comment-submit" type="submit" value="send" />
                                </form>
                                : null}

                        {
                            item?.comments?.map((comment, i) =>
                                <div key={i} className="comment-box">
                                    <div className="img-container rounded-circle">
                                        <img src={comment.profilePicture} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="d-flex flex-column mx-2">
                                        <div className="">
                                            <Link to={`/single-profile/${comment.userId}`}>{comment?.userName}</Link>
                                        </div>
                                        <div className="">
                                            {comment.comment}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    : null
            }

        </div>
    );
};

export default Index;