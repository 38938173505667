import React, { useEffect, useState } from 'react';
import FilterItems from '../../../components/Matches/Filter/Index'
// import Search from '../../../components/NavBar/Search';
import Profile from '../../../components/Profile/Index'
import { useLocation, useHistory } from "react-router-dom";
import Loading from '../../../components/Loading/Index'
import queryString from 'query-string'
// import Requests from '../../../utils/Requests/Index'
import { useContext } from 'react';
import { singleProContext } from '../../../utils/context/SingleProfileContext';
import { useQuery } from 'react-query';
// import { UserContext } from '../../../utils/context/userContext';
import { RefineContext } from '../../../utils/context/RefineSearchContext';
import { RefineSearchFunc } from '../../../utils/RefineSearch/RefineSearchFunc';
import Pagination from '../../../components/pagination/Index';
import { api } from '../../../utils/api';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Icon from 'react-icons-kit'
import { plus, minus } from 'react-icons-kit/feather'


const Index = () => {
    // const { user } = useContext(UserContext)
    const { handleLocalStoreIds } = useContext(singleProContext);
    const { refineSearchObj, setRefineSearchObj } = useContext(RefineContext)
    const [pageReload, setPageReload] = useState(true)
    const [refineReload, setRefineReload] = useState(false)
    const location = useLocation()
    const history = useHistory()
    const query = queryString.parse(location.search)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [totalPage, setTotalPage] = useState(null)
    const [queryParams, setQueryParams] = useState({
        page: query.page,
        limit: 20,
        age1: query.age1 || null,
        age2: query.age2 || null,
        height1: query.height1 || null,
        height2: query.height2 || null,
        religion: query.religion || null,
        livingCountry: query.livingCountry || null,
        materialStatus: query.materialStatus || null,
        motherTongue: query.motherTongue || null,
        livingState: query.livingState || null,
        birthCountry: query.birthCountry || null,
        qualification: query.qualification || null,
        workingWith: query.workingWith || null,
        professionArea: query.professionArea || null,
        annualIncome: query.annualIncome || null
    })
    const [RefineShow, setRefineShow] = useState(true);
    const handleRefineShowHide = () => {
        setRefineShow(!RefineShow)
    }
    useEffect(() => {
        if (window.innerWidth <= 992) {
            setRefineShow(false)
        }
    }, [window])


    // Fetch data
    const loadData = async () => {
        try {
            let response
            if (query.advance) {
                response = await Axios.get(`${api}search/advance?page=${query.page}&limit=${queryParams.limit}&age1=${queryParams.age1}&age2=${queryParams.age2}&height1=${queryParams.height1}&height2=${queryParams.height2}&religion=${queryParams.religion}&livingCountry=${queryParams.livingCountry}&materialStatus=${queryParams.materialStatus}&motherTongue=${queryParams.motherTongue}&livingState=${queryParams.livingState}&birthCountry=${queryParams.birthCountry}&qualification=${queryParams.qualification}&workingWith=${queryParams.workingWith}&professionArea=${queryParams.professionArea}&annualIncome=${queryParams.annualIncome}`, header)
            }
            else {
                response = await Axios.get(`${api}search/basic?page=${query.page}&limit=${queryParams.limit}&age1=${queryParams.age1}&age2=${queryParams.age2}&height1=${queryParams.height1}&height2=${queryParams.height2}&religion=${queryParams.religion}&materialStatus=${queryParams.materialStatus}&motherTongue=${queryParams.motherTongue}`, header)
            }
            if (response.status === 200) {
                setPageReload(false)
                setTotalPage(response.data.totalPage)
                return response.data.users;
            }
        } catch (error) {
            toast.warn(error.message)
        }
    }

    // react query;
    let users = useQuery('searchResults', loadData,
        {
            retry: 2,
            cacheTime: 600000,
            refetchOnWindowFocus: false,
            enabled: Boolean(pageReload)
        })

    // get refine search data
    useEffect(() => {
        setRefineSearchObj({
            "age": [],
            "height": [],
            "materialStatus": [],
            "professionArea": [],
            "qualification": [],
            "workingWith": [],
            "income": [],
            "language": [],
            "livingCountry": [],
            "recentlyJoined": [],
            "religion": [],
            "title": [],
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRefineReload(true);
    }, [refineSearchObj]) // eslint-disable-line react-hooks/exhaustive-deps

    const getRefineData = async () => {
        const response = await RefineSearchFunc(users.data, refineSearchObj)
        setRefineReload(false)
        return response;
    }
    const handleRefine = useQuery(`${query.advance ? "advanceRefineSearch" : "basicRefineSearch"}`, getRefineData,
        {
            retry: false,
            cacheTime: 600000,
            refetchOnWindowFocus: false,
            enabled: Boolean(refineReload)
        })

    // Set ids For Single Profile...
    useEffect(() => {
        if (users?.data || handleRefine?.data) {
            const userIds = handleRefine?.data ? handleRefine?.data?.map(info => info._id) : users?.data?.map(info => info._id);
            handleLocalStoreIds(userIds);
        }
    }, [users.data, handleRefine.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // change page no
    const changePage = page => {

        if (query.advance) {
            const {
                age1,
                age2,
                height1,
                height2,
                religion,
                livingCountry,
                materialStatus,
                motherTongue,
                livingState,
                birthCountry,
                qualification,
                workingWith,
                professionArea,
                annualIncome } = queryParams
            history.push(`/search/results?advance=${true}&page=${page}&limit=20&age1=${age1}&age2=${age2}&height1=${height1}&height2=${height2}&religion=${religion}&livingCountry=${livingCountry}&materialStatus=${materialStatus}&motherTongue=${motherTongue}&livingState=${livingState}&birthCountry=${birthCountry}&qualification=${qualification}&workingWith=${workingWith}&professionArea=${professionArea}&annualIncome=${annualIncome}`)
        }
        else {
            const {
                age1,
                age2,
                height1,
                height2,
                religion,
                materialStatus,
                motherTongue } = queryParams
            history.push(`/search/results?page=${page}&limit=20&gender=male&age1=${age1}&age2=${age2}&height1=${height1}&height2=${height2}&religion=${religion}&materialStatus=${materialStatus}&motherTongue=${motherTongue}`)
        }
        setPageReload(true)
    }

    if (users.isLoading || handleRefine.isLoading) return <Loading />;

    return (
        <div className="matches-results-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-11 col-xxl-9 m-auto px-2">
                        <div className="d-xl-flex">

                            {/* Filter container */}
                            <div className="filter-container mb-2">
                                <div onClick={handleRefineShowHide} className="d-flex header p-3">
                                    <div><h6>Refine search</h6></div>
                                    <div className="ml-auto" style={{ color: '#fff' }}>
                                        {RefineShow ? <Icon icon={minus} size={25} /> : <Icon icon={plus} size={25} />}
                                    </div>
                                </div>
                                {RefineShow ?
                                    <div className="body">
                                        <FilterItems />
                                    </div>
                                    : null}
                            </div>

                            {/* Result container */}
                            <div className="result-container">
                                {
                                    handleRefine?.data
                                        ?
                                        handleRefine?.data?.map((item, i) => <Profile key={i} item={item} />)
                                        :
                                        users?.data?.map((item, i) => <Profile key={i} item={item} />)
                                }
                                {/* pageination */}
                                <div className='onmenu-bar'>
                                    <Pagination totalpage={totalPage} changePage={changePage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;