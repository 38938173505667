import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { users } from 'react-icons-kit/feather'
import { chevronDown } from 'react-icons-kit/feather'
import ShortCard from '../ShortCard/Index'
import { useDispatch } from 'react-redux';
import { memberSetter } from '../../utils/Redux/Slice/Chat/ChatMembersSlice'
import { setTrue } from '../../utils/Redux/Slice/Chat/MessageBoxStateSlice'
import { seenMessage } from '../../utils/Redux/Slice/Chat/ArraivalMessageSlice'

const Index = ({ handleDialog, data }) => {
    const dispatch = useDispatch()
    
    const handleChatMembers = data =>{
        dispatch(memberSetter(data))
        dispatch(setTrue(data))
        dispatch(seenMessage(data._id))
    }

    return (
        <div className="d-none d-lg-block chat-dialog">
            <div className='header'>
                <p><Icon icon={users} size={14} /> online</p>
                <Icon onClick={handleDialog} className="remove" icon={chevronDown} size={14} />
            </div>
            <div className='dialog-body'>
                {data.map((one, i) => {
                    return (
                        <div key={i} onClick={()=> handleChatMembers(one)}>
                            <ShortCard one={ one }/>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default Index;