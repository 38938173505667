import React, { useState } from 'react'
import './style.scss'
import QuickItems from '../../../components/MyBiye/QuickItems/Index'
import Loading from '../../../components/Loading/Index'
import { useQuery } from 'react-query'
import Requests from '../../../utils/Requests/Index'
import { DayMonthYear } from '../../../utils/helpers'

const Index = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageLoad, setPageload] = useState(true)

    const getTransactionHistory = async () => {
        try {
            const data = await Requests.Package.TransactionHistory(header)
            setPageload(false)
            return data.history
        } catch (error) {
            setPageload(false)
        }
    }

    const { data, isLoading, isError } = useQuery("transactionHistory", getTransactionHistory, {
        retry: false,
        cacheTime: 2100000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    if (isLoading || isError) return <Loading />

    return (
        <div className="photo-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex">
                            <QuickItems />

                            <div className="flex-fill main-section px-lg-4 pb-4">
                                <div className="card">
                                    {/* Header */}
                                    <div className="card-header px-0 pb-3 pt-lg-0">
                                        <h5 className="mb-0">Payment History</h5>
                                    </div>
                                    <div className='card-body'>
                                        {
                                            data && data.length > 0 ?
                                                <table className="table table-responsive table-borderless">
                                                    <thead>
                                                        <tr className="border-bottom row d-table-row">
                                                            <td className="text-center col-1">SL</td>
                                                            <td className='col-3'>Transaction ID</td>
                                                            <td className='col-1'>Amount</td>
                                                            <td className='col-1'>Currency</td>
                                                            <td className='col-2'>Issuer</td>
                                                            <td className='col-2'>Start Date</td>
                                                            <td className='col-2'>End Date</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((one, i) =>
                                                            <tr className="border-bottom row d-table-row" key={i}>
                                                                <td className="text-center col-1">{i + 1}</td>
                                                                <td className='col-3'>{one.transactionId}</td>
                                                                <td className='col-1'>{one.amount}</td>
                                                                <td className='col-1'>{one.currency}</td>
                                                                <td className='col-2'>{one.issuer}</td>
                                                                <td className='col-2'>{one.transactionDate ? DayMonthYear(one.transactionDate) : 'N/A'}</td>
                                                                <td className='col-2'>{DayMonthYear(one.endDate)}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                : <p>no such payment history found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;