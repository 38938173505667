import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NavBar from '../../components/NavBar/MyBiyesadi'

import Dashboard from './Dashboard/Index'
import MyProfile from './MyProfile/Index'
import PersonalInterest from '../MyBiye/MyProfile/PersonalInterest/Index'
import MyPhoto from './MyPhoto/Index'
import PartnerPreference from './PartnerPreference/Index'
import Settings from './Settings/Index'
import ContactDetails from './ContactDetails/Index'
import Package from './Package/Index'
import PaymentLog from './PaymentLog/Index'
// import FourOFour from '../FourOfour/Index'

const Master = () => {
    return (
        <>
            <NavBar />
            <Switch>
                <Route exact path="/my-biyesadi/" component={Dashboard} />
                <Route exact path="/my-biyesadi/profile" component={MyProfile} />
                <Route exact path="/my-biyesadi/profile/personal-interest" component={PersonalInterest} />
                <Route exact path="/my-biyesadi/photos" component={MyPhoto} />
                <Route exact path="/my-biyesadi/partner-preference" component={PartnerPreference} />
                <Route exact path="/my-biyesadi/settings" component={Settings} />
                <Route exact path="/my-biyesadi/contact" component={ContactDetails} />
                <Route exact path="/my-biyesadi/package" component={Package} />
                <Route exact path="/my-biyesadi/paymentlog" component={PaymentLog} />
                {/* <Route path="*" component={FourOFour} /> */}
            </Switch>
        </>
    );
};

export default Master;