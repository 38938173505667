import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import Requests from '../../utils/Requests/Index'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { x } from 'react-icons-kit/feather'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Create = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [selectedFile, setSelectedFile] = useState({ value: null, preview: null })
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    // Handle image
    const handleImage = event => {
        const file = event.target.files[0]
        if (file) {
            return setSelectedFile({ value: file, preview: URL.createObjectURL(file) })
        }
    }

    const clearFile = () => {
        setSelectedFile({ ...selectedFile, value: null, preview: null })
    }

    // Submit Form
    const onSubmit = async (data) => {
        setLoading(true)

        const formData = new FormData()
        formData.append("content", data.content)
        formData.append("image", selectedFile.value)

        const response = await Requests.TimelinePost.Store(formData, header)
        if (response.status) {
            setLoading(false)
            toast.success(response.message)
            props.onHide()
        }
    }

    return (
        <div>
            <Modal
                size="md"
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-post-create-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">Create your own post</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn shadow-none rounded-circle"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-1 py-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-2">
                            <textarea
                                rows={5}
                                name="content"
                                placeholder="Share your emotion..."
                                className="form-control shadow-none border-0"
                                {...register('content')}
                            />
                        </div>

                        {selectedFile.preview ?
                            <div className="preview-box my-3">
                                <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                <button
                                    type="button"
                                    className="btn shadow rounded-circle"
                                    onClick={clearFile}
                                >
                                    <Icon icon={x} size={18} />
                                </button>
                            </div>
                            : null}

                        <div className="d-flex px-2 pt-2 pb-3">
                            <div className="file-uploader ml-auto">
                                <button type="button" className="btn shadow-none" disabled={isLoading}>Photo</button>
                                <input type="file" className="input-field" onChange={handleImage} />
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className={!errors.content ? "btn btn-primary text-white shadow-none ml-2" : "btn btn-muted shadow-none ml-2"}
                                    disabled={errors.content || isLoading}
                                >
                                    {isLoading ? 'Posting...' : 'Post'}
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Create;