import React, { useState, useContext } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import LocationOfGroomModal from './Modal/LocationOfGroom'
import { UserContext } from '../../utils/context/userContext';

const LocationOfGroom = (props) => {

    const [model, setModel] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const { birthCountry, livingCountry, stateDevision, city } = user

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Location Details</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setModel({ ...model, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Birth Country</th>
                                        <td className="pl-5">:</td>
                                        <td >{birthCountry ? birthCountry : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>State / Devision</th>
                                        <td className="pl-5">:</td>
                                        <td>{stateDevision ? stateDevision : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Living Country</th>
                                        <td className="pl-5">:</td>
                                        <td >{livingCountry ? livingCountry : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{city ? city : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {model.show ?
                <LocationOfGroomModal
                    show={model.show}
                    birthCountry={birthCountry}
                    livingCountry={livingCountry}
                    stateDevision={stateDevision}
                    city={city}
                    loading={model.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setModel({ ...model, show: false, loading: false })}
                />
                : null}
        </div>
    )
};

export default LocationOfGroom;