import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { commentsO } from 'react-icons-kit/fa'
import { useSelector } from 'react-redux'

const Index = ({ one }) => {
    const arrivalMessage = useSelector((state) => state.arrivalMessage.data)
    const onlineUsers = useSelector((state) => state.onlineusers.onlineUsers)
    let isExists = onlineUsers?.find(data => data.userID === one._id)

    const handleMessageNotification = () => arrivalMessage.filter(a => a.sender === one._id).length
    return (
        <div className={handleMessageNotification() > 0 ? 'conversation arrivalMsg' : 'conversation'}>
            <div className='d-flex'>
                <div className="chatOnlineImgContainer">
                    <img
                        src={one.images[0].name}
                        alt=""
                        className="conversationimage"
                    />
                    <div className={isExists ? "chatOnlineBadge" : "d-none"}></div>
                </div>
                <div className='infoContainer'>
                    <p className="name">{one.name ? one.name : 'Namecoming'}</p>
                    <p className="others">{one.age} years, height: {one.height}{one.bloodGroup ? `, BG: ${one.bloodGroup}` : null}</p>
                    <p className="others">{one.workingWith}</p>
                </div>
            </div>
            <div className='text-center'>
                <div style={{ fontSize: "10px" }}>{isExists ? "online" : "offline"}</div>
                <div className='notification-icon'>
                    <span className={handleMessageNotification() > 0 ? 'count' : 'd-none'}>{handleMessageNotification() > 0 ? handleMessageNotification() : null}</span>
                    <Icon icon={commentsO} size={20} />
                </div>
            </div>
        </div>
    )
}

export default Index;