import React, { useState, useEffect, useContext, useRef } from 'react'
import ProfileCard from '../../../components/Connection/ProfileCard'
import Loading from '../../../components/Loading/Index'
import Requests from '../../../utils/Requests/Index'
import { singleProContext } from "../../../utils/context/SingleProfileContext"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useQuery } from 'react-query';
import { io } from "socket.io-client";
import { useSelector, useDispatch } from 'react-redux'
import { stopRefatch } from '../../../utils/Redux/Slice/RefatchSlice'
import { seenIncomingProposal } from '../../../utils/Redux/Slice/NotificationSlice'

const Index = () => {
    const { handleLocalStoreIds } = useContext(singleProContext)
    const [isUpdate, setUpdate] = useState({ value: null, status: false })
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageload, setPageload] = useState(true)
    const socket = useRef()
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.user.userData)
    const doRefatch = useSelector(state => state.refatch.sideEffect)
    const count = useSelector((state) => state.notification)

    useEffect(() => {
        socket.current = io(`${process.env.REACT_APP_API_URL}`, { transports: ["websocket", 'polling'] })
    }, [])

    // Fetch data
    const fetchData = async () => {
        try {
            const response = await Requests.Connection.Pending(header)
            if (response && response.users) {
                setPageload(false)
                dispatch(stopRefatch())
                return response.users
            }
        } catch (error) {
            setPageload(false)
        }
    }
    const { data, isLoading } = useQuery("pending", fetchData, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageload)
    })

    useEffect(() => {
        if (window.location.pathname === '/connection/pending' && count.incomingProposal > 0) {
            dispatch(seenIncomingProposal())
        }
    }, [dispatch, count])

    useEffect(() => {
        if (doRefatch) {
            setPageload(true)
        }
    }, [doRefatch]);

    useEffect(() => {
        // Set ids For Single Profile...
        const userIds = data?.map(info => info._id);
        handleLocalStoreIds(userIds);
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    // Update request type
    const Updatetype = async data => {
        try {
            setUpdate({ value: data, status: true })
            const response = await Requests.Connection.UpdateRequestType(data, "accept", header)
            if (response && response.status) {
                toast.success(response.message)
                setPageload(true)
                socket.current.emit("acceptProposal", {
                    senderId: userInfo.userID,
                    name: userInfo.name,
                    receiverId: data
                })
            }
            setUpdate({ value: null, status: false })
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <Loading />

    return (
        <div className="connection-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 text-center text-sm-left px-sm-4 mb-2">
                        <h6 className="mb-0">{data.length} proposal pending</h6>
                        <small className="text-muted"><i>Peoples who want to connect with you.</i></small>
                    </div>

                    <div className="col-12">
                        {data && data.map((item, i) =>
                            <ProfileCard
                                key={i}
                                item={item}
                                buttonTitle="Accept"
                                changetype={Updatetype}
                                loading={isUpdate}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;