import React from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { ic_clear } from 'react-icons-kit/md'
import { phoneOutgoing } from 'react-icons-kit/feather';
import { chatbubbleWorking } from 'react-icons-kit/ionicons';

const ContactNumber = (props) => {

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="custom-settings-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mt-2 mb-0">Contact Details.</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <div className="flex-column flex-center">

                        <div className="d-flex justify-content-between align-items-center">
                            {
                                props.numberType === 'call'
                                    ? <>
                                        <a href={`tel:${props?.number}`}>
                                            <button
                                                type="button"
                                                className="contact_btn call"
                                            >
                                                <Icon icon={phoneOutgoing} size={14} /> Call
                                            </button>
                                        </a>
                                        <p className="pt-3 pl-3 font-weight-bold text-muted"> {props.number} </p>
                                    </>
                                    : <>
                                        <button
                                            type="button"
                                            className="contact_btn whatsapp"
                                        >
                                            <Icon icon={chatbubbleWorking} size={16} /> WhatsApp
                                        </button>
                                        <p className="pt-3 pl-3 font-weight-bold text-muted"> {props.number} </p>
                                    </>
                            }

                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ContactNumber;