import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        userData: {},
    },
    reducers: {
        userSetter: (state, action) => {
            state.userObject = action.payload
        },
        dashboardDataSetter: (state, action) => {
            let data ={
                profileType: action.payload.profileType,
                profilePicture: action.payload.image,
                userID: action.payload.userID,
                name: action.payload.name
            }
            return {
                ...state,
                userData: data
            }
        }
    },
});

export const { userSetter, dashboardDataSetter } = UserSlice.actions

export default UserSlice.reducer;