import React from 'react';
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import SingleProfile from "./SingleProfile/Index"
// import FourOFour from '../FourOfour/Index'

const Master = () => {

    return (
        <main id="single_profile">

            <Switch>

                <Route exact path="/single-profile/:id" component={SingleProfile} />

                {/* <Route path="*" component={FourOFour} /> */}

            </Switch>

        </main>
    );
};

export default Master;