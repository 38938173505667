import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import Requests from '../../utils/Requests/Index'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { x } from 'react-icons-kit/feather'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Delete = (props) => {
    const { handleSubmit, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })


    // Submit Form
    const onSubmit = async () => {
        setLoading(true)

        const response = await Requests.TimelinePost.Delete(props.deleteId, header)
        if (response.status) {
            setLoading(false)
            toast.success(response.message)
            props.onHide()
        }
    }

    return (
        <div>
            <Modal
                size="md"
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-post-delete-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">Delete your own post</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn shadow-none rounded-circle"
                                onClick={props.onHide}
                                disabled={isLoading}
                            >
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-1 py-0">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mt-5 mb-1">
                            <button
                                type="submit"
                                className={!errors.content ? "btn btn-danger text-white shadow-none ml-2" : "btn btn-muted shadow-none ml-2"}
                                disabled={errors.content || isLoading}
                            >
                                {isLoading ? 'Confirm Delete...' : 'Confirm Delete'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Delete;