import React, { useState } from 'react'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux';
import Icon from 'react-icons-kit'
import { close } from 'react-icons-kit/fa'
import { memberRemove } from '../../utils/Redux/Slice/Chat/ChatMembersSlice'
import { setTrue, hidden } from '../../utils/Redux/Slice/Chat/MessageBoxStateSlice'

const Index = () => {
    const chatMembers = useSelector((state) => state.chatMembers.members)
    const chatNumber= chatMembers.length -3
    const dispatch = useDispatch()
    const [hide, setHide] = useState(false)

    const handleMember = data => {
        dispatch(memberRemove(data))
        dispatch(hidden())
    }

    const handleMessageBox = data =>{
        dispatch(setTrue(data))
        if(hide){
            setHide(false)
        }
    }

    const handleHiddenMembers = e =>{
        e.preventDefault()
        setHide(true)
        dispatch(hidden())
    }

    return (
        <div className="d-none d-lg-block chatbar">
            <div className='childs'>
                {chatMembers.map((one, i) => {
                    return i < 3 ? (
                        <div key={i} className="single">
                            <div onClick={()=>handleMessageBox(one)} className='member-name'>{one.name ? one.name : 'Namecoming'}</div>
                            <Icon className="remove" icon={close} onClick={() => handleMember(one._id)} size={14} />
                        </div>
                    )
                        : null
                })}
            </div>
            <div className={`${chatNumber > 0 ? "chatNumber" : "d-none"}`} onClick={handleHiddenMembers}>
                {chatNumber}
            </div>
            {
                hide ?
                    <div className={`${chatNumber > 0 ? "extraMembers" : "d-none"}`}>
                        {
                            chatMembers.map((one, i) => {
                                return i >= 3 ? (
                                    <div key={i} className="single" >
                                        <div onClick={()=>handleMessageBox(one)} className='member-name'>{one.name ? one.name : 'Namecoming'}</div>
                                        <Icon className="remove" icon={close} onClick={() => handleMember(one._id)} size={14} />
                                    </div>
                                )
                                    : null
                            })
                        }
                    </div>
                    : null
            }
        </div>
    )
}

export default Index;