import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// All match profiles
const Single_Profile = async (id, header) => {
    try {
        const response = await Axios.get(`${api}user/show/${id}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const MyProfile = {
    Single_Profile
}

export default MyProfile