import React from 'react'
import { NavLink } from 'react-router-dom'
import './style.scss'

const Matches = () => {
    return (
        <div className="custom-navbar">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <NavLink
                        exact
                        type="button"
                        className="btn shadow-sm"
                        to="/matches/"
                        activeClassName="isActive"
                    >new matches</NavLink>
                    <NavLink
                        exact
                        type="button"
                        className="btn shadow-sm"
                        to="/matches/today"
                        activeClassName="isActive"
                    >today matches</NavLink>
                    <NavLink
                        exact
                        type="button"
                        className="btn shadow-sm"
                        to="/matches/all"
                        activeClassName="isActive"
                    >all matches</NavLink>
                    <NavLink
                        exact
                        type="button"
                        className="btn shadow-sm"
                        to="/matches/near-me"
                        activeClassName="isActive"
                    >near me</NavLink>
                    <NavLink
                        exact
                        type="button"
                        className="btn shadow-sm"
                        to="/matches/recently-viewed"
                        activeClassName="isActive"
                    >recently viewed</NavLink>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Matches;