import React, { useState, useContext } from 'react';
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import './style.scss';
import BasicLifeStyleModal from './Modal/BasicLifeStyle'
import { AgeCalculate, HeightCalculate } from '../../utils/helpers'
import { UserContext } from '../../utils/context/userContext';

const BasicLifeStyle = (props) => {
    const { user, handleRefetch } = useContext(UserContext)
    const { age, materialStatus, height,
        bodyWeight, diet, bloodGroup,
        healthInformation, disability
    } = user.basicAndLifestyleInformation

    const [basicLifeStyle, setBasicLifeStyle] = useState({ show: false, loading: false })
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Basic & Lifestyle</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setBasicLifeStyle({ ...basicLifeStyle, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <td className="pl-5">:</td>
                                        <td >{props.profileId ? props.profileId : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td className="pl-5">:</td>
                                        <td >{props.name ? props.name : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Material Status</th>
                                        <td className="pl-5">:</td>
                                        <td>{materialStatus ? materialStatus : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Diet</th>
                                        <td className="pl-5">:</td>
                                        {diet ? diet.map((one,id) => <td id={id}>{one}</td>) : "N/A"}
                                    </tr>
                                    <tr>
                                        <th>Age & Height</th>
                                        <td className="pl-5">:</td>
                                        <td>{age ? AgeCalculate(age) : "N/A"} & {height ? HeightCalculate(height) : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Body Weight</th>
                                        <td className="pl-5">:</td>
                                        <td>{bodyWeight ? `${bodyWeight} kg` : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Blood Group</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{bloodGroup ? bloodGroup : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Health Information</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{healthInformation ? healthInformation : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Disability</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{disability ? disability : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {basicLifeStyle.show ?
                <BasicLifeStyleModal
                    show={basicLifeStyle.show}
                    data={user.basicAndLifestyleInformation}
                    name={props.name}
                    loading={basicLifeStyle.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setBasicLifeStyle({ ...basicLifeStyle, show: false, loading: false })}
                />
                : null}
        </div>
    )
};

export default BasicLifeStyle;