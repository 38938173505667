import React, { useContext, useEffect, useState } from 'react';
import "./style.scss"
import { Link } from "react-router-dom";
// icons 
import aboutIcon from "../../../assets/static/single-profile/Icon awesome-info-circle.svg"
import contactIcon from "../../../assets/static/single-profile/Icon feather-phone-call.svg"
import phoneIcon from "../../../assets/static/single-profile/Icon material-call.svg"
import emailIcon from "../../../assets/static/single-profile/Icon ionic-ios-mail.svg"
import lifeStyleIcon from "../../../assets/static/single-profile/Group 3066.svg"
import backgroundIcon from "../../../assets/static/single-profile/Group 3067.svg"
import familyIcon from "../../../assets/static/single-profile/Group 3070.svg"
import educationIcon from "../../../assets/static/single-profile/Group 3089.svg"

import { UserContext } from '../../../utils/context/userContext';
import ContactInfo from '../Modal/ContactInfo';
import Requests from "../../../utils/Requests/Index"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Index = ({ data }) => {
    const [contactModel, setContactModel] = useState(false);
    const [contactShow, setContactShow] = useState(false);
    const [viewConfirmModal, setViewConfirmModal] = useState(false);
    const { user, handleRefetch } = useContext(UserContext);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const updateContactViewed = async (userId) => {
        try {
            // This if else for UX;
            if (user.packageInfo.proposal.contactViewLimit <= 0) {
                toast.warning("Your contact viewed limit finished!");
                return;
            } else {
                setContactShow(true);
            }

            const response = await Requests.Profile.UpdateContactViewed(userId, header)
            if (response?.status) {
                toast.success(response.message);
                handleRefetch()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (user?.profileType === "Paid" && user?.packageInfo?.contactViewed?.indexOf(data._id) > -1) {
            setContactShow(true);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="profile_details">
            <div className="info_box">
                <h5 className="details_title">
                    <img src={aboutIcon} alt="about icon" />  About
                </h5>
                <p className="text_area"> {data?.about} </p>
            </div>
            <br />
            <div className="info_box">
                <h5 className="details_title">
                    <img src={contactIcon} alt="icon" /> Contact Details
                    {/* {
                        !contactShow && <button
                            onClick={() => {
                                user?.profileType === "Paid"
                                    ? updateContactViewed(data?._id)
                                    : setContactModel(true)
                            }}
                            className="show_btn "> Show </button>
                    } */}
                </h5>
                {
                    contactShow ?
                        <div className="contact_box">
                            <div className="d-flex flex-wrap flex-md-row flex-sm-column justify-content-around overflow-hidden">
                                <div className="d-flex  flex-row flex-md-column">
                                    <span> <img src={phoneIcon} alt="" /> Contact Number: </span>
                                    <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                                </div>
                                <div className="d-flex  flex-row flex-md-column">
                                    <span className=""> <img src={emailIcon} alt="" /> Email ID:</span>
                                    <a href={`mailto:${data?.email}`}>{data?.email}</a>
                                </div>
                            </div>

                            <span
                                onClick={() => {
                                    user?.profileType === "Paid"
                                        ? updateContactViewed(data?._id)
                                        : setContactModel(true)
                                }}
                                className="upgrade" >
                                Show
                            </span>

                        </div>
                        :
                        user?.profileType === "Paid" ?

                            <div className="contact_box">
                                <div className="d-flex flex-wrap flex-md-row flex-sm-column justify-content-around">
                                    <div className="d-flex  flex-row flex-md-column">
                                        <span className="mr-1"> <img src={phoneIcon} alt="" /> Contact Number:</span>
                                        <span>+8801XXX-XXXXXX</span>
                                    </div>

                                    <div className="d-flex flex-row flex-md-column ">
                                        <span className="mr-1"> <img src={emailIcon} alt="" /> Email ID:</span>
                                        <span
                                            onClick={() => {
                                                user?.profileType === "Paid"
                                                    ? updateContactViewed(data?._id)
                                                    : setContactModel(true)
                                            }} >XXXXX@gmail.com</span>
                                    </div>
                                </div>

                                <span
                                    onClick={() => {
                                        user?.profileType === "Paid"
                                            ? updateContactViewed(data?._id)
                                            : setContactModel(true)
                                    }}
                                    className="upgrade" >
                                    Show
                                </span>

                            </div>
                            :
                            <div className="contact_box">
                                <div className="d-flex flex-wrap flex-md-row flex-sm-column justify-content-around">
                                    <div className="d-flex  flex-row flex-md-column">
                                        <span className="mr-1"> <img src={phoneIcon} alt="" /> Contact Number : </span>
                                        <span>+8801XXX-XXXXXX</span>
                                    </div>

                                    <div className="d-flex flex-row flex-md-column ">
                                        <span className="mr-1"> <img src={emailIcon} className="mr-1" alt="" /> Email : </span>
                                        <span>XXXXX@gmail.com</span>
                                    </div>
                                </div>

                                <Link to="/my-biyesadi/package" className="upgrade" >
                                    Upgrade Now To View
                                </Link>
                            </div>

                }

            </div>
            <br />
            <div className="info_box">
                <h5 className="details_title">
                    <img src={lifeStyleIcon} alt="icon" /> Lifestyle
                </h5>
                <p className="text_area">{data?.lifeStyle}</p>
            </div>
            <br />
            <div className="info_box">
                <h5 className="details_title">
                    <img src={backgroundIcon} alt="icon" /> Background
                </h5>
                <p className="text_area">
                    {data?.religion || data?.language ? <>
                        {data?.religion}, {data?.language || "Bengali"}
                        <br />
                    </> : ""}

                    {data?.socialOrder ? <>
                        {data?.socialOrder},
                        <br />
                    </> : ""}

                    Present And Permanent Address: {`Lives in ${data?.city + "," || ""} ${data?.stateDevision + "," || ""} ${data?.livingCountry || "Bangladesh."}.`}

                </p>
            </div>
            <br />
            <div className="info_box">
                <h5 className="details_title">
                    <img src={familyIcon} alt="icon" /> Family Details
                </h5>
                <p className="text_area">

                    <b>Family Location:</b> {data.familyLocation || "---"}
                    <br />
                    <b>Family Native Location:</b> {data.nativePlace || "---"}
                    <br />
                    <b>Family Type:</b> {data.familyType || "---"}
                    <br />
                    <b>Family Affluence:</b> {data.familyAffluence || "---"}
                    <br />
                    <b>Family Value:</b> {data.familyValue || "---"}
                    {/* <br />
                    <b>Grow Up In:</b> {} */}
                    <br />
                    <b>Father's Name:</b> {data.fatherName || "---"}
                    <br />
                    <b>Siblings:</b> {data.yourOrder || "---"}
                    <br />
                    <b>Father's Occupation:</b> {data.fatherOccupation || "---"}
                    <br />
                    <b>Mother's Name:</b> {data.motherName || "---"}
                    <br />
                    <b>Mother's Occupation:</b> {data.motherOccupation || "---"}
                    <br />
                    <b>Your Order As Child:</b> {data.yourOrder || "---"}
                    <br />
                    <b>No Of Brother:</b> {data.numberOfBrothers || "---"}
                    <br />
                    <b>Married Brother:</b> {data.numberOfBrothersMarried || "---"}
                    <br />
                    <b>No Of Sister:</b> {data.numberOfSisters || "---"}
                    <br />
                    <b>Married Sister:</b> {data.numberOfSistersMarried || "---"}
                    <br />
                </p>
            </div>
            <br />
            <div className="info_box">
                <h5 className="details_title">
                    <img src={educationIcon} alt="icon" /> Education & Carrier
                </h5>
                <p className="text_area">

                    <b>Qualification:</b> {data.qualification || "---"}
                    <br />
                    <b>College(S) Attended:</b> {data.institute || "---"}
                    <br />
                    <b>Working With:</b> {data.workingWith || "---"}
                    <br />
                    <b>Profession Area: </b> {data.professionArea || "---"}
                    <br />
                    <b>Monthly Income:</b> {data.annualIncome || "---"} BDT
                </p>
            </div>

            {contactModel ?
                <ContactInfo
                    show={contactModel}
                    onHide={() => setContactModel(false)}
                />
                : null}

                
            {/* Modal for paid accounts. But, not contactViewed. */}
            {/* {
                viewConfirmModal ?
                    <ContactViewConfirm
                        show={viewConfirmModal}
                        onHide={() => setViewConfirmModal(false)}
                        handleViewConfirm={() => handleViewConfirm(item?._id)}
                    />
                    : null
            } */}

        </div>
    );
};

export default Index;