import React from 'react'
import { NavLink } from 'react-router-dom'
import './style.scss'

const MyBiyesadi = () => {
    return (
        <div className="custom-navbar">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/"
                            activeClassName="isActive"
                        >Dashboard</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/profile"
                            activeClassName="isActive"
                        >profile</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/photos"
                            activeClassName="isActive"
                        >photo</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/partner-preference"
                            activeClassName="isActive"
                        >partner preference</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/settings"
                            activeClassName="isActive"
                        >settings</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/contact"
                            activeClassName="isActive"
                        >contact details</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/package"
                            activeClassName="isActive"
                        >packages</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/my-biyesadi/paymentlog"
                            activeClassName="isActive"
                        >payment log</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyBiyesadi;