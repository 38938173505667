import Dashboard from './Dashboard'
import Profile from './Profile'
import Matches from './Matches'
import Connection from './Connection'
import Search from './Search'
import MyPhoto from './MyPhoto'
import Settings from './Settings'
import Package from './Package'
import MyProfile from "./MyProfile"
import Auth from './Auth'
import Message from './Message'
import TimelinePost from "./TimelinePost"

const Requests = {
    Dashboard,
    Profile,
    Matches,
    Connection,
    Search,
    MyPhoto,
    Settings,
    Package,
    MyProfile,
    Auth,
    Message,
    TimelinePost,
}

export default Requests
