import React, { useState, useContext, useEffect, useCallback } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import '../style.scss'
import BasicLifeStyleModal from '../Modal/PartnerPreference/BasicInformations'
import { UserContext } from '../../../utils/context/userContext'
import { HeightCalculate } from '../../../utils/helpers'

const BasicInformations = (props) => {

    const [basicLifeStyle, setBasicLifeStyle] = useState({ show: false, loading: false })
    const { user, handleRefetch } = useContext(UserContext)
    const [ageRange, setAgeRange] = useState(null)
    const [heightRange, setHeightRange] = useState(null)
    const [materialStatus, setMaterialStatus] = useState(null)
    const [religion, setReligion] = useState(null)
    const [motherTongue, setMotherTongue] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.partnerPreference) {
            setAgeRange(user.partnerPreference.ageRange)
            setHeightRange(user.partnerPreference.heightRange)
            setMaterialStatus(user.partnerPreference.materialStatus)
            setReligion(user.partnerPreference.religion)
            setMotherTongue(user.partnerPreference.motherTongue)
        }
    },[user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Basic Informations</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setBasicLifeStyle({ ...basicLifeStyle, show: true })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body px-lg-5">
                    <div className="row" >
                        <div className=" col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Age</th>
                                        <td className="pl-5">:</td>
                                        <td >{ageRange && ageRange.startFrom !== null ? `${ageRange.startFrom} - ${ageRange.endTo} years` : " N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Height</th>
                                        <td className="pl-5">:</td>
                                        <td>{heightRange && heightRange.startFrom !== null ? `${HeightCalculate(heightRange.startFrom)} ft - ${HeightCalculate(heightRange.endTo)} ft` : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Religion / Community</th>
                                        <td className="pl-5">:</td>
                                        <td>{religion && religion.length ? religion : " N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Mother Toungh</th>
                                        <td className="pl-5">:</td>
                                        <td >{motherTongue ? motherTongue : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Material Status</th>
                                        <td className="pl-md-5">:</td>
                                        <td>{materialStatus && materialStatus.length ? materialStatus : "N/A"}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            {basicLifeStyle.show ?
                <BasicLifeStyleModal
                    show={basicLifeStyle.show}
                    ageStart={ageRange ? ageRange.startFrom : null}
                    ageEnd={ageRange ? ageRange.endTo : null}
                    heightStartFrom={heightRange ? heightRange.startFrom : null}
                    heightEndTo={heightRange ? heightRange.endTo : null}
                    materialStatus={materialStatus}
                    religion={religion}
                    motherTongue={motherTongue}
                    loading={basicLifeStyle.loading}
                    handleRefetch={handleRefetch}
                    onHide={() => setBasicLifeStyle({ ...basicLifeStyle, show: false, loading: false })}
                />
                : null}
        </div>
    )
};

export default BasicInformations;