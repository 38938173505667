import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

const Index = async (header) =>{
    try {
        const response = await Axios.get(`${api}package/index`, header)
        if (response.status === 200) {
            return response.data.packages
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const SurjoRequest = async (data, header) =>{
    try {
        const response = await Axios.post(`${api}payment/checkout-request`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const TransactionHistory = async (header) =>{
    try {
        const response = await Axios.get(`${api}transactionhistory`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const Package = {
    Index,
    SurjoRequest,
    TransactionHistory
}

export default Package
