import Axios from 'axios'
import { errorHandeller } from './Error'

const api = `${process.env.REACT_APP_API_URL}/api/v1/user/`

const Logout = async (header) => {
    try {
        await Axios.put(`${api}logout`, {}, header)

    } catch (err) {
        if (err) return errorHandeller(err);
    }
}

const DeleteProfileViewNotification = async(header) =>{
    try {
        const res = await Axios.get(`${api}removeprofileviewnotification`, header)
        return res.data
    } catch (err) {
        if (err) return errorHandeller(err)
    }
}
const Auth = {
    Logout,
    DeleteProfileViewNotification
}

export default Auth;