import React from 'react'
import { NavLink } from 'react-router-dom'
import './style.scss'

const Search = () => {
    return (
        <div className="custom-navbar">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/search/"
                            activeClassName="isActive"
                        >basic</NavLink>
                        <NavLink
                            exact
                            type="button"
                            className="btn shadow-sm"
                            to="/search/advance"
                            activeClassName="isActive"
                        >advance</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;