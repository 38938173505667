import Axios from 'axios'
import { api } from '../api'
import { errorHandeller } from './Error'

import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

// change user password
const UpdatePassword = async (data, header) => {
    try {
        const response = await Axios.put(`${api}updatepassword`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const SendOtp = async (header) => {
    try {
        const response = await Axios.get(`${api}sendotptophone`, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const SendOtpToEmail = async (header) => {
    try {
        const response = await Axios.get(`${api}sendotptoemail`, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const VerifyOtp = async (data, header) => {
    try {
        const response = await Axios.put(`${api}verifyotpcode`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const ChangePassword = async (data, header) => {
    try {
        const response = await Axios.put(`${api}changepassword`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Settings = {
    UpdatePassword,
    SendOtp,
    SendOtpToEmail,
    VerifyOtp,
    ChangePassword,
}

export default Settings