// const {
//     nYearOldsYear,
//     dateToYear,
// } = require("../helpers")

// Refine Search Function
export const RefineSearchFunc = async (users, search) => {      // , currentUser

    // checking users value;
    if (users === undefined || users == null) {
        return null;
    }

    let results = users;

    // Filtering Matches... All & 2-Way -> (still not working!)
    if (search.matches) {
        //     if (search.matches === 'all') {
        //         // Filtering CurrentUser partnerPreference && For All Matches...
        //         let myResults = results?.filter(
        //             item => item.language === currentUser.partnerPreference.motherTongue
        //                 && item.religion === currentUser.partnerPreference.religion[0]
        //                 && item.livingCountry === currentUser.partnerPreference.location.country[0]
        //                 && item?.materialStatus === currentUser?.partnerPreference?.materialStatus[0]
        //                 && parseInt(currentUser.partnerPreference.heightRange.startFrom || 0) <= parseInt(item?.height || 0) && parseInt(item?.height || 0) <= parseInt(currentUser.partnerPreference.heightRange.endTo || 0)

        //                 && parseInt(item?.age) >= parseInt(currentUser.partnerPreference.ageRange.startFrom) && parseInt(item?.age) <= parseInt(currentUser.partnerPreference.ageRange.endTo)


        //             // && parseInt(currentUser.partnerPreference.annualIncome.startFrom) == parseInt(item.annualIncome.startFrom) && parseInt(currentUser.partnerPreference.annualIncome.endTo) == parseInt(item.annualIncome.endTo)

        //         );
        //         results = myResults;
        //     }
        //     else if (search.matches === "2-way") {
        //         // Filtering CurrentUser partnerPreference && Users for 2-way matches...
        //         let myResults = results?.filter(
        //             item => item.language.motherTongue === currentUser.partnerPreference.motherTongue
        //                 && currentUser?.language?.motherTongue === item?.partnerPreference?.motherTongue

        //                 && item.religion === currentUser.partnerPreference.religion[0]
        //                 && currentUser.religion === item?.partnerPreference?.religion[0]

        //                 && item?.livingCountry === currentUser?.partnerPreference?.location?.country[0]
        //                 && currentUser?.livingCountry === item?.partnerPreference?.location?.country[0]

        //                 && item?.basicAndLifestyleInformation?.materialStatus === currentUser?.partnerPreference?.materialStatus[0]
        //                 && currentUser?.basicAndLifestyleInformation?.materialStatus === item?.partnerPreference?.materialStatus[0]

        //                 && parseInt(currentUser.partnerPreference.heightRange.startFrom || 0) <= parseInt(item?.basicAndLifestyleInformation?.height || 0) && parseInt(item?.basicAndLifestyleInformation?.height || 0) <= parseInt(currentUser.partnerPreference.heightRange.endTo || 0)
        //                 && parseInt(item.partnerPreference.heightRange.startFrom || 0) <= parseInt(currentUser?.basicAndLifestyleInformation?.height || 0) && parseInt(currentUser?.basicAndLifestyleInformation?.height || 0) <= parseInt(item.partnerPreference.heightRange.endTo || 0)

        //                 && dateToYear(new Date(item.dob)) <= nYearOldsYear(currentUser.partnerPreference.ageRange.startFrom) && dateToYear(new Date(item.dob)) >= nYearOldsYear(currentUser.partnerPreference.ageRange.endTo)
        //                 && dateToYear(new Date(currentUser.dob)) <= nYearOldsYear(item.partnerPreference.ageRange.startFrom) && dateToYear(new Date(currentUser.dob)) >= nYearOldsYear(item.partnerPreference.ageRange.endTo)


        //             // && parseInt(currentUser.partnerPreference.annualIncome.startFrom) == parseInt(item.annualIncome.startFrom) && parseInt(currentUser.partnerPreference.annualIncome.endTo) == parseInt(item.annualIncome.endTo)
        //             // && parseInt(item.partnerPreference.annualIncome.startFrom) == parseInt(currentUser.annualIncome.startFrom) && parseInt(item.partnerPreference.annualIncome.endTo) == parseInt(currentUser.annualIncome.endTo)
        //         );

        //         results = myResults;
        //     }
    }

    // Filtering Recently Joined...
    if (search.recentlyJoined.length > 0) {
        let nowResults = [];
        for (let k = 0; k < search.recentlyJoined.length; k++) {
            const element = search.recentlyJoined[k];

            if (element === 'today') {
                let myResults = results.filter(item => Math.floor((((new Date() - new Date(item.createdAt)) / 1000) / 60) / 60) <= 24);
                nowResults = [...nowResults, ...myResults];
            } else if (element === 'week') {
                let myResults = results.filter(item => Math.floor((((new Date() - new Date(item.createdAt)) / 1000) / 60) / 60) <= 168);
                nowResults = [...nowResults, ...myResults];
            } else if (element === 'month') {
                let myResults = results.filter(item => Math.floor((((new Date() - new Date(item.createdAt)) / 1000) / 60) / 60) <= 720);
                nowResults = [...nowResults, ...myResults];
            } else {
                nowResults = [...results]
            }

        }

        results = nowResults;
    }


    // Filtering Language MotherTongue
    if (search.language.length > 0) {
        let nowResults = [];
        for (let k = 0; k < search.language.length; k++) {
            const element = search.language[k];
            let myResults = results?.filter(item => item.language?.toLowerCase() === element?.toLowerCase());
            nowResults = [...nowResults, ...myResults];
        }
        results = nowResults;
    }

    // Filtering Religion  
    if (search.religion.length > 0) {
        let nowResults = [];
        for (let k = 0; k < search.religion.length; k++) {
            const element = search.religion[k];
            let myResults = results?.filter(item => item.religion?.toLowerCase() === element?.toLowerCase());
            nowResults = [...nowResults, ...myResults];
        }
        results = nowResults;
    }

    // Filtering title  
    if (search.title.length > 0) {
        let nowResults = [];
        for (let k = 0; k < search.title.length; k++) {
            const element = search.title[k];
            let myResults = results?.filter(item => item?.title?.toLowerCase() === element?.toLowerCase());
            nowResults = [...nowResults, ...myResults];
        }
        results = nowResults;
    }

    //Filtering Ages
    if (search.age.length > 0) {
        let myResults = []
        for (let i = 0; i < search.age.length; i++) {
            let ageSplit = search.age[i].split("-");
            let age1 = parseInt(ageSplit[0])
            let age2 = parseInt(ageSplit[1])
            let nowResults = results.filter(item => parseInt(item?.age) >= age1 && parseInt(item?.age) <= age2);
            myResults = [...myResults, ...nowResults]
        }
        results = myResults;
    }

    // Filtering Heights
    if (search.height.length > 0) {
        let myResults = [];
        for (let i = 0; i < search.height.length; i++) {
            const heightSplit = search.height[i].split("-");
            const height1 = parseInt(heightSplit[0]);
            const height2 = parseInt(heightSplit[1]);

            let nowResults = results.filter(item => height1 <= parseInt(item?.height) && parseInt(item?.height) <= height2);
            myResults = [...myResults, ...nowResults]
        }
        results = myResults;
    }

    //Filtering MaterialStatus
    if (search.materialStatus.length > 0) {
        let myResults = []
        for (let i = 0; i < search.materialStatus.length; i++) {
            let nowResults = results.filter(item => item?.materialStatus?.toLowerCase() === search.materialStatus[i]?.toLowerCase());
            myResults = [...myResults, ...nowResults];
        }
        results = myResults
    }

    // Filtering livingCountry
    if (search.livingCountry.length > 0) {
        let nowResults = [];
        for (let k = 0; k < search.livingCountry.length; k++) {
            const element = search.livingCountry[k];
            let myResults = results?.filter(item => item?.livingCountry?.toLowerCase() === element?.toLowerCase());
            nowResults = [...nowResults, ...myResults];
        }
        results = nowResults;
    }

    // Filtering qualification
    if (search.qualification.length > 0) {
        let myResults = []
        for (let i = 0; i < search.qualification.length; i++) {
            let nowResults = results.filter(item => item?.qualification?.toLowerCase() === search.qualification[i]?.toLowerCase());
            myResults = [...myResults, ...nowResults];


        }
        results = myResults
    }

    //Filtering WorkingWith
    if (search.workingWith.length > 0) {
        let myResults = []
        for (let i = 0; i < search.workingWith.length; i++) {
            let nowResults = results.filter(item => item?.workingWith?.toLowerCase() === search.workingWith[i]?.toLowerCase());
            myResults = [...myResults, ...nowResults];

        }
        results = myResults
    }

    // Filtering professions
    if (search.professionArea.length > 0) {
        let myResults = []
        for (let i = 0; i < search.professionArea.length; i++) {
            let nowResults = results.filter(item => item?.professionArea?.toLowerCase() === search.professionArea[i]?.toLowerCase());
            myResults = [...myResults, ...nowResults];

        }
        results = myResults
    }

    // Filtering Income
    if (search.income.length > 0) {
        let myResults = [];
        for (let i = 0; i < search.income.length; i++) {
            const incomeSplit = search.income[i].split("-");
            const income1 = parseInt(incomeSplit[0]);
            const income2 = parseInt(incomeSplit[1]);

            let nowResults = results.filter(item => income1 >= parseInt(item?.annualIncome?.startFrom || 0) && income2 <= parseInt(item?.annualIncome?.endTo || 0));
            myResults = [...myResults, ...nowResults]
        }
        results = myResults;
    }

    return results;
}
