import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SingleSelect from '../../../components/Form/SingleSelect'
import { useHistory } from "react-router-dom";
import { optionContext } from '../../../utils/context/optionContext'
import Loading from '../../../components/Loading/Index'
import { Height } from '../../../components/OptionsData/HeightData/Heightdata'
import { Age } from '../../../components/OptionsData/Age/Age'
import QuickLink from '../../../components/MyBiye/QuickItems/Index'
import { useQuery } from 'react-query';

const Index = () => {

    const { options } = useContext(optionContext)
    const [isLoading, setLoading] = useState(false)
    let [pageload, setPageload] = useState(true)
    let history = useHistory();

    const [age1, setAge1] = useState({
        value: null,
        options: Age
    })

    const [age2, setAge2] = useState({
        value: null,
        options: Age
    })
    const [height1, setHeight1] = useState({
        value: null,
        options: Height
    })

    const [height2, setHeight2] = useState({
        value: null,
        options: Height
    })

    const [motherTongue, setMotherTongue] = useState({
        value: null,
        options: null
    })

    const [religion, setReligion] = useState({
        value: null,
        options: []
    })

    const [livingCountry, setLivingCountry] = useState({
        value: null,
        options: []
    })

    // const [livingState, setLivingState] = useState({
    //     value: null,
    //     options: []
    // })

    const [birthCountry, setBirthCountry] = useState({
        value: null,
        options: []
    })

    const [qualification, setQualification] = useState({
        value: null,
        options: []
    })

    const [workingWith, setWorkingWith] = useState({
        value: null,
        options: []
    })

    const [professionArea, setProfessionArea] = useState({
        value: null,
        options: []
    })

    const [annualIncome, setAnnualIncome] = useState({
        value: null,
        options: []
    })

    function setAllOptions() {
        let fields = options.settingFiled2
        for (let index = 0; index < fields.length; index++) {
            const element = fields[index]
            if (element.label === "Language") {
                setMotherTongue({ ...motherTongue, options: element.value })
            }
            else if (element.label === "Income") {
                setAnnualIncome({ ...annualIncome, options: element.value })
            }
            else if (element.label === "Profession area") {
                setProfessionArea({ ...professionArea, options: element.value })
            }
            else if (element.label === "Working with") {
                setWorkingWith({ ...workingWith, options: element.value })
            }
            else if (element.label === "Qualification") {
                setQualification({ ...qualification, options: element.value })
            }
            else if (element.label === "Country") {
                setBirthCountry({ ...birthCountry, options: element.value })
                setLivingCountry({ ...livingCountry, options: element.value })
            }
            else if (element.label === "Religion") {
                setReligion({ ...religion, options: element.value })
            }
        }
        setPageload(false)
        return options.settingFiled2
    }

    const multipleOptions = useQuery('advanceOptions', setAllOptions, {
        cacheTime: 1500000,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageload)
    })


    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        options: [
            { value: 'null', label: 'Any' },
            { value: 'never married', label: 'Never Married' },
            { value: 'divorced', label: 'Divorced' },
            { value: 'annulled', label: 'Annulled' },
            { value: 'widowed', label: 'Widowed' },
            { value: 'married', label: 'Married' }
        ]
    })

    // Submit form
    const submitForm = event => {
        event.preventDefault()
        setLoading(true)
        if (!materialStatus.value && !religion.value && !motherTongue.value &&
            !livingCountry.value && !birthCountry.value && !qualification.value &&
            !workingWith.value && !professionArea.value && !annualIncome.value &&
            (!height1.value || !height2.value) && (!age1.value || !age2.value)) {
            toast.warn('please provide values.')
            setLoading(false)
        }
        else {
            setTimeout(() => {
                setLoading(false)
                history.push(`/search/results?advance=${true}&page=1&limit=20&age1=${age1.value}&age2=${age2.value}&height1=${height1.value}&height2=${height2.value}&religion=${religion.value}&livingCountry=${livingCountry.value}&materialStatus=${materialStatus.value}&motherTongue=${motherTongue.value}&birthCountry=${birthCountry.value}&qualification=${qualification.value}&workingWith=${workingWith.value}&professionArea=${professionArea.value}&annualIncome=${annualIncome.value}`)
            }, 2000)
        }
    }

    if (multipleOptions.isLoading) return <Loading />

    return (
        <div className="container search-container">
            <div className="row">
                <QuickLink />
                {/* main search */}
                <div className="col-12 col-lg-8 m-auto">
                    <div className="card border-0">
                        <div className="card-body p-lg-4">
                            <form onSubmit={submitForm}>
                                <div className="row">

                                    <div className="col-12 col-lg-8 col-xl-6 mb-2">
                                        {/* Age range */}
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                <div className="form-group">
                                                    <p>Age</p>
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={age1.options}
                                                        value={(data) => setAge1({ ...age1, value: data })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-2"><h6>To</h6></div>
                                            <div className="flex-fill pt-4">
                                                <div className="form-group">
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={age2.options}
                                                        value={(data) => setAge2({ ...age2, value: data })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Height range */}
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                <div className="form-group">
                                                    <p>Height</p>
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={height1.options}
                                                        value={(data) => setHeight1({ ...height1, value: data })} />
                                                </div>
                                            </div>
                                            <div className="px-2"><h6>To</h6></div>
                                            <div className="flex-fill pt-4">
                                                <div className="form-group">
                                                    <SingleSelect
                                                        className="form-control shadow-none"
                                                        options={height2.options}
                                                        value={(data) => setHeight2({ ...height2, value: data })} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Material status */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Material status</p>
                                            <SingleSelect
                                                options={materialStatus.options}
                                                value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Religion / Community */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Religion / Community</p>
                                            <SingleSelect
                                                options={religion.options}
                                                value={(data) => setReligion({ ...religion, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Mother tongue */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Mother tongue</p>
                                            <SingleSelect
                                                options={motherTongue.options}
                                                value={(data) => setMotherTongue({ ...motherTongue, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Location & grew up in details */}
                                    <div className="col-12 py-3">
                                        <h5 className="mb-0">Location & grew up in details</h5>
                                    </div>

                                    {/* Living country */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Living country</p>
                                            <SingleSelect
                                                options={livingCountry.options}
                                                value={(data) => setLivingCountry({ ...livingCountry, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Living state */}
                                    {/* <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Living state</p>

                                            <SingleSelect
                                                options={livingState.options}
                                                value={(data) => setLivingState({ ...livingState, value: data })}
                                            />
                                        </div>
                                    </div> */}

                                    {/*Birth country */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Birth country</p>
                                            <SingleSelect
                                                options={birthCountry.options}
                                                value={(data) => setBirthCountry({ ...birthCountry, value: data })}
                                            />
                                        </div>
                                    </div>


                                    {/* Education & profession details */}
                                    <div className="col-12 py-3">
                                        <h5 className="mb-0">Education & profession details</h5>
                                    </div>

                                    {/* Qualification */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Qualification</p>
                                            <SingleSelect
                                                // options={optionData.qualifications}
                                                options={qualification.options}
                                                value={(data) => setQualification({ ...qualification, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Working with */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Working with</p>
                                            <SingleSelect
                                                options={workingWith.options}
                                                value={(data) => setWorkingWith({ ...workingWith, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Profession area */}
                                    <div className="col-12">
                                        <div className="form-group mb-4">
                                            <p>Profession area</p>
                                            <SingleSelect
                                                options={professionArea.options}
                                                value={(data) => setProfessionArea({ ...professionArea, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Annual income */}
                                    <div className="col-12">
                                        <div className="form-group mb-4 pb-5">
                                            <p>Monthly income</p>
                                            <SingleSelect
                                                options={annualIncome.options}
                                                value={(data) => setAnnualIncome({ ...annualIncome, value: data })}
                                            />
                                        </div>
                                    </div>

                                    {/* Search using keywords */}
                                    {/* <div className="col-12 py-3">
                                        <h5 className="mb-0">Search using keywords</h5>
                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="mb-2">Chat status</p>
                                        {['Only profiles avaliable', 'Online', 'Offline'].map((item, i) => (
                                            <Form.Check
                                                key={i}
                                                inline
                                                custom
                                                id={item}
                                                type="radio"
                                                label={item}
                                                checked={item === chatStatus}
                                                onChange={() => setChatStatus(item)}
                                            />
                                        ))}
                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="mb-2">Photo setting</p>
                                        {['Visible to all', 'Protected'].map((item, i) => (
                                            <Form.Check
                                                key={i}
                                                inline
                                                custom
                                                id={item}
                                                type="radio"
                                                label={item}
                                                checked={item === photoSetting}
                                                onChange={() => setPhotoSetting(item)}
                                            />
                                        ))}
                                    </div> */}

                                    <div className="col-12 text-right">
                                        <button
                                            type="submit"
                                            className="btn shadow-none"
                                            disabled={isLoading}
                                        >{isLoading ? 'Searching...' : 'Search'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;