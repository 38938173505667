import React, { useState } from 'react'
import '../style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useForm } from 'react-hook-form'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })

const Number = (props) => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [isLoading, setLoading] = useState(false)
    const { handleSubmit } = useForm()

    const onSubmit = async () => {
        setLoading(true)
        const response = await Requests.Settings.SendOtp(header)
        if (response.status) {
            setLoading(false)
            props.setShow('otp')
            setTimeout(()=>{
                toast.success('Otp code send to your phone number')
            },500)
        }
    }
    return (
        <div className="card border-0">
            <div className="card-body px-0">
            <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-4">
                        <p>phone number</p>
                        <span>verification code will sent to this phone number</span>
                        <input
                            type="text"
                            placeholder="phone"
                            className="form-control shadow-none my-3"
                            value={props.phone}
                        />
                    </div>
                    <div className="text-right d-flex">
                        <button 
                            type="submit" 
                            className="btn shadow-none" 
                            disabled={isLoading}>
                            {isLoading ? 'sending...' : 'send'}
                        </button>
                    </div>
                </form>
        </div>
        </div>
    );
};

export default Number;