import React from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { chevronLeft, chevronRight } from 'react-icons-kit/feather'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'


const Index = ({page, totalpage, changePage }) => {
    // const location = useLocation()
    // const query = queryString.parse(location.search)
    
    return (
        <div className="pageination-items">
            <ul>
                <li>
                    <button
                        type="button"
                        className="btn shadow-none px-2"
                    ><Icon icon={chevronLeft} size={17} /></button>
                </li>
                {[...Array(totalpage).keys()].map((i) => {
                    return (
                        <li key={i}>
                            <button
                                type="button"
                                className={parseInt(page) === (i + 1) ? "btn shadow-none active" : "btn shadow-none"}
                                onClick={() =>changePage(i + 1)}
                            >{i + 1}</button>
                        </li>
                    )
                })}
                <li>
                    <button
                        type="button"
                        className="btn shadow-none px-2"
                    ><Icon icon={chevronRight} size={17} /></button>
                </li>
            </ul>
        </div>
    );
}

export default Index;